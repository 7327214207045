import React from 'react';
import {
  Typography,
  Fade,
  Grid,
  Hidden,
  Stepper,
  Step,
  StepLabel,
} from '@mui/material';
import WhatsappOutlinedIcon from '@mui/icons-material/WhatsappOutlined';
import blueLogo from '../../assets/bancame_logo.svg';
import QontoConnector from '../../components/QontoStepIcon/QontoConnector';
import QontoStepIcon from '../../components/QontoStepIcon';
import EnterprisesFormParent from './form';
import simulationStyles from './styles';
import {
  formSchema, getInfographics, stepperSteps,
} from './form/formLogic';
import { formSchemaInterface } from '../../utils/interfaces/simulation';
import AlertDialog from '../../components/AlertDialog';

function SimulationIndex() {
  const classes = simulationStyles();
  const [activeStep, setActiveStep] = React.useState(1);
  const [stepperActiveStep, setStepperActiveStep] = React.useState(0);
  const [currentData, setCurrentData] = React.useState<formSchemaInterface>(formSchema);
  const handleCurrentData = (data) => setCurrentData(data);
  const handleStep = (step) => setActiveStep(step);
  const handleNext = () => setActiveStep((prevStep) => prevStep + 1);
  const handleBack = () => {
    if (activeStep === -1) {
      window.location.href = 'https://banca.me/';
    } else {
      setActiveStep((prevStep) => prevStep - 1);
    }
  };
  const handleReset = () => handleStep(1);
  const handleOpenWhatsApp = () => {
    window.open('https://api.whatsapp.com/send/?phone=56920711980&text=Hola.+Tengo+una+pregunta+con+respecto+al+simulador+de+empresas');
  };
  React.useEffect(() => {
    if (activeStep > 1) {
      return setStepperActiveStep(1);
    }
    if (activeStep === 1) {
      return setStepperActiveStep(0);
    }
    return setStepperActiveStep(0);
  }, [activeStep]);

  return (
    <Grid
      container
      className={classes.root}

    >

      <div
        className={classes.floatingButton}
        style={{
          backgroundColor: 'green',

        }}
        onClick={handleOpenWhatsApp}
        aria-hidden
      >
        <WhatsappOutlinedIcon style={{
          color: 'white', margin: 'auto', width: '100%', height: '100%',
        }}
        />

      </div>
      <Grid
        item
        xs={12}
        className={classes.navBar}
        id="navBar"
      >

        <div className={classes.stepperContainer}>
          <img
            src={blueLogo}
            alt="BancaMeLogo"
            className={classes.logo_img}
            aria-hidden="true"
          />
          {activeStep < 4
            ? (
              <Stepper
                className={classes.stepper}
                activeStep={stepperActiveStep}
                connector={<QontoConnector />}
              >
                {stepperSteps.map((label, idx) => (
                  <Step key={label}>
                    <StepLabel
                      StepIconComponent={QontoStepIcon}
                    >
                      {stepperActiveStep === idx
                        ? (
                          <Typography fontWeight="bold">
                            {label}
                          </Typography>
                        ) : (
                          <Typography fontWeight="bold" style={{ color: '#ccc' }}>
                            {label}
                            {' '}
                          </Typography>
                        )}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            ) : (
              <Typography />
            )}
        </div>

        <Hidden mdDown>
          {(activeStep === 3)
            && (
            <AlertDialog
              buttonLabel="Volver"
              dialogTitle="¿Volver al inicio?"
              dialogContent="Si continúa, volverá al incio y perderá toda la información de la simulación"
              action={handleReset}
            />
            )}
        </Hidden>
      </Grid>
      {(activeStep !== 3 && activeStep !== 2)
        && (
        <Hidden lgDown={activeStep !== 4}>
          <Grid
            item
            xs={activeStep === 4 ? 12 : false}
            lg={activeStep === 4 ? 12 : 6}
            className={classes.leftGrid}
          >
            <Fade
              in
              {...({ timeout: 3500 })}
            >

              <div
                className={classes.infographicsContainer}
              >
                {getInfographics(activeStep, classes.infographicsContainer)}
              </div>
            </Fade>

          </Grid>
        </Hidden>
        )}
      {activeStep !== 4
          && (

            <Grid
              item
              xs={12}
              lg={activeStep === 1 ? 6 : 12}
              className={classes.rightGrid}
              style={{
                backgroundColor: activeStep === 3 ? 'white' : null,
                padding: activeStep === 3 ? 0 : null,
              }}
            >
              <EnterprisesFormParent
                activeStep={activeStep}
                currentData={currentData}
                handleCurrentData={handleCurrentData}
                handleStep={handleStep}
                handleNext={handleNext}
                handleBack={handleBack}
                handleReset={handleReset}
              />

            </Grid>

          )}
    </Grid>

  );
}

export default SimulationIndex;

import fetch from 'node-fetch';

const fetcher = async (
  url: string,
  method: string,
  body: string | string[][] | Record<string, string> | {} | null,
  token: string| null,
) => {
  const res = await fetch(url, {
    method,
    body: body ? new URLSearchParams(body) : undefined,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: token ? `Bearer ${token}` : '',

    },
  });
  if (res.ok) {
    const response = await res.json();
    return response;
  }
  throw res.status;
};

export default fetcher;

import React from 'react';

// Company Report
import {
  Route, Switch,
} from 'react-router-dom';
import CompanyReportRoutes from './routes/company-report';
import GenerateReportRoutes from './routes/generate-report';
import ProductsRoutes from './routes/products';
import SimulationRoutes from './routes/simulation';
import AccountRoutes from './routes/account';
import NotFound from './404';
import CompanyAccountLogin from '../pages/account/login';

const notFound = () => <NotFound />;

function AppRoutes() {
  return (
    <Route
      render={
                ({ location }) => (
                  <Switch location={location}>
                    <Route path="/generate-report" component={GenerateReportRoutes} />
                    <Route path="/company-report" component={CompanyReportRoutes} />
                    <Route path="/products" component={ProductsRoutes} />
                    <Route path="/simulation" component={SimulationRoutes} />
                    <Route path="/account" component={AccountRoutes} />
                    <Route path="/company-login" component={CompanyAccountLogin} />
                    <Route component={notFound} />
                  </Switch>
                )
}
    />
  );
}

export default AppRoutes;

export const handleVisibleTab = (tab: string, history) => {
  if (tab === 'workers') {
    history.push('/products/workers');
  }
  if (tab === 'enterprises') {
    history.push('/products/enterprises');
  }
};

export default handleVisibleTab;

import React from 'react';
import {
  Button,
  TablePagination,
} from '@mui/material';
import { CSVLink } from 'react-csv';
import SquareIcon from '@mui/icons-material/CropSquare';
import DownloadIcon from '@mui/icons-material/Download';
import { handleSaveAsExcel } from '../helpers';
import useStyles from './styles';

type EnhancedTableHeadProps = {
  title: string,
  rows: any[],
  page: number,
  rowsPerPage: number,
  cellHighlight: { [key: string]: { function: () => void; label: string, labelColor: string}},
  handleChangePage: (event: unknown, newPage: number) => void,
  handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>)=> void,
}

export default function EnhancedTableFooter(props:EnhancedTableHeadProps) {
  const {
    title,
    rows,
    page,
    rowsPerPage,
    cellHighlight,
    handleChangePage,
    handleChangeRowsPerPage,
  } = props;
  const classes = useStyles();

  return (
    <>
      <div style={{ display: 'flex', margin: '10px' }}>
        {Object.keys(cellHighlight).map((elem) => (
          <div
            key={elem}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <SquareIcon style={{ color: cellHighlight[elem].labelColor }} />
            <span>{cellHighlight[elem].label}</span>
          </div>
        ))}
      </div>
      <div className={classes.actionButtons}>
        <CSVLink
          data={rows}
          filename={`${title} ${new Date()}`}
          style={{ textDecoration: 'none' }}
        >
          <Button
            style={{
              margin: 'auto 5px',
              fontSize: 'small',
            }}
            variant="outlined"
            startIcon={<DownloadIcon style={{ fontSize: 'medium' }} />}
          >
            .CSV
          </Button>
        </CSVLink>
        <Button
          style={{
            color: 'green', margin: 'auto 5px', borderColor: 'green', fontSize: 'small',
          }}
          variant="outlined"
          onClick={() => handleSaveAsExcel(rows, title)}
          startIcon={<DownloadIcon style={{ color: 'green', fontSize: 'medium' }} />}
        >
          .XLSX
        </Button>
      </div>
      <TablePagination
        rowsPerPageOptions={[30, 60, 90]}
        labelRowsPerPage="Filas por página"
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${(count !== -1 ? count : `más de ${to}`)}`}
      />

    </>
  );
}

import React from 'react';
import { useHistory } from 'react-router';
import TokenDecode from '../../../features/account/TokenDecode';
import { useQuery } from '../../../utils/helpers';

export default function CompanyAnswer() {
  const history = useHistory();
  const query = useQuery();
  const loanSimulationId = query.get('loanSimulationId');
  return (
    <TokenDecode
      successCallback={() => history.push(`/account/company-answer/${loanSimulationId}`)}
    />
  );
}

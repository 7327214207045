import React from 'react';
import {
  Checkbox,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import headerLabels from './labels';
import useStyles from './styles';

type EnhancedTableHeadProps = {
    numSelected: number;
    onRequestSort: (e: React.SyntheticEvent | Event, property: string) => void;
    onSelectAllClick: (e: React.SyntheticEvent | Event) => void;
    order: 'asc' | 'desc';
    orderBy: string;
    rowCount: number;
    headCells: { id: string, numeric: boolean, disablePadding: boolean, label: string}[];
    isSelect: boolean;
    isSelectButton: boolean;
}

export default function EnhancedTableHead(props:EnhancedTableHeadProps) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headCells,
    isSelect,
    isSelectButton,
  } = props;
  const classes = useStyles();

  const createSortHandler = (property: string) => (event: React.SyntheticEvent | Event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {isSelect && (
          <TableCell padding="checkbox">
            {isSelectButton
            && (
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ 'aria-label': 'select all items' }}
            />
            )}
          </TableCell>
        )}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="center"
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              <b style={{ fontSize: 14, marginLeft: 26 }}>
                {Object.keys(headerLabels).includes(headCell.label)
                  ? headerLabels[headCell.label] : headCell.label}
              </b>
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

import React from 'react';
import { useHistory } from 'react-router-dom';
import logo from '../../../../assets/bancame_logo.svg';
import bancameIndex from './financial-report.png';
import Header from '../../header';
import { handleVisibleTab } from '../../helpers';
import HubSpot from '../../../../components/HubspotForm';

function BancameIndex() {
  const history = useHistory();
  React.useEffect(() => {
    document.body.style.backgroundColor = '#E5E5E5';
  }, []);
  const [isInterested, setIsInterested] = React.useState(false);
  const handleIsInterested = () => setIsInterested((o) => !o);
  const handleSubmit = () => setTimeout(() => setIsInterested(false), 2000);

  return (
    <Header type="enterprises">

      <div className="cards_container">

        <div className="card card_detail">
          <div
            className="card_image enterprise"
            style={{
              backgroundImage: `url(${bancameIndex})`,

            }}
          >
            {' '}
            <div style={{ margin: 'auto' }}>
              <span style={{ fontSize: '90px' }}>&#128202;</span>
            </div>

          </div>
          <div className="container">

            <p>
              Una radiografía rápida y gratuita sobre la
              situación financiera de tus trabajadores

            </p>
            <object data={logo} width="30px" height="30px"> </object>
          </div>
        </div>
        <div>
          <div
            aria-hidden
            onClick={() => handleVisibleTab('enterprises', history)}
            style={{
              fontSize: '15px',
              marginBottom: '10px',
              cursor: 'pointer',
              color: '#f44c7f',
              textAlign: 'left',
            }}
          >
            <span style={{ fontSize: '20px', marginRight: '5px' }}>&#x2039;</span>
            Atrás

          </div>
          <div style={{ textAlign: 'left', height: '400px', overflowY: 'auto' }}>

            {isInterested

              && HubSpot(
                process.env.REACT_APP_PORTAL_ID,
                process.env.REACT_APP_BANCAME_INDEX_FORM_ID,
                handleSubmit,
              )}
            {!isInterested && (
            <div>

              <h2>Índice Banca.Me</h2>
              <p style={{ fontSize: '18px' }}>
                El índice Banca.Me te muestra que porcentaje de tus
                trabajadores se encuentra en buena situación financiera y
                puede rendir mejor en el trabajo.

              </p>
              <p style={{ fontSize: '18px' }}>
                Un índice bajo significa que tus trabajadores tienen
                estrés financiero y eso reduce su productividad y
                compromiso con la empresa.

              </p>

              <h5>¿Cómo funciona?</h5>
              <ol style={{ fontSize: '16px' }}>
                <li>Se debe crear un usuario Banca.Me en su Software de Recursos Humanos.</li>
                <li>En 3 días se entrega el índice con posibilidad de una reunión explicativa.</li>
                <li>
                  Los datos son anonimizados y se cumple con la
                  ley 19.628 de privacidad de datos.

                </li>
              </ol>

            </div>
            )}
          </div>
          {!isInterested
                    && (
                    <div
                      style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', margin: 'auto 5%' }}
                    >
                      <h5 style={{
                        color: '#f44c7f',
                        cursor: 'pointer',
                        margin: 0,
                        padding: '8px',
                        borderRadius: '20px',
                        border: '#f44c7f 1px solid',
                      }}
                      >
                        <a className="link" href="/company-report" target="_blank">
                          Ver mi informe
                        </a>

                      </h5>
                      <div />

                      <h5
                        aria-hidden
                        onClick={handleIsInterested}
                        className="interestedFooter"
                      >
                        Estoy interesado

                      </h5>

                    </div>
                    )}
        </div>
      </div>

    </Header>
  );
}

export default BancameIndex;

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  viewTitle: {
    display: 'flex',
    height: '10%',
    justifyContent: 'space-between',
  },
}));

export default useStyles;

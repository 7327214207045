import React from 'react';
import {
  useLocation,
} from 'react-router-dom';
import { Fade, Typography } from '@mui/material';
import logo from '../../../assets/bancame_logo.svg';
import body1 from '../../../assets/generate-report/01_sign-up/body_1.svg';
import talana from '../../../assets/generate-report/01_sign-up/talana.svg';
import buk from '../../../assets/generate-report/01_sign-up/buk.svg';
import './styles.css';
import fetcher from '../../../utils/fetcher';

const getHRImage = (HRSoftware:string) => {
  switch (HRSoftware) {
    case 'Talana':
      return (
        <>
          <img src={talana} alt="talana" width="200px" style={{ margin: '15px' }} />
          <div>Credenciales Talana</div>
        </>
      );
    case 'Buk':
      return (
        <>
          <img src={buk} alt="buk" width="200px" style={{ margin: '0px' }} />
          <div style={{ color: 'white' }}>Credenciales Buk</div>
        </>
      );
    default:
      return <div />;
  }
};

const getHRBackground = (HRSoftware: string) => {
  switch (HRSoftware) {
    case 'Talana':
      return { backgroundColor: 'white', color: '#6783b2' };
    case 'Buk':
      return { backgroundColor: '#6783b2', color: 'white' };
    default:
      return { backgroundColor: 'white', color: '#6783b2' };
  }
};

const submitButtons = (
  success: boolean | null,
  loading: boolean,
  submitFunction: ()=> void,
) => {
  switch (success) {
    case true:
      return (
        <>
          <h3
            className="HRlogIn"
            style={{ color: 'green', cursor: 'default' }}
          >
            INGRESO EXITOSO
          </h3>
          <h6 style={{ margin: 0 }}>Se han ingresado los datos con éxito</h6>
        </>
      );
    case false:
      return (
        <>
          <h3
            className="HRlogIn"
            style={{ color: 'red', cursor: 'default' }}
          >
            ERROR
          </h3>
          <h6 style={{ margin: 0 }}>Por favor contactar al equipo de Banca.Me</h6>
        </>
      );
    default:
      return (
        <h3
          aria-hidden
          onClick={!loading ? submitFunction : undefined}
          style={{ color: loading ? 'grey' : undefined }}
          className="HRlogIn"
        >
          {loading ? 'CARGANDO...' : 'INGRESAR'}
        </h3>
      );
  }
};

const useQuery = () => new URLSearchParams(useLocation().search);

function GenerateReportAuth() {
  const query = useQuery();
  const token = query.get('token');

  React.useEffect(() => {
    document.body.style.backgroundColor = '#D7EFF4';
    document.body.style.transition = '2s';
  }, []);
  const [HRSoftware, setHRSoftware] = React.useState('');
  const [showLanding, setShowLanding] = React.useState(true);
  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [success, setSuccess] = React.useState<boolean|null>(null);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);

  const handleClick = (HRSoft:string) => {
    setHRSoftware(HRSoft);
    if (HRSoft === 'Talana') {
      document.body.style.backgroundColor = 'white';
    } else if (HRSoft === 'Buk') {
      document.body.style.backgroundColor = '#6783b2';
    }
    setShowLanding(false);
  };
  const handleSubmit = async () => {
    if (username === '' || password === '') {
      return setError(true);
    }
    setError(false);
    setLoading(true);
    try {
      await fetcher(
        `${process.env.REACT_APP_API_URL}/third-parties/hr-integration`,
        'POST',
        { user: username, password, type: HRSoftware },
        token as string,
      );
      setSuccess(true);
      return setLoading(false);
    } catch (e) {
      setLoading(false);
      return setSuccess(false);
    }
  };

  return (
    <div>

      <img src={body1} alt="authBackground" className="auth_background" />
      <object data={logo} width="50px" height="50px" style={{ position: 'absolute', margin: '15px' }}> </object>

      <div className="auth_body">
        {showLanding ? (
          <div className="auth_content">

            <h2>¿Cuál es su software de Recursos Humanos?</h2>
            <div className="hr_buttons">
              <div>
                <img
                  aria-hidden
                  className="HRImage talana"
                  onClick={() => handleClick('Talana')}
                  src={talana}
                  alt="talana"
                />
              </div>
              <div className="buk">

                <img className="HRImage buk_1" src={buk} alt="buk" />
                <div className="centered">Pronto</div>

              </div>

            </div>

          </div>
        ) : (
          <Fade
            in={!showLanding}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...(!showLanding ? { timeout: 2000 } : {})}
          >
            <div className="auth_content">
              <Typography align="left" onClick={() => setShowLanding(true)} style={{ cursor: 'pointer' }}>
                {' '}
                <span
                  style={{ fontSize: '20px', marginRight: 5 }}
                >
                  &#x2039;

                </span>
                Volver

              </Typography>
              <div style={getHRBackground(HRSoftware)} className="auth_buttons">
                {getHRImage(HRSoftware)}
                <input
                  disabled={loading}
                  className="auth_inputs"
                  placeholder="Usuario"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  type="text"
                  name="lname"
                />
                <input
                  disabled={loading}
                  className="auth_inputs"
                  placeholder="Contraseña"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  type="password"
                  name="lname"
                />
                <p style={{ fontSize: '12px' }}><a href="/generate-report/auth#">Términos y condiciones</a></p>
                {submitButtons(success, loading, handleSubmit)}
                {error && <h6 style={{ margin: 0, color: 'red' }}>Rellene todos los campos</h6>}
              </div>

            </div>
          </Fade>
        )}
      </div>

    </div>
  );
}

export default GenerateReportAuth;

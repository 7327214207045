import React from 'react';
import body1 from '../../../assets/company-report/03_action-plan/body_1.svg';
import logo from '../../../assets/bancame_logo.svg';
import img1 from '../../../assets/company-report/03_action-plan/img_21.svg';
import img2 from '../../../assets/company-report/03_action-plan/img_22.svg';
import img3 from '../../../assets/company-report/03_action-plan/img_23.svg';
import './styles.css';

function CompanyReportActionPlan() {
  React.useEffect(() => {
    document.body.style.backgroundColor = '#DFE3EC';
    document.body.style.transition = '2s';
  }, []);

  return (
    <div>
      <img src={body1} alt="firstBackground" className="first_background" />

      <div className="introTwo_body">
        <div className="first_header">
          <object data={logo} width="70px" height="70px"> </object>
          <div>
            <h2 className="intro_title_2">PLAN DE ACCIÓN BANCA.ME</h2>
            <h4 className="intro_subtitle_2">
              Éstas son las principales estrategias de Banca.Me para mejorar el
              Bienestar Fianciero de tus colaboradores
              {' '}

            </h4>
          </div>
        </div>
        <div className="intro_two">
          <div>
            <object data={img1} aria-label="imgGender" width="300px" height="300px" />
            <div className="intro_two_text">
              <h4 style={{ margin: 0 }}>Refinanciamiento</h4>
              <p className="intro_two_description">
                Invitación a los trabajadores que se encuentran en situación crítica
                o requieren atención a portar su deuda a Banca.Me.

              </p>
            </div>
          </div>
          <div>
            <object data={img2} aria-label="imgGender" width="300px" height="300px" />
            <div className="intro_two_text">
              <h4 style={{ margin: 0 }}>Unificación de deuda</h4>
              <p className="intro_two_description">
                Capacitación enfocada en ordenar las
                finanzas con invitación a que unifiquen sus deudas actuales y las porten a Banca.Me

              </p>
            </div>
          </div>
          <div>
            <object data={img3} aria-label="imgGender" width="300px" height="300px" />
            <div className="intro_two_text">
              <h4 style={{ margin: 0 }}>Planificación financiera</h4>
              <p className="intro_two_description" style={{ marginBottom: 100 }}>
                Capacitación enfocada en definir un objetivo de inversión y
                trabajar para alcanzarlo.
                Desde un vehículo, un emprendimiento, un departamento, etc.

              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}

export default CompanyReportActionPlan;

import React from 'react';
import {
  Checkbox, Grid, TableBody, TableCell, TableRow,
} from '@mui/material';
import {
  handleSelect, getComparator, stableSort,
  renderRowLabels, handleHightLight,
} from './helpers';
import useStyles from './styles';

type EnhancedTableBodyProps = {
  rows: any[];
  order: 'asc' | 'desc';
  orderBy: string;
  page: number;
  rowsPerPage: number;
  isSelect: boolean;
  getSelected: (item: any) => void;
  cellHighlight: { [key: string]: { function: () => void; label: string; labelColor: string } };
  selectFilter: { [key: string]: { function: () => void; } };
  optionalIcon: React.ReactNode;
  selected: any[],
  filteredRows: any[],
  handleItemClick: (event: any, row: any) => void;
}

export default function EnhancedTableBody(props: EnhancedTableBodyProps) {
  const {
    rows,
    order,
    orderBy,
    page,
    rowsPerPage,
    isSelect,
    getSelected,
    cellHighlight,
    selectFilter,
    optionalIcon,
    selected,
    filteredRows,
    handleItemClick,
  } = props;
  const classes = useStyles();

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  React.useEffect(() => {
    if (getSelected) {
      getSelected(selected);
    }
  }, [selected]);

  return (
    <TableBody>

      {
                stableSort(filteredRows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow
                        hover
                        style={{
                          cursor: 'pointer',
                          height: '70px',
                          background: handleHightLight(cellHighlight, row),
                        }}
                        classes={{ hover: classes.hover }}
                        className={classes.tableRow}
                        onClick={(event) => handleSelect(selectFilter, row)
                                && handleItemClick(event, row)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={`table-row-${row.id}`}
                        selected={isItemSelected}
                      >
                        {isSelect && (
                        <TableCell
                          padding="checkbox"
                          style={{
                            width: '8vw',
                            minWidth: '70px',
                          }}
                        >
                          {handleSelect(selectFilter, row)
                                            && (
                                            <Grid container spacing={2}>
                                              <Grid item xs={6}>
                                                <Checkbox
                                                  checked={isItemSelected}
                                                  inputProps={{ 'aria-labelledby': labelId }}
                                                />
                                              </Grid>
                                              <Grid
                                                item
                                                xs={6}
                                                style={{ padding: '20%' }}
                                              >
                                                {optionalIcon}
                                              </Grid>
                                            </Grid>
                                            )}
                        </TableCell>
                        )}
                        {renderRowLabels(row, classes).map((cell, idx) => cell !== undefined
                                    && (
                                    <TableCell style={{ fontSize: 'small' }} align={idx > 0 ? 'center' : 'left'} key={`row_${idx * 10}`}>
                                      {cell}
                                    </TableCell>
                                    ))}
                      </TableRow>
                    );
                  })
            }
      {emptyRows > 0 && (
        <TableRow style={{ height: (53) * emptyRows }}>
          <TableCell colSpan={6} />
        </TableRow>
      )}
    </TableBody>
  );
}

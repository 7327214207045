import React from 'react';
import { makeStyles } from '@mui/styles';

const usePlaceholderStyles = makeStyles({
  placeholder: {
    color: '#aaa',
  },
});

interface PlaceHolderInterface {
    children: React.ReactNode
}

export default function SelectPlaceholder(props:PlaceHolderInterface) {
  const { children } = props;
  const classes = usePlaceholderStyles();
  return <div className={classes.placeholder}>{children}</div>;
}

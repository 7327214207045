import { createTheme, responsiveFontSizes } from '@mui/material';
import { makeStyles } from '@mui/styles';
import NunitoRegular from './fonts/Nunito-Regular.ttf';
import NunitoBold from './fonts/Nunito-Bold.ttf';
import NunitoLight from './fonts/Nunito-Light.ttf';

const nunito = {
  fontFamily: 'Nunito',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('Nunito'),
    local('Nunito-Regular'),
    url(${NunitoRegular})
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const nunitoBold = {
  fontFamily: 'Nunito',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 700,
  src: `
    local('Nunito'),
    local('Nunito-Bold'),
    url(${NunitoBold})
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const nunitoLight = {
  fontFamily: 'Nunito',
  fontStyle: 'light',
  fontDisplay: 'swap',
  fontWeight: 300,
  src: `
    local('Nunito'),
    local('Nunito-Light'),
    url(${NunitoLight})
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

declare module '@mui/material/styles' {
  // for external use of new overrides
  interface Theme{
    customPalette: {
      background: {
        secondary: string,
        dark: string,
        light: string,
      },
      tertiary: {
        main: string
      };
      opacity: {
        primary: string
      };
      white: {
        main: string
      };

    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    customPalette?: {
      background: {
        secondary: string,
        dark: string,
        light: string,
      },
      tertiary?: {
        main: string
      };
      opacity?: {
        primary: string
      };
      white?: {
        main: string
      };

    };
  }
}

const muiTheme = createTheme({
  customPalette: {
    tertiary: {
      main: '#F3005A',
    },
    opacity: {
      primary: 'rgba(38, 45, 64, 0.9)',
    },
    white: {
      main: '#FFFFFF',
    },
    background: {
      secondary: 'rgba(249, 249, 249, 1)',
      dark: 'rgba(217, 238, 245)',
      light: 'rgba(237, 243, 245)',
    },
  },
  spacing: 4,
  palette: {
    background: {
      default: '#EDF1F5',
    },
    primary: {
      main: '#01182E',
    },
    secondary: {
      main: '#00ACD8',
    },
    success: {
      main: '#66A182',
    },
    text: {
      primary: '#262D40',
      secondary: '#7F8EB3',
    },

    error: {
      main: '#CC444B',
    },
    info: {
      main: '#FFCC1A',
    },
  },
  typography: {
    fontFamily: 'Nunito',
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '@font-face': [nunito, nunitoBold, nunitoLight],
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          height: 45,
          width: 225,
          textTransform: 'none',
        },
        containedPrimary: {
          borderRadius: '100px',
          fontWeight: 'bold',
          backgroundColor: '#F3005A',
          '&:hover': {
            backgroundColor: '#01182E',
          },
          '&:focus': {
            backgroundColor: '#00ACD8',
          },
        },
        containedSecondary: {
          backgroundColor: '#000000',
          borderRadius: '100px',
          color: 'white',
        },
        outlinedPrimary: {
          borderRadius: '100px',
          borderWidth: '1px',
          borderColor: '#262D40',
          '&:hover': {
            borderWidth: '1px',
            borderColor: '#35AAC5',
          },
        },
        textSecondary: {
          textTransform: 'none',
          fontSize: '0.9rem',
          padding: '2px 14px',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: '#262D40',
          lineHeight: 1.1,
        },
        h1: {
          fontSize: '40px',
          '@media (min-width:600px)': {
            fontSize: '48px',
          },
        },
        h2: {
          fontSize: '36px',
          '@media (min-width:600px)': {
            fontSize: '40px',
          },
        },
        h3: {
          fontSize: '25px',
          '@media (min-width:600px)': {
            fontSize: '30px',
          },
        },
        h4: {
          fontSize: '25px',
        },
        h5: {
          fontSize: '20px',
        },
        h6: {
          fontSize: '26px',
        },
        subtitle1: {
          fontSize: '1.2rem',
          lineHeight: 1.1,
        },
        subtitle2: {
          color: '#7F8EB3',
        },
        body1: {
          lineHeight: 1.3,
        },
        body2: {
          lineHeight: 1.3,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '&::placeholder': {
            color: '#262D40',
          },
          color: '#000',
          backgroundColor: 'white',
          borderRadius: '7px',
          borderColor: '#262D40',
          '& label': {
            color: '#35AAC5',
          },
          '& label.Mui-focused': {
            color: '#35AAC5',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          background: 'white',
          height: 50,
        },
        notchedOutline: {
          borderColor: 'rgba(237,241,245,0.8)',
          borderWidth: '0px',
          borderRadius: '5px',
          boxShadow: '-5px 5px 7px rgba(237,241,245,0.8), 5px -5px 7px rgba(237,241,245,0.8)',

        },
      },
    },
    MuiInput: {
      styleOverrides: {
        underline: {
          borderColor: '#35AAC5',
          '&:before': {
            borderColor: '#35AAC5 !important',
          },
          '&:after': {
            borderColor: '#35AAC5',
          },
          '&:hover:not($disabled):not($focused):not($error):before': {
            borderColor: '#35AAC5',
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          borderColor: 'rgba(237,241,245,0.8)',
          borderWidth: '0px',
          borderRadius: '5px',
          boxShadow: '-5px 5px 7px rgba(237,241,245,0.8), 5px -5px 7px rgba(237,241,245,0.8)',
        },
        rounded: {
          borderRadius: '20px',
          borderTop: 'none',
          color: '#262D40',
          '&:before': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          '&$expanded': {
            margin: 0,
          },
        },
        content: {
          margin: '10px 20px',
          '&.Mui-expanded': {
            margin: '0 20px',
          },
        },
        expandIconWrapper: {
          margin: '0 !important',
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          width: '100%',
          '&::placeholder': {
            color: '#262D40',
          },
          '& label': {
            color: '#35AAC5',
            transform: 'translate(14px, 12px) scale(1)',
          },
          '& .MuiOutlinedInput-input': {
            padding: '10.5px 14px',
          },
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: '#262D40',
          height: '1.3em',
          width: '1em',
        },
        colorSecondary: {
          color: 'rgb(200, 203, 207)',
          height: '1.3em',
          width: '1em',
        },
        colorPrimary: {
          color: 'white',
          backgroundColor: '#262D40',
          height: '1.3em',
          width: '1em',
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          alignSelf: 'center',
          // width: '16% !important',
          // height: '16% !important',
          // position: 'relative',
          // left: '42%',
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        mark: {
          width: 0,
        },
      },
    },

    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&:hover': {
            color: '#F3005A',
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '15px',
        },
      },
    },
  },
});

const theme = responsiveFontSizes(muiTheme);

// shared styles
const useSharedStyles = makeStyles({
  mobileSecondaryBackground: {
    backgroundColor: theme.customPalette.background.secondary,
    minHeight: '100vh',
    padding: '10px',
  },
  form_field: {
    width: '80%',
    margin: '0 10%',
  },
  link_no_decoration: {
    textDecoration: 'none',
    color: 'inherit',
  },
  underline: {
    textDecoration: 'underline',
  },
  textInputError: {
    margin: '0 !important',
    marginTop: '10px !important',
  },
});

export { theme, useSharedStyles };

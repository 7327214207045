import React from 'react';
import { useHistory } from 'react-router-dom';
import logo from '../../../../assets/bancame_logo.svg';
import debtsPay from './online-pay.png';
import Header from '../../header';
import handleVisibleTab from '../../helpers';
import HubSpot from '../../../../components/HubspotForm';

function Debts() {
  const history = useHistory();
  React.useEffect(() => {
    document.body.style.backgroundColor = '#E5E5E5';
  }, []);
  const [isInterested, setIsInterested] = React.useState(false);
  const handleIsInterested = () => setIsInterested((o) => !o);
  const handleSubmit = () => setTimeout(() => setIsInterested(false), 2000);

  return (
    <Header type="workers">
      <div className="cards_container">

        <div className="card card_detail">
          <div
            className="card_image"
            style={{
              backgroundImage: `url(${debtsPay})`,
              cursor: 'default',
            }}
          >
            {' '}
            <div style={{ margin: 'auto' }}>
              <span style={{ fontSize: '90px' }}>&#128209;</span>
            </div>

          </div>
          <div className="container">

            <p>¡Ordena tus cuentas y ahorra hasta un 20% con la ayuda de  Banca.Me!</p>
            <object data={logo} width="30px" height="30px"> </object>
          </div>
        </div>
        <div>
          <div
            aria-hidden
            onClick={() => handleVisibleTab('workers', history)}
            style={{
              fontSize: '15px',
              marginBottom: '10px',
              cursor: 'pointer',
              color: '#35aac5',
              textAlign: 'left',
            }}
          >
            <span style={{ fontSize: '20px', marginRight: '5px' }}>&#x2039;</span>
            Atrás

          </div>
          <div style={{ textAlign: 'left', height: '400px', overflowY: 'auto' }}>
            {isInterested

                          && HubSpot(
                            process.env.REACT_APP_PORTAL_ID,
                            process.env.REACT_APP_DEBTS_PAYMENT_FORM_ID,
                            handleSubmit,
                          )}
            {!isInterested && (
              <div>
                <h2>Ordena tus finanzas con Banca.Me</h2>
                <p>
                  Ingresa a nuestra plataforma y te ayudaremos a
                  saber realmente cuantos ingresos y gastos tienes
                  para que puedas ordenar tus finanzas.
                  Podrás
                  {' '}
                  <b>pagar tus cuentas con nosotros y ahorrar hasta un 20%. </b>

                </p>
                <h5>¿Cómo funciona?</h5>
                <ol style={{ fontSize: '18px' }}>
                  <li>Regístrate en nuestra plataforma</li>
                  <li>Descubre cual es tu situación y revisa nuestras recomendaciones</li>
                  <li>Selecciona las cuentas que te gustaría pagar</li>
                </ol>

              </div>
            )}
          </div>

          {!isInterested
                      && (
                      <h5
                        aria-hidden
                        onClick={handleIsInterested}
                        className="interestedFooter"
                      >
                        Estoy interesado
                      </h5>
                      )}
        </div>

      </div>

    </Header>
  );
}

export default Debts;

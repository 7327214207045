import React from 'react';

import {
  Route, Switch,
} from 'react-router-dom';
import SimulationIndex from '../../../pages/simulation';

function SimulationRoutes() {
  const simulationIndex = () => <SimulationIndex />;
  return (
    <Switch>
      <Route exact path="/simulation" component={simulationIndex} />
    </Switch>
  );
}
export default SimulationRoutes;

import React from 'react';
import body4 from '../../../assets/company-report/06_critical/body_4.svg';
import ellipse4 from '../../../assets/company-report/06_critical/ellipse_4.svg';
import logo4 from '../../../assets/bancame_logo_white.svg';
import img4 from '../../../assets/company-report/06_critical/img_4.svg';
import './styles.css';
import StatsPieChart from '../../../components/StatsPieChart';
import logoSans from '../../../assets/bancame_logo_sans.svg';
import { RenderedCompanyReport } from '../../../utils/interfaces/company-report';

interface CRDataInterface {
  reportData: RenderedCompanyReport
}

function CompanyReportCritical(props:CRDataInterface) {
  const { reportData } = props;
  React.useEffect(() => {
    document.body.style.backgroundColor = '#7F8EB3';
  }, []);
  let totalWorkers = 1;
  let totalToIncome = 0;
  let expensesToIncome = 0;
  let debtToIncome = 0;
  if (reportData.companyData) {
    totalWorkers = reportData.companyData.totalToIncome.total;
    totalToIncome = reportData.companyData.totalToIncome.toTakeAction;
    expensesToIncome = reportData.companyData.expensesToIncome.toTakeAction;
    debtToIncome = reportData.companyData.debtToIncome.toTakeAction;
  }
  const data0 = [
    { name: 'totalToIncome', value: +totalToIncome },
    { name: 'rest', value: +totalWorkers - totalToIncome },
  ];
  const data1 = [
    { name: 'totalToIncome', value: +expensesToIncome },
    { name: 'rest', value: +totalWorkers - expensesToIncome },
  ];

  const data2 = [
    { name: 'totalToIncome', value: +debtToIncome },
    { name: 'rest', value: +totalWorkers - debtToIncome },
  ];

  const dataObject:{[key:number]: any} = {
    0: {
      title: 'Nivel de gastos totales crítico',
      data: data0,
      description:
  <>
    <span style={{ fontWeight: 'bold' }}>
      {' '}
      {totalToIncome}
      {' '}
      personas
      {' '}
    </span>
    {' '}
    poseen
    una
    {' '}
    <span style={{ fontWeight: 550 }}> tasa de egresos </span>
    crítica en tu empresa

  </>,
      info: 'La relación Gastos totales/Ingreso bruto es mayor al 17% del sueldo bruto, adaptado del estudio de bienestar financiero de Wells Fargo',
    },
    1: {
      title: 'Nivel de gastos crítico',
      data: data1,
      description:
  <>
    <span style={{ fontWeight: 'bold' }}>
      {' '}
      {expensesToIncome}
      {' '}
      personas
      {' '}
    </span>
    {' '}
    poseen
    una
    {' '}
    <span style={{ fontWeight: 550 }}> tasa de gastos </span>
    crítica en tu empresa

  </>,
      info: 'La relación Gastos/Ingreso bruto es mayor al 17% del sueldo bruto, adaptado del estudio de bienestar financiero de Wells Fargo',
    },
    2: {
      title: 'Nivel de deudas crítico',
      data: data2,
      description:
  <>
    <span style={{ fontWeight: 'bold' }}>
      {' '}
      {debtToIncome}
      {' '}
      personas
      {' '}
    </span>
    {' '}
    poseen
    una
    {' '}
    <span style={{ fontWeight: 550 }}> tasa de deudas </span>
    crítica en tu empresa

  </>,
      info: 'La relación Deudas/Ingreso bruto es mayor al 17% del sueldo bruto, adaptado del estudio de bienestar financiero de Wells Fargo',
    },
  };

  const [selected, setSelected] = React.useState(2);
  const informeWellsFargo = 'https://www.wellsfargo.com/es/goals-credit/smarter-credit/credit-101/debt-to-income-ratio/understanding-dti/';

  return (
    <div>
      <img src={body4} alt="fourthBG" className="fourth_background" />
      <img src={ellipse4} alt="fourthBG" className="ellipse_4" />
      <div className="fourth_body">
        <div className="fourth_header">
          <object data={logo4} width="70px" height="70px"> </object>
          <h1 className="title_4">
            Trabajadores en la empresa que están en un
            {' '}
            <span style={{ color: 'white' }}>estado crítico</span>
          </h1>
        </div>
        <div className="img_4_container">
          <object data={img4} className="img_4"> </object>
        </div>
        <div className="second_content">
          <div className="info_container">
            <div className="chart_container">
              <StatsPieChart
                chartData={dataObject[selected].data}
                size="normal"
              />
            </div>
            <div className="info_subContainer">
              <div className="info_title">

                <h2 className="subtitle_2">
                  {dataObject[selected].title}
                </h2>
                <div style={{ display: 'flex', placeContent: 'center' }}>
                  <div
                    aria-hidden
                    onClick={() => window.open(informeWellsFargo)}
                    style={{ cursor: 'pointer', zIndex: 1 }}
                  >
                    <i className="material-icons" style={{ fontSize: '25px' }}>launch</i>
                  </div>
                  <div className="tooltip" style={{ cursor: 'default' }}>
                    <i className="material-icons" style={{ fontSize: '25px' }}>info_outline</i>
                    <span className="tooltiptext-right" style={{ top: -45 }}>{dataObject[selected].info}</span>
                  </div>
                </div>

              </div>
              <h3 className="description_2">
                {dataObject[selected].description}
              </h3>
              <div className="data_buttons">
                <div
                  aria-hidden
                  className="tooltip"
                  onClick={() => setSelected(0)}
                >
                  <i style={{ color: selected === 0 ? '#F44C7F' : undefined }} className="material-icons">monetization_on</i>
                  <span className="tooltiptext">{dataObject[0].title}</span>
                </div>
                <div className="tooltip">
                  <i
                    aria-hidden
                    style={{ color: selected === 2 ? '#F44C7F' : undefined }}
                    onClick={() => setSelected(2)}
                    className="material-icons"
                  >
                    monetization_on

                  </i>
                  <span className="tooltiptext">{dataObject[2].title}</span>
                  <span className="badge badge-success pull-right"><object data={logoSans} width="14px" height="10px"> </object></span>
                </div>
              </div>
            </div>
            <div />
            <div>
              <div className="upper_arrow"><i style={{ fontSize: 'medium' }} className="material-icons">keyboard_arrow_up</i></div>
              <div className="select_description">
                <div>
                  Click aquí para seleccionar un coeficiente
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
}

export default CompanyReportCritical;

import React from 'react';
import Select from 'react-select';
import img55 from '../../../assets/company-report/07_segmentation/img_55.svg';
import img51 from '../../../assets/company-report/07_segmentation/img_51.svg';
// import img_52 from './img_52.svg';
import img56 from '../../../assets/company-report/07_segmentation/img_56.svg';
import img54 from '../../../assets/company-report/07_segmentation/img_54.svg';
import PartFiveDetailStats from './detail_stats';
import { indicatorsLabels } from '../../../utils/CompanyReportSampleData';
import { RenderedCompanyReport, GroupInterface } from '../../../utils/interfaces/company-report';

const imageSwitch = (attr: string|null) => {
  if (attr === 'gender') {
    return <object className="img_detail_2" data={img51} aria-label="imgEducation" />;
  }
  if (attr === 'costs-center') {
    return <object className="img_detail_2" data={img55} aria-label="imgEducation" />;
  }
  if (attr === 'position') {
    return <object className="img_detail_2" data={img56} aria-label="imgEducation" />;
  }
  if (attr === 'age') {
    return <object className="img_detail_2" data={img54} aria-label="imgEducation" />;
  }
  return <div />;
};
const indicatorLabelSwitch = (statsType:string, optionLabel: string) => {
  switch (statsType) {
    case 'costs-center':
      return optionLabel;
    default:
      return indicatorsLabels[optionLabel];
  }
};

interface CRSegInterface {
  handleToggleDetail: (detail: string) => void,
  statsType: string,
  dataArray: RenderedCompanyReport
}

function CompanyReportSegmentationDetail(props:CRSegInterface) {
  const { handleToggleDetail, statsType, dataArray } = props;
  const optionsSwitch = (attr:string) => {
    if (attr === 'gender') {
      return dataArray.genderOptions;
    }
    if (attr === 'costs-center') {
      return dataArray.costCenterOptions;
    }
    if (attr === 'position') {
      return dataArray.incomeGroupOptions;
    }
    if (attr === 'age') {
      return dataArray.ageGroupOptions;
    }
    return dataArray.genderOptions;
  };
  const [optionValue, setOptionValue] = React.useState(optionsSwitch(statsType)[0]);
  const handleSelection = (selection) => {
    setOptionValue(selection);
  };

  return (
    <div className="detail_content_body">
      <div>
        <div
          aria-hidden
          style={{ textAlign: 'left', marginLeft: 50, cursor: 'pointer' }}
          onClick={() => handleToggleDetail('')}
        >
          <span
            style={{ fontSize: '30px', marginRight: 10 }}
          >
            &#x2039;

          </span>
          Volver atrás

        </div>
        <div className="detail_select">
          {imageSwitch(statsType)}
          <h6 style={{ margin: 0, marginLeft: '-90px' }}>Seleccione estadísticas</h6>
          <Select
            className="react_select"
            noOptionsMessage={() => 'No hay opciones'}
            getOptionValue={(option) => `${option.label}`}
            getOptionLabel={(option) => `${indicatorLabelSwitch(statsType, option.label)}`}
            placeholder="Seleccionar..."
            options={optionsSwitch(statsType).filter(
              (option: GroupInterface) => Object.keys(option).length !== 0,
            )}
            defaultValue={optionsSwitch(statsType)[0]}
            onChange={(selection) => handleSelection(selection)}
          />
        </div>
      </div>
      <div>
        <PartFiveDetailStats data={optionValue} />
        <div style={{ height: '100px' }} />
      </div>
    </div>
  );
}

export default CompanyReportSegmentationDetail;

export const indicatorsLabels:{[key:string]: string} = {
  M: 'Hombres',
  F: 'Mujeres',
  '(0.0, 25.0]': '18 a 25 años',
  '(25.0, 35.0]': '25 a 35 años',
  '(35.0, 45.0]': '35 a 45 años',
  '(45.0, 55.0]': '45 a 55 años',
  '(55.0, 65.0]': '55 a 65 años',
  '(65.0, inf]': '65 o más años',
  '(0.0, 531645.0]': 'Hasta $531.645',
  '(531645.0, 1063291.0]': 'Entre $531.645 y $1.063.291',
  '(1063291.0, 1594936.0]': 'Entre $1.063.291 y $1.594.936',
  '(1594936.0, 2126582.0]': 'Entre $1.594.936 y $2.126.582',
  '(2126582.0, inf]': 'Desde $2.126.582',
};

export const tagleData = {
  company: {
    totalToIncome: {
      good: 63, overCMF: 15, toImprove: 0, toTakeAction: 10, total: 88,
    },
    expensesToIncome: {
      good: 81, overCMF: 2, toImprove: 0, toTakeAction: 5, total: 88,
    },
    debtToIncome: {
      good: 78, overCMF: 3, toImprove: 1, toTakeAction: 6, total: 88,
    },
    canInvest: 23,
    'over15%': 2,
    bancameIndex: 88,
  },
  costCenter: [],
  gender: [{
    debtToIncome: {
      good: 68, overCMF: 2, toImprove: 1, toTakeAction: 3, total: 74,
    },
    totalToIncome: {
      good: 53, overCMF: 14, toImprove: 0, toTakeAction: 7, total: 74,
    },
    expensesToIncome: {
      good: 69, overCMF: 1, toImprove: 0, toTakeAction: 4, total: 74,
    },
    label: 'M',
  }, {
    debtToIncome: {
      good: 10, overCMF: 1, toImprove: 0, toTakeAction: 3, total: 14,
    },
    totalToIncome: {
      good: 10, overCMF: 1, toImprove: 0, toTakeAction: 3, total: 14,
    },
    expensesToIncome: {
      good: 12, overCMF: 1, toImprove: 0, toTakeAction: 1, total: 14,
    },
    label: 'F',
  }],
  role: [{
    debtToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 2, total: 3,
    },
    totalToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 2, total: 3,
    },
    expensesToIncome: {
      good: 2, overCMF: 0, toImprove: 0, toTakeAction: 1, total: 3,
    },
    label: 'Asistente Administrativo',
  }, {
    debtToIncome: {
      good: 9, overCMF: 0, toImprove: 0, toTakeAction: 1, total: 10,
    },
    totalToIncome: {
      good: 4, overCMF: 4, toImprove: 0, toTakeAction: 2, total: 10,
    },
    expensesToIncome: {
      good: 9, overCMF: 0, toImprove: 0, toTakeAction: 1, total: 10,
    },
    label: 'Instalador en Terreno',
  }, {
    debtToIncome: {
      good: 16, overCMF: 2, toImprove: 0, toTakeAction: 1, total: 19,
    },
    totalToIncome: {
      good: 11, overCMF: 6, toImprove: 0, toTakeAction: 2, total: 19,
    },
    expensesToIncome: {
      good: 18, overCMF: 0, toImprove: 0, toTakeAction: 1, total: 19,
    },
    label: 'Maestro Instalador',
  }, {
    debtToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 1, total: 2,
    },
    totalToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 1, total: 2,
    },
    expensesToIncome: {
      good: 1, overCMF: 1, toImprove: 0, toTakeAction: 0, total: 2,
    },
    label: 'Asistente Administrativo y Cobranzas',
  }, {
    debtToIncome: {
      good: 4, overCMF: 0, toImprove: 0, toTakeAction: 1, total: 5,
    },
    totalToIncome: {
      good: 3, overCMF: 1, toImprove: 0, toTakeAction: 1, total: 5,
    },
    expensesToIncome: {
      good: 4, overCMF: 0, toImprove: 0, toTakeAction: 1, total: 5,
    },
    label: 'Chofer',
  }, {
    debtToIncome: {
      good: 1, overCMF: 0, toImprove: 1, toTakeAction: 0, total: 2,
    },
    totalToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 1, total: 2,
    },
    expensesToIncome: {
      good: 2, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 2,
    },
    label: 'Dibujante',
  }, {
    debtToIncome: {
      good: 2, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 2,
    },
    totalToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 1, total: 2,
    },
    expensesToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 1, total: 2,
    },
    label: 'Representante de Ventas',
  }, {
    debtToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    totalToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    expensesToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    label: 'Administrador de Proyectos',
  }, {
    debtToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    totalToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    expensesToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    label: 'Aseo, orden y limpieza',
  }, {
    debtToIncome: {
      good: 2, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 2,
    },
    totalToIncome: {
      good: 2, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 2,
    },
    expensesToIncome: {
      good: 2, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 2,
    },
    label: 'Asistente Administrativo Contabilidad y Finanzas',
  }, {
    debtToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    totalToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    expensesToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    label: 'Asistente Contable',
  }, {
    debtToIncome: {
      good: 2, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 2,
    },
    totalToIncome: {
      good: 2, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 2,
    },
    expensesToIncome: {
      good: 2, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 2,
    },
    label: 'Asistente de Adquisiciones',
  }, {
    debtToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    totalToIncome: {
      good: 0, overCMF: 1, toImprove: 0, toTakeAction: 0, total: 1,
    },
    expensesToIncome: {
      good: 0, overCMF: 1, toImprove: 0, toTakeAction: 0, total: 1,
    },
    label: 'Asistente de Bodega',
  }, {
    debtToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    totalToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    expensesToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    label: 'Auxiliar de Aseo',
  }, {
    debtToIncome: {
      good: 5, overCMF: 1, toImprove: 0, toTakeAction: 0, total: 6,
    },
    totalToIncome: {
      good: 5, overCMF: 1, toImprove: 0, toTakeAction: 0, total: 6,
    },
    expensesToIncome: {
      good: 6, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 6,
    },
    label: 'Ayudante de Bodega',
  }, {
    debtToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    totalToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    expensesToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    label: 'Diseñador',
  }, {
    debtToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    totalToIncome: {
      good: 0, overCMF: 1, toImprove: 0, toTakeAction: 0, total: 1,
    },
    expensesToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    label: 'Diseñador de Riego Tecnificado',
  }, {
    debtToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    totalToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    expensesToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    label: 'Encargada de Administración y Finanzas',
  }, {
    debtToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    totalToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    expensesToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    label: 'Encargado de Bodega',
  }, {
    debtToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    totalToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    expensesToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    label: 'Gerente General',
  }, {
    debtToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    totalToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    expensesToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    label: 'Gerente de Operaciones',
  }, {
    debtToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    totalToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    expensesToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    label: 'Gerente de Proyectos',
  }, {
    debtToIncome: {
      good: 6, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 6,
    },
    totalToIncome: {
      good: 6, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 6,
    },
    expensesToIncome: {
      good: 6, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 6,
    },
    label: 'Ingeniero',
  }, {
    debtToIncome: {
      good: 6, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 6,
    },
    totalToIncome: {
      good: 5, overCMF: 1, toImprove: 0, toTakeAction: 0, total: 6,
    },
    expensesToIncome: {
      good: 6, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 6,
    },
    label: 'Instalador Eléctrico',
  }, {
    debtToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    totalToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    expensesToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    label: 'Jefe de Adquisiciones',
  }, {
    debtToIncome: {
      good: 2, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 2,
    },
    totalToIncome: {
      good: 2, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 2,
    },
    expensesToIncome: {
      good: 2, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 2,
    },
    label: 'Jefe de Bodega',
  }, {
    debtToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    totalToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    expensesToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    label: 'Jefe de Desarrollo e innovación',
  }, {
    debtToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    totalToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    expensesToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    label: 'Jefe de Recursos Humanos',
  }, {
    debtToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    totalToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    expensesToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    label: 'Jefe de Terreno',
  }, {
    debtToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    totalToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    expensesToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    label: 'Maestro Electromecánico e Instalador',
  }, {
    debtToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    totalToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    expensesToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    label: 'Soporte Informático Sistemas',
  }, {
    debtToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    totalToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    expensesToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    label: 'Sub Gerente Comercial',
  }, {
    debtToIncome: {
      good: 2, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 2,
    },
    totalToIncome: {
      good: 2, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 2,
    },
    expensesToIncome: {
      good: 2, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 2,
    },
    label: 'Supervisor de Terreno',
  }],
  ageGroups: [{
    debtToIncome: {
      good: 9, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 9,
    },
    totalToIncome: {
      good: 6, overCMF: 3, toImprove: 0, toTakeAction: 0, total: 9,
    },
    expensesToIncome: {
      good: 9, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 9,
    },
    label: '(0.0, 25.0]',
  }, {
    debtToIncome: {
      good: 25, overCMF: 1, toImprove: 1, toTakeAction: 2, total: 29,
    },
    totalToIncome: {
      good: 22, overCMF: 4, toImprove: 0, toTakeAction: 3, total: 29,
    },
    expensesToIncome: {
      good: 28, overCMF: 0, toImprove: 0, toTakeAction: 1, total: 29,
    },
    label: '(25.0, 35.0]',
  }, {
    debtToIncome: {
      good: 17, overCMF: 2, toImprove: 0, toTakeAction: 3, total: 22,
    },
    totalToIncome: {
      good: 13, overCMF: 6, toImprove: 0, toTakeAction: 3, total: 22,
    },
    expensesToIncome: {
      good: 21, overCMF: 1, toImprove: 0, toTakeAction: 0, total: 22,
    },
    label: '(35.0, 45.0]',
  }, {
    debtToIncome: {
      good: 18, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 18,
    },
    totalToIncome: {
      good: 15, overCMF: 1, toImprove: 0, toTakeAction: 2, total: 18,
    },
    expensesToIncome: {
      good: 16, overCMF: 0, toImprove: 0, toTakeAction: 2, total: 18,
    },
    label: '(45.0, 55.0]',
  }, {
    debtToIncome: {
      good: 7, overCMF: 0, toImprove: 0, toTakeAction: 1, total: 8,
    },
    totalToIncome: {
      good: 6, overCMF: 0, toImprove: 0, toTakeAction: 2, total: 8,
    },
    expensesToIncome: {
      good: 6, overCMF: 0, toImprove: 0, toTakeAction: 2, total: 8,
    },
    label: '(55.0, 65.0]',
  }, {
    debtToIncome: {
      good: 2, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 2,
    },
    totalToIncome: {
      good: 1, overCMF: 1, toImprove: 0, toTakeAction: 0, total: 2,
    },
    expensesToIncome: {
      good: 1, overCMF: 1, toImprove: 0, toTakeAction: 0, total: 2,
    },
    label: '(65.0, inf]',
  }],
  incomeGroups: [{
    debtToIncome: {
      good: 3, overCMF: 0, toImprove: 0, toTakeAction: 1, total: 4,
    },
    totalToIncome: {
      good: 3, overCMF: 0, toImprove: 0, toTakeAction: 1, total: 4,
    },
    expensesToIncome: {
      good: 4, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 4,
    },
    label: '(0.0, 531645.0]',
  }, {
    debtToIncome: {
      good: 32, overCMF: 2, toImprove: 1, toTakeAction: 3, total: 38,
    },
    totalToIncome: {
      good: 23, overCMF: 11, toImprove: 0, toTakeAction: 4, total: 38,
    },
    expensesToIncome: {
      good: 36, overCMF: 2, toImprove: 0, toTakeAction: 0, total: 38,
    },
    label: '(531645.0, 1063291.0]',
  }, {
    debtToIncome: {
      good: 17, overCMF: 1, toImprove: 0, toTakeAction: 0, total: 18,
    },
    totalToIncome: {
      good: 15, overCMF: 3, toImprove: 0, toTakeAction: 0, total: 18,
    },
    expensesToIncome: {
      good: 18, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 18,
    },
    label: '(1063291.0, 1594936.0]',
  }, {
    debtToIncome: {
      good: 7, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 7,
    },
    totalToIncome: {
      good: 7, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 7,
    },
    expensesToIncome: {
      good: 7, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 7,
    },
    label: '(1594936.0, 2126582.0]',
  }, {
    debtToIncome: {
      good: 15, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 15,
    },
    totalToIncome: {
      good: 14, overCMF: 1, toImprove: 0, toTakeAction: 0, total: 15,
    },
    expensesToIncome: {
      good: 15, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 15,
    },
    label: '(2126582.0, inf]',
  }],
};

export const smartCFOdata = {
  company: {
    totalToIncome: {
      good: 26, overCMF: 1, toImprove: 1, toTakeAction: 1, total: 29,
    },
    expensesToIncome: {
      good: 27, overCMF: 1, toImprove: 0, toTakeAction: 1, total: 29,
    },
    debtToIncome: {
      good: 28, overCMF: 1, toImprove: 0, toTakeAction: 0, total: 29,
    },
    canInvest: 10,
    'over15%': 0,
    bancameIndex: 93,
  },
  costCenter: [{
    debtToIncome: {
      good: 8, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 8,
    },
    totalToIncome: {
      good: 7, overCMF: 0, toImprove: 0, toTakeAction: 1, total: 8,
    },
    expensesToIncome: {
      good: 7, overCMF: 0, toImprove: 0, toTakeAction: 1, total: 8,
    },
    label: 'Tesorería',
  }, {
    debtToIncome: {
      good: 3, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 3,
    },
    totalToIncome: {
      good: 2, overCMF: 0, toImprove: 1, toTakeAction: 0, total: 3,
    },
    expensesToIncome: {
      good: 2, overCMF: 1, toImprove: 0, toTakeAction: 0, total: 3,
    },
    label: 'Administracion',
  }, {
    debtToIncome: {
      good: 3, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 3,
    },
    totalToIncome: {
      good: 3, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 3,
    },
    expensesToIncome: {
      good: 3, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 3,
    },
    label: 'BI',
  }, {
    debtToIncome: {
      good: 4, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 4,
    },
    totalToIncome: {
      good: 4, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 4,
    },
    expensesToIncome: {
      good: 4, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 4,
    },
    label: 'Cliente',
  }, {
    debtToIncome: {
      good: 3, overCMF: 1, toImprove: 0, toTakeAction: 0, total: 4,
    },
    totalToIncome: {
      good: 3, overCMF: 1, toImprove: 0, toTakeAction: 0, total: 4,
    },
    expensesToIncome: {
      good: 4, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 4,
    },
    label: 'Contabilidad',
  }, {
    debtToIncome: {
      good: 4, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 4,
    },
    totalToIncome: {
      good: 4, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 4,
    },
    expensesToIncome: {
      good: 4, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 4,
    },
    label: 'Desarrollo',
  }, {
    debtToIncome: {
      good: 3, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 3,
    },
    totalToIncome: {
      good: 3, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 3,
    },
    expensesToIncome: {
      good: 3, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 3,
    },
    label: 'RRHH',
  }],
  gender: [{
    debtToIncome: {
      good: 15, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 15,
    },
    totalToIncome: {
      good: 15, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 15,
    },
    expensesToIncome: {
      good: 15, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 15,
    },
    label: 'M',
  }, {
    debtToIncome: {
      good: 13, overCMF: 1, toImprove: 0, toTakeAction: 0, total: 14,
    },
    totalToIncome: {
      good: 11, overCMF: 1, toImprove: 1, toTakeAction: 1, total: 14,
    },
    expensesToIncome: {
      good: 12, overCMF: 1, toImprove: 0, toTakeAction: 1, total: 14,
    },
    label: 'F',
  }],
  role: [{
    debtToIncome: {
      good: 7, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 7,
    },
    totalToIncome: {
      good: 6, overCMF: 0, toImprove: 0, toTakeAction: 1, total: 7,
    },
    expensesToIncome: {
      good: 6, overCMF: 0, toImprove: 0, toTakeAction: 1, total: 7,
    },
    label: 'Tesorero',
  }, {
    debtToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    totalToIncome: {
      good: 0, overCMF: 0, toImprove: 1, toTakeAction: 0, total: 1,
    },
    expensesToIncome: {
      good: 0, overCMF: 1, toImprove: 0, toTakeAction: 0, total: 1,
    },
    label: 'Auxiliar de Aseo',
  }, {
    debtToIncome: {
      good: 2, overCMF: 1, toImprove: 0, toTakeAction: 0, total: 3,
    },
    totalToIncome: {
      good: 2, overCMF: 1, toImprove: 0, toTakeAction: 0, total: 3,
    },
    expensesToIncome: {
      good: 3, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 3,
    },
    label: 'Analista Contable',
  }, {
    debtToIncome: {
      good: 2, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 2,
    },
    totalToIncome: {
      good: 2, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 2,
    },
    expensesToIncome: {
      good: 2, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 2,
    },
    label: 'Analista de Remuneraciones',
  }, {
    debtToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    totalToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    expensesToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    label: 'Asistente Administrativo',
  }, {
    debtToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    totalToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    expensesToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    label: 'Business Developer',
  }, {
    debtToIncome: {
      good: 2, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 2,
    },
    totalToIncome: {
      good: 2, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 2,
    },
    expensesToIncome: {
      good: 2, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 2,
    },
    label: 'Business Intelligence Analyst',
  }, {
    debtToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    totalToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    expensesToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    label: 'Business Intelligence Leader',
  }, {
    debtToIncome: {
      good: 2, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 2,
    },
    totalToIncome: {
      good: 2, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 2,
    },
    expensesToIncome: {
      good: 2, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 2,
    },
    label: 'Customer Success Specialist',
  }, {
    debtToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    totalToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    expensesToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    label: 'Customer Support',
  }, {
    debtToIncome: {
      good: 3, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 3,
    },
    totalToIncome: {
      good: 3, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 3,
    },
    expensesToIncome: {
      good: 3, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 3,
    },
    label: 'Desarrollador Fullstack',
  }, {
    debtToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    totalToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    expensesToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    label: 'Encargada de Contabilidad',
  }, {
    debtToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    totalToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    expensesToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    label: 'Gerente General',
  }, {
    debtToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    totalToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    expensesToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    label: 'Gerente de Desarrollo',
  }, {
    debtToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    totalToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    expensesToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    label: 'Head of Finance',
  }, {
    debtToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    totalToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    expensesToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    label: 'Líder de Tesorería',
  }],
  ageGroups: [{
    debtToIncome: {
      good: 10, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 10,
    },
    totalToIncome: {
      good: 10, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 10,
    },
    expensesToIncome: {
      good: 10, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 10,
    },
    label: '(0.0, 25.0]',
  }, {
    debtToIncome: {
      good: 14, overCMF: 1, toImprove: 0, toTakeAction: 0, total: 15,
    },
    totalToIncome: {
      good: 13, overCMF: 1, toImprove: 0, toTakeAction: 1, total: 15,
    },
    expensesToIncome: {
      good: 14, overCMF: 0, toImprove: 0, toTakeAction: 1, total: 15,
    },
    label: '(25.0, 35.0]',
  }, {
    debtToIncome: {
      good: 4, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 4,
    },
    totalToIncome: {
      good: 3, overCMF: 0, toImprove: 1, toTakeAction: 0, total: 4,
    },
    expensesToIncome: {
      good: 3, overCMF: 1, toImprove: 0, toTakeAction: 0, total: 4,
    },
    label: '(35.0, 45.0]',
  }, [], [], []],
  incomeGroups: [{
    debtToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    totalToIncome: {
      good: 0, overCMF: 0, toImprove: 1, toTakeAction: 0, total: 1,
    },
    expensesToIncome: {
      good: 0, overCMF: 1, toImprove: 0, toTakeAction: 0, total: 1,
    },
    label: '(0.0, 531645.0]',
  }, {
    debtToIncome: {
      good: 14, overCMF: 1, toImprove: 0, toTakeAction: 0, total: 15,
    },
    totalToIncome: {
      good: 13, overCMF: 1, toImprove: 0, toTakeAction: 1, total: 15,
    },
    expensesToIncome: {
      good: 14, overCMF: 0, toImprove: 0, toTakeAction: 1, total: 15,
    },
    label: '(531645.0, 1063291.0]',
  }, {
    debtToIncome: {
      good: 8, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 8,
    },
    totalToIncome: {
      good: 8, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 8,
    },
    expensesToIncome: {
      good: 8, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 8,
    },
    label: '(1063291.0, 1594936.0]',
  }, {
    debtToIncome: {
      good: 2, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 2,
    },
    totalToIncome: {
      good: 2, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 2,
    },
    expensesToIncome: {
      good: 2, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 2,
    },
    label: '(1594936.0, 2126582.0]',
  }, {
    debtToIncome: {
      good: 3, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 3,
    },
    totalToIncome: {
      good: 3, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 3,
    },
    expensesToIncome: {
      good: 3, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 3,
    },
    label: '(2126582.0, inf]',
  }],
};

export const rembreData = {
  company:
      {
        totalToIncome:
         {
           good: 34, overCMF: 8, toImprove: 2, toTakeAction: 17, total: 61,
         },
        expensesToIncome:
         {
           good: 46, overCMF: 2, toImprove: 2, toTakeAction: 11, total: 61,
         },
        debtToIncome:
         {
           good: 45, overCMF: 6, toImprove: 1, toTakeAction: 9, total: 61,
         },
        canInvest: 11,
        'over15%': 5,
        bancameIndex: 68,
      },
  costCenter: [
    {
      debtToIncome: {
        good: 42, overCMF: 4, toImprove: 1, toTakeAction: 9, total: 56,
      },
      totalToIncome: {
        good: 31, overCMF: 7, toImprove: 2, toTakeAction: 16, total: 56,
      },
      expensesToIncome: {
        good: 42, overCMF: 2, toImprove: 2, toTakeAction: 10, total: 56,
      },
      label: 'Zona Central',
    },
    {
      debtToIncome: {
        good: 1, overCMF: 1, toImprove: 0, toTakeAction: 0, total: 2,
      },
      totalToIncome: {
        good: 1, overCMF: 0, toImprove: 0, toTakeAction: 1, total: 2,
      },
      expensesToIncome: {
        good: 1, overCMF: 0, toImprove: 0, toTakeAction: 1, total: 2,
      },
      label: 'Home',
    }, {
      debtToIncome: {
        good: 2, overCMF: 1, toImprove: 0, toTakeAction: 0, total: 3,
      },
      totalToIncome: {
        good: 2, overCMF: 1, toImprove: 0, toTakeAction: 0, total: 3,
      },
      expensesToIncome: {
        good: 3, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 3,
      },
      label: 'Zona Norte',
    }],
  gender: [{
    debtToIncome: {
      good: 31, overCMF: 6, toImprove: 1, toTakeAction: 5, total: 43,
    },
    totalToIncome: {
      good: 22, overCMF: 8, toImprove: 2, toTakeAction: 11, total: 43,
    },
    expensesToIncome: {
      good: 32, overCMF: 1, toImprove: 1, toTakeAction: 9, total: 43,
    },
    label: 'M',
  }, {
    debtToIncome: {
      good: 14, overCMF: 0, toImprove: 0, toTakeAction: 4, total: 18,
    },
    totalToIncome: {
      good: 12, overCMF: 0, toImprove: 0, toTakeAction: 6, total: 18,
    },
    expensesToIncome: {
      good: 14, overCMF: 1, toImprove: 1, toTakeAction: 2, total: 18,
    },
    label: 'F',
  }],
  role: [{
    debtToIncome: {
      good: 2, overCMF: 0, toImprove: 1, toTakeAction: 1, total: 4,
    },
    totalToIncome: {
      good: 1, overCMF: 0, toImprove: 1, toTakeAction: 2, total: 4,
    },
    expensesToIncome: {
      good: 3, overCMF: 0, toImprove: 0, toTakeAction: 1, total: 4,
    },
    label: 'ATENCÓN PUBLICO PUNTOS LIMPIOS',
  }, {
    debtToIncome: {
      good: 3, overCMF: 0, toImprove: 0, toTakeAction: 1, total: 4,
    },
    totalToIncome: {
      good: 2, overCMF: 0, toImprove: 0, toTakeAction: 2, total: 4,
    },
    expensesToIncome: {
      good: 2, overCMF: 0, toImprove: 0, toTakeAction: 2, total: 4,
    },
    label: 'CONDUCTOR CLASE B Y A4',
  }, {
    debtToIncome: {
      good: 0, overCMF: 0, toImprove: 0, toTakeAction: 2, total: 2,
    },
    totalToIncome: {
      good: 0, overCMF: 0, toImprove: 0, toTakeAction: 2, total: 2,
    },
    expensesToIncome: {
      good: 1, overCMF: 1, toImprove: 0, toTakeAction: 0, total: 2,
    },
    label: 'OPERADOR',
  }, {
    debtToIncome: {
      good: 5, overCMF: 1, toImprove: 0, toTakeAction: 0, total: 6,
    },
    totalToIncome: {
      good: 4, overCMF: 0, toImprove: 0, toTakeAction: 2, total: 6,
    },
    expensesToIncome: {
      good: 4, overCMF: 0, toImprove: 1, toTakeAction: 1, total: 6,
    },
    label: 'OPERADOR PEONETA',
  }, {
    debtToIncome: {
      good: 3, overCMF: 1, toImprove: 0, toTakeAction: 2, total: 6,
    },
    totalToIncome: {
      good: 3, overCMF: 1, toImprove: 0, toTakeAction: 2, total: 6,
    },
    expensesToIncome: {
      good: 4, overCMF: 0, toImprove: 0, toTakeAction: 2, total: 6,
    },
    label: 'OPERADOR Y ENCARGADO DE ATENCION PUNTO LIMPIO',
  }, {
    debtToIncome: {
      good: 2, overCMF: 1, toImprove: 0, toTakeAction: 1, total: 4,
    },
    totalToIncome: {
      good: 2, overCMF: 0, toImprove: 1, toTakeAction: 1, total: 4,
    },
    expensesToIncome: {
      good: 2, overCMF: 1, toImprove: 1, toTakeAction: 0, total: 4,
    },
    label: 'OPERADOR PUNTO LIMPIO',
  }, {
    debtToIncome: {
      good: 2, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 2,
    },
    totalToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 1, total: 2,
    },
    expensesToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 1, total: 2,
    },
    label: 'ATENCIÓN PUNTOS LIMPIOS',
  }, {
    debtToIncome: {
      good: 3, overCMF: 1, toImprove: 0, toTakeAction: 0, total: 4,
    },
    totalToIncome: {
      good: 3, overCMF: 0, toImprove: 0, toTakeAction: 1, total: 4,
    },
    expensesToIncome: {
      good: 3, overCMF: 0, toImprove: 0, toTakeAction: 1, total: 4,
    },
    label: 'COORDINADOR LOGISTICO',
  }, {
    debtToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    totalToIncome: {
      good: 0, overCMF: 0, toImprove: 0, toTakeAction: 1, total: 1,
    },
    expensesToIncome: {
      good: 0, overCMF: 0, toImprove: 0, toTakeAction: 1, total: 1,
    },
    label: 'COORDINADOR Y SUPERVISOR DE TERRENO',
  }, {
    debtToIncome: {
      good: 0, overCMF: 0, toImprove: 0, toTakeAction: 1, total: 1,
    },
    totalToIncome: {
      good: 0, overCMF: 0, toImprove: 0, toTakeAction: 1, total: 1,
    },
    expensesToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    label: 'Encargado Punto Limpio Piedra Roja',
  }, {
    debtToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    totalToIncome: {
      good: 0, overCMF: 0, toImprove: 0, toTakeAction: 1, total: 1,
    },
    expensesToIncome: {
      good: 0, overCMF: 0, toImprove: 0, toTakeAction: 1, total: 1,
    },
    label: 'Operadora',
  }, {
    debtToIncome: {
      good: 0, overCMF: 0, toImprove: 0, toTakeAction: 1, total: 1,
    },
    totalToIncome: {
      good: 0, overCMF: 0, toImprove: 0, toTakeAction: 1, total: 1,
    },
    expensesToIncome: {
      good: 0, overCMF: 0, toImprove: 0, toTakeAction: 1, total: 1,
    },
    label: 'Peoneta',
  }, {
    debtToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    totalToIncome: {
      good: 0, overCMF: 1, toImprove: 0, toTakeAction: 0, total: 1,
    },
    expensesToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    label: 'Coordinador Turno Tarde',
  }, {
    debtToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    totalToIncome: {
      good: 0, overCMF: 1, toImprove: 0, toTakeAction: 0, total: 1,
    },
    expensesToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    label: 'Encargado Planta SK Noviciado',
  }, {
    debtToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    totalToIncome: {
      good: 0, overCMF: 1, toImprove: 0, toTakeAction: 0, total: 1,
    },
    expensesToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    label: 'Encargado Punto Industrial Derco',
  }, {
    debtToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    totalToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    expensesToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    label: 'Encargado Punto Movil',
  }, {
    debtToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    totalToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    expensesToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    label: 'Encargado de Desarrollos',
  }, {
    debtToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    totalToIncome: {
      good: 0, overCMF: 1, toImprove: 0, toTakeAction: 0, total: 1,
    },
    expensesToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    label: 'JEFE DE ECOVALORIZACIÓN',
  }, {
    debtToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    totalToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    expensesToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    label: 'JEFE DE PLANTA',
  }, {
    debtToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    totalToIncome: {
      good: 0, overCMF: 1, toImprove: 0, toTakeAction: 0, total: 1,
    },
    expensesToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    label: 'Mantencion de Maquinarias, Equipos y Herramientas',
  }, {
    debtToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    totalToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    expensesToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    label: 'OPERADOR PLANTA',
  }, {
    debtToIncome: {
      good: 0, overCMF: 1, toImprove: 0, toTakeAction: 0, total: 1,
    },
    totalToIncome: {
      good: 0, overCMF: 1, toImprove: 0, toTakeAction: 0, total: 1,
    },
    expensesToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    label: 'OPERADOR Y ENCARGADO DE MANTENIMIENTO MAQUINARIAS',
  }, {
    debtToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    totalToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    expensesToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    label: 'Operador',
  }, {
    debtToIncome: {
      good: 0, overCMF: 1, toImprove: 0, toTakeAction: 0, total: 1,
    },
    totalToIncome: {
      good: 0, overCMF: 1, toImprove: 0, toTakeAction: 0, total: 1,
    },
    expensesToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    label: 'Operador Grua Horquilla',
  }, {
    debtToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    totalToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    expensesToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    label: 'Operador Maquinista',
  }, {
    debtToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    totalToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    expensesToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    label: 'Operador Planta',
  }, {
    debtToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    totalToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    expensesToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    label: 'Operador Punto Limpio Estacion Central',
  }, {
    debtToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    totalToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    expensesToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    label: 'Operador de Punto Limpio Industrial',
  }, {
    debtToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    totalToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    expensesToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    label: 'Operador de Punto Limpio Movil y Otros',
  }, {
    debtToIncome: {
      good: 3, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 3,
    },
    totalToIncome: {
      good: 3, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 3,
    },
    expensesToIncome: {
      good: 3, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 3,
    },
    label: 'Operador y Atencion Publico Punto Limpio',
  }, {
    debtToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    totalToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    expensesToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    label: 'Operador y Supervisor Turno',
  }, {
    debtToIncome: {
      good: 2, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 2,
    },
    totalToIncome: {
      good: 2, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 2,
    },
    expensesToIncome: {
      good: 2, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 2,
    },
    label: 'PEONETA',
  }, {
    debtToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    totalToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    expensesToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    label: 'SEGREGADOR MAQUINISTA',
  }, {
    debtToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    totalToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    expensesToIncome: {
      good: 1, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 1,
    },
    label: 'SUPERVISOR ESTACIÓN CENTRAL',
  }],
  ageGroups: [{
    debtToIncome: {
      good: 16, overCMF: 0, toImprove: 1, toTakeAction: 0, total: 17,
    },
    totalToIncome: {
      good: 11, overCMF: 2, toImprove: 1, toTakeAction: 3, total: 17,
    },
    expensesToIncome: {
      good: 14, overCMF: 0, toImprove: 1, toTakeAction: 2, total: 17,
    },
    label: '(0.0, 25.0]',
  }, {
    debtToIncome: {
      good: 12, overCMF: 4, toImprove: 0, toTakeAction: 1, total: 17,
    },
    totalToIncome: {
      good: 8, overCMF: 5, toImprove: 1, toTakeAction: 3, total: 17,
    },
    expensesToIncome: {
      good: 13, overCMF: 1, toImprove: 1, toTakeAction: 2, total: 17,
    },
    label: '(25.0, 35.0]',
  }, {
    debtToIncome: {
      good: 8, overCMF: 1, toImprove: 0, toTakeAction: 3, total: 12,
    },
    totalToIncome: {
      good: 7, overCMF: 1, toImprove: 0, toTakeAction: 4, total: 12,
    },
    expensesToIncome: {
      good: 9, overCMF: 0, toImprove: 0, toTakeAction: 3, total: 12,
    },
    label: '(35.0, 45.0]',
  }, {
    debtToIncome: {
      good: 5, overCMF: 1, toImprove: 0, toTakeAction: 3, total: 9,
    },
    totalToIncome: {
      good: 4, overCMF: 0, toImprove: 0, toTakeAction: 5, total: 9,
    },
    expensesToIncome: {
      good: 5, overCMF: 1, toImprove: 0, toTakeAction: 3, total: 9,
    },
    label: '(45.0, 55.0]',
  }, {
    debtToIncome: {
      good: 4, overCMF: 0, toImprove: 0, toTakeAction: 2, total: 6,
    },
    totalToIncome: {
      good: 4, overCMF: 0, toImprove: 0, toTakeAction: 2, total: 6,
    },
    expensesToIncome: {
      good: 5, overCMF: 0, toImprove: 0, toTakeAction: 1, total: 6,
    },
    label: '(55.0, 65.0]',
  }],
  incomeGroups: [{
    debtToIncome: {
      good: 17, overCMF: 1, toImprove: 0, toTakeAction: 5, total: 23,
    },
    totalToIncome: {
      good: 14, overCMF: 1, toImprove: 1, toTakeAction: 7, total: 23,
    },
    expensesToIncome: {
      good: 16, overCMF: 2, toImprove: 2, toTakeAction: 3, total: 23,
    },
    label: '(0.0, 531645.0]',
  }, {
    debtToIncome: {
      good: 25, overCMF: 5, toImprove: 1, toTakeAction: 3, total: 34,
    },
    totalToIncome: {
      good: 19, overCMF: 6, toImprove: 1, toTakeAction: 8, total: 34,
    },
    expensesToIncome: {
      good: 28, overCMF: 0, toImprove: 0, toTakeAction: 6, total: 34,
    },
    label: '(531645.0, 1063291.0]',
  }, {
    debtToIncome: {
      good: 2, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 2,
    },
    totalToIncome: {
      good: 1, overCMF: 1, toImprove: 0, toTakeAction: 0, total: 2,
    },
    expensesToIncome: {
      good: 2, overCMF: 0, toImprove: 0, toTakeAction: 0, total: 2,
    },
    label: '(1063291.0, 1594936.0]',
  }],
};

export const fullData = {
  company: {
    totalToIncome: {
      good: 209,
      overCMF: 30,
      toImprove: 15,
      toTakeAction: 46,
      total: 300,
    },
    expensesToIncome: {
      good: 249,
      overCMF: 16,
      toImprove: 6,
      toTakeAction: 29,
      total: 300,
    },
    debtToIncome: {
      good: 251,
      overCMF: 20,
      toImprove: 10,
      toTakeAction: 19,
      total: 300,
    },
    canInvest: 83,
    'over15%': 3,
    bancameIndex: 79,
  },
  costCenter: [
    {
      debtToIncome: {
        good: 7,
        overCMF: 1,
        toImprove: 0,
        toTakeAction: 0,
        total: 8,
      },
      totalToIncome: {
        good: 3,
        overCMF: 2,
        toImprove: 0,
        toTakeAction: 3,
        total: 8,
      },
      expensesToIncome: {
        good: 5,
        overCMF: 0,
        toImprove: 1,
        toTakeAction: 2,
        total: 8,
      },
      label: 'Viña',
    },
    {
      debtToIncome: {
        good: 5,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 5,
      },
      totalToIncome: {
        good: 1,
        overCMF: 1,
        toImprove: 1,
        toTakeAction: 2,
        total: 5,
      },
      expensesToIncome: {
        good: 1,
        overCMF: 1,
        toImprove: 1,
        toTakeAction: 2,
        total: 5,
      },
      label: 'Quinchamali',
    },
    {
      debtToIncome: {
        good: 7,
        overCMF: 2,
        toImprove: 0,
        toTakeAction: 1,
        total: 10,
      },
      totalToIncome: {
        good: 6,
        overCMF: 1,
        toImprove: 1,
        toTakeAction: 2,
        total: 10,
      },
      expensesToIncome: {
        good: 9,
        overCMF: 1,
        toImprove: 0,
        toTakeAction: 0,
        total: 10,
      },
      label: 'San Felipe',
    },
    {
      debtToIncome: {
        good: 17,
        overCMF: 1,
        toImprove: 0,
        toTakeAction: 0,
        total: 18,
      },
      totalToIncome: {
        good: 15,
        overCMF: 0,
        toImprove: 2,
        toTakeAction: 1,
        total: 18,
      },
      expensesToIncome: {
        good: 16,
        overCMF: 1,
        toImprove: 0,
        toTakeAction: 1,
        total: 18,
      },
      label: 'Administracion & Finanzas',
    },
    {
      debtToIncome: {
        good: 6,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 1,
        total: 7,
      },
      totalToIncome: {
        good: 5,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 2,
        total: 7,
      },
      expensesToIncome: {
        good: 6,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 1,
        total: 7,
      },
      label: 'Alameda',
    },
    {
      debtToIncome: {
        good: 4,
        overCMF: 1,
        toImprove: 0,
        toTakeAction: 1,
        total: 6,
      },
      totalToIncome: {
        good: 3,
        overCMF: 1,
        toImprove: 0,
        toTakeAction: 2,
        total: 6,
      },
      expensesToIncome: {
        good: 3,
        overCMF: 2,
        toImprove: 1,
        toTakeAction: 0,
        total: 6,
      },
      label: 'Almirante Barroso',
    },
    {
      debtToIncome: {
        good: 5,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 2,
        total: 7,
      },
      totalToIncome: {
        good: 4,
        overCMF: 1,
        toImprove: 0,
        toTakeAction: 2,
        total: 7,
      },
      expensesToIncome: {
        good: 6,
        overCMF: 1,
        toImprove: 0,
        toTakeAction: 0,
        total: 7,
      },
      label: 'Chiguayante',
    },
    {
      debtToIncome: {
        good: 3,
        overCMF: 1,
        toImprove: 0,
        toTakeAction: 2,
        total: 6,
      },
      totalToIncome: {
        good: 3,
        overCMF: 1,
        toImprove: 0,
        toTakeAction: 2,
        total: 6,
      },
      expensesToIncome: {
        good: 4,
        overCMF: 0,
        toImprove: 1,
        toTakeAction: 1,
        total: 6,
      },
      label: 'La Dehesa',
    },
    {
      debtToIncome: {
        good: 3,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 3,
      },
      totalToIncome: {
        good: 1,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 2,
        total: 3,
      },
      expensesToIncome: {
        good: 1,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 2,
        total: 3,
      },
      label: 'La Laguna',
    },
    {
      debtToIncome: {
        good: 7,
        overCMF: 1,
        toImprove: 0,
        toTakeAction: 0,
        total: 8,
      },
      totalToIncome: {
        good: 6,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 2,
        total: 8,
      },
      expensesToIncome: {
        good: 6,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 2,
        total: 8,
      },
      label: 'Manuel Montt 211',
    },
    {
      debtToIncome: {
        good: 5,
        overCMF: 0,
        toImprove: 1,
        toTakeAction: 1,
        total: 7,
      },
      totalToIncome: {
        good: 5,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 2,
        total: 7,
      },
      expensesToIncome: {
        good: 5,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 2,
        total: 7,
      },
      label: 'Providencia',
    },
    {
      debtToIncome: {
        good: 4,
        overCMF: 1,
        toImprove: 2,
        toTakeAction: 0,
        total: 7,
      },
      totalToIncome: {
        good: 4,
        overCMF: 0,
        toImprove: 2,
        toTakeAction: 1,
        total: 7,
      },
      expensesToIncome: {
        good: 6,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 1,
        total: 7,
      },
      label: 'Quillota',
    },
    {
      debtToIncome: {
        good: 3,
        overCMF: 0,
        toImprove: 1,
        toTakeAction: 1,
        total: 5,
      },
      totalToIncome: {
        good: 2,
        overCMF: 1,
        toImprove: 0,
        toTakeAction: 2,
        total: 5,
      },
      expensesToIncome: {
        good: 2,
        overCMF: 3,
        toImprove: 0,
        toTakeAction: 0,
        total: 5,
      },
      label: 'Rondizzoni',
    },
    {
      debtToIncome: {
        good: 13,
        overCMF: 1,
        toImprove: 0,
        toTakeAction: 0,
        total: 14,
      },
      totalToIncome: {
        good: 9,
        overCMF: 3,
        toImprove: 0,
        toTakeAction: 2,
        total: 14,
      },
      expensesToIncome: {
        good: 12,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 2,
        total: 14,
      },
      label: 'San Pablo',
    },
    {
      debtToIncome: {
        good: 2,
        overCMF: 1,
        toImprove: 1,
        toTakeAction: 0,
        total: 4,
      },
      totalToIncome: {
        good: 2,
        overCMF: 0,
        toImprove: 1,
        toTakeAction: 1,
        total: 4,
      },
      expensesToIncome: {
        good: 3,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 1,
        total: 4,
      },
      label: 'Control de Gestion',
    },
    {
      debtToIncome: {
        good: 4,
        overCMF: 0,
        toImprove: 1,
        toTakeAction: 0,
        total: 5,
      },
      totalToIncome: {
        good: 3,
        overCMF: 0,
        toImprove: 1,
        toTakeAction: 1,
        total: 5,
      },
      expensesToIncome: {
        good: 4,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 1,
        total: 5,
      },
      label: 'Cumming',
    },
    {
      debtToIncome: {
        good: 5,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 1,
        total: 6,
      },
      totalToIncome: {
        good: 4,
        overCMF: 0,
        toImprove: 1,
        toTakeAction: 1,
        total: 6,
      },
      expensesToIncome: {
        good: 4,
        overCMF: 1,
        toImprove: 0,
        toTakeAction: 1,
        total: 6,
      },
      label: 'Latadia',
    },
    {
      debtToIncome: {
        good: 4,
        overCMF: 1,
        toImprove: 0,
        toTakeAction: 1,
        total: 6,
      },
      totalToIncome: {
        good: 3,
        overCMF: 1,
        toImprove: 1,
        toTakeAction: 1,
        total: 6,
      },
      expensesToIncome: {
        good: 6,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 6,
      },
      label: 'Lo Errazuriz 2100',
    },
    {
      debtToIncome: {
        good: 4,
        overCMF: 1,
        toImprove: 1,
        toTakeAction: 0,
        total: 6,
      },
      totalToIncome: {
        good: 3,
        overCMF: 1,
        toImprove: 1,
        toTakeAction: 1,
        total: 6,
      },
      expensesToIncome: {
        good: 5,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 1,
        total: 6,
      },
      label: 'Maipu',
    },
    {
      debtToIncome: {
        good: 4,
        overCMF: 0,
        toImprove: 1,
        toTakeAction: 1,
        total: 6,
      },
      totalToIncome: {
        good: 4,
        overCMF: 0,
        toImprove: 1,
        toTakeAction: 1,
        total: 6,
      },
      expensesToIncome: {
        good: 6,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 6,
      },
      label: 'Nataniel Cox',
    },
    {
      debtToIncome: {
        good: 6,
        overCMF: 2,
        toImprove: 0,
        toTakeAction: 0,
        total: 8,
      },
      totalToIncome: {
        good: 4,
        overCMF: 2,
        toImprove: 1,
        toTakeAction: 1,
        total: 8,
      },
      expensesToIncome: {
        good: 6,
        overCMF: 1,
        toImprove: 1,
        toTakeAction: 0,
        total: 8,
      },
      label: 'Portugal 277',
    },
    {
      debtToIncome: {
        good: 5,
        overCMF: 1,
        toImprove: 0,
        toTakeAction: 0,
        total: 6,
      },
      totalToIncome: {
        good: 5,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 1,
        total: 6,
      },
      expensesToIncome: {
        good: 5,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 1,
        total: 6,
      },
      label: '10 de Julio',
    },
    {
      debtToIncome: {
        good: 2,
        overCMF: 1,
        toImprove: 0,
        toTakeAction: 1,
        total: 4,
      },
      totalToIncome: {
        good: 2,
        overCMF: 1,
        toImprove: 0,
        toTakeAction: 1,
        total: 4,
      },
      expensesToIncome: {
        good: 3,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 1,
        total: 4,
      },
      label: 'Batlle y Ordoñez',
    },
    {
      debtToIncome: {
        good: 6,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 6,
      },
      totalToIncome: {
        good: 4,
        overCMF: 1,
        toImprove: 0,
        toTakeAction: 1,
        total: 6,
      },
      expensesToIncome: {
        good: 4,
        overCMF: 1,
        toImprove: 0,
        toTakeAction: 1,
        total: 6,
      },
      label: 'Bilbao',
    },
    {
      debtToIncome: {
        good: 2,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 1,
        total: 3,
      },
      totalToIncome: {
        good: 2,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 1,
        total: 3,
      },
      expensesToIncome: {
        good: 2,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 1,
        total: 3,
      },
      label: 'Gerencia General',
    },
    {
      debtToIncome: {
        good: 5,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 1,
        total: 6,
      },
      totalToIncome: {
        good: 3,
        overCMF: 2,
        toImprove: 0,
        toTakeAction: 1,
        total: 6,
      },
      expensesToIncome: {
        good: 4,
        overCMF: 1,
        toImprove: 0,
        toTakeAction: 1,
        total: 6,
      },
      label: 'Gladys Marin',
    },
    {
      debtToIncome: {
        good: 3,
        overCMF: 0,
        toImprove: 1,
        toTakeAction: 0,
        total: 4,
      },
      totalToIncome: {
        good: 2,
        overCMF: 1,
        toImprove: 0,
        toTakeAction: 1,
        total: 4,
      },
      expensesToIncome: {
        good: 3,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 1,
        total: 4,
      },
      label: 'Huechuraba',
    },
    {
      debtToIncome: {
        good: 4,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 1,
        total: 5,
      },
      totalToIncome: {
        good: 4,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 1,
        total: 5,
      },
      expensesToIncome: {
        good: 5,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 5,
      },
      label: 'Los Andes',
    },
    {
      debtToIncome: {
        good: 3,
        overCMF: 1,
        toImprove: 0,
        toTakeAction: 1,
        total: 5,
      },
      totalToIncome: {
        good: 3,
        overCMF: 1,
        toImprove: 0,
        toTakeAction: 1,
        total: 5,
      },
      expensesToIncome: {
        good: 4,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 1,
        total: 5,
      },
      label: 'Manuel Montt',
    },
    {
      debtToIncome: {
        good: 3,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 1,
        total: 4,
      },
      totalToIncome: {
        good: 1,
        overCMF: 2,
        toImprove: 0,
        toTakeAction: 1,
        total: 4,
      },
      expensesToIncome: {
        good: 4,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 4,
      },
      label: 'Santa Maria',
    },
    {
      debtToIncome: {
        good: 4,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 4,
      },
      totalToIncome: {
        good: 2,
        overCMF: 1,
        toImprove: 0,
        toTakeAction: 1,
        total: 4,
      },
      expensesToIncome: {
        good: 2,
        overCMF: 1,
        toImprove: 0,
        toTakeAction: 1,
        total: 4,
      },
      label: 'Supervisores',
    },
    {
      debtToIncome: {
        good: 6,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 1,
        total: 7,
      },
      totalToIncome: {
        good: 6,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 1,
        total: 7,
      },
      expensesToIncome: {
        good: 6,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 1,
        total: 7,
      },
      label: 'Tobalaba 911',
    },
    {
      debtToIncome: {
        good: 4,
        overCMF: 0,
        toImprove: 1,
        toTakeAction: 0,
        total: 5,
      },
      totalToIncome: {
        good: 4,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 1,
        total: 5,
      },
      expensesToIncome: {
        good: 4,
        overCMF: 0,
        toImprove: 1,
        toTakeAction: 0,
        total: 5,
      },
      label: 'Villa Alemana',
    },
    {
      debtToIncome: {
        good: 7,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 7,
      },
      totalToIncome: {
        good: 6,
        overCMF: 0,
        toImprove: 1,
        toTakeAction: 0,
        total: 7,
      },
      expensesToIncome: {
        good: 6,
        overCMF: 1,
        toImprove: 0,
        toTakeAction: 0,
        total: 7,
      },
      label: 'La Reina',
    },
    {
      debtToIncome: {
        good: 6,
        overCMF: 1,
        toImprove: 0,
        toTakeAction: 0,
        total: 7,
      },
      totalToIncome: {
        good: 5,
        overCMF: 1,
        toImprove: 1,
        toTakeAction: 0,
        total: 7,
      },
      expensesToIncome: {
        good: 7,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 7,
      },
      label: 'Pocuro',
    },
    {
      debtToIncome: {
        good: 4,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 4,
      },
      totalToIncome: {
        good: 4,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 4,
      },
      expensesToIncome: {
        good: 4,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 4,
      },
      label: 'Chicureo',
    },
    {
      debtToIncome: {
        good: 7,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 7,
      },
      totalToIncome: {
        good: 7,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 7,
      },
      expensesToIncome: {
        good: 7,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 7,
      },
      label: 'Colon',
    },
    {
      debtToIncome: {
        good: 4,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 4,
      },
      totalToIncome: {
        good: 4,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 4,
      },
      expensesToIncome: {
        good: 4,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 4,
      },
      label: 'Ejercito',
    },
    {
      debtToIncome: {
        good: 4,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 4,
      },
      totalToIncome: {
        good: 3,
        overCMF: 1,
        toImprove: 0,
        toTakeAction: 0,
        total: 4,
      },
      expensesToIncome: {
        good: 4,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 4,
      },
      label: 'La Calera',
    },
    {
      debtToIncome: {
        good: 5,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 5,
      },
      totalToIncome: {
        good: 5,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 5,
      },
      expensesToIncome: {
        good: 5,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 5,
      },
      label: 'La Florida',
    },
    {
      debtToIncome: {
        good: 5,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 5,
      },
      totalToIncome: {
        good: 5,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 5,
      },
      expensesToIncome: {
        good: 5,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 5,
      },
      label: 'Las Condes',
    },
    {
      debtToIncome: {
        good: 1,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 1,
      },
      totalToIncome: {
        good: 1,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 1,
      },
      expensesToIncome: {
        good: 1,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 1,
      },
      label: 'Liquidos La Picá de la Abuela',
    },
    {
      debtToIncome: {
        good: 1,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 1,
      },
      totalToIncome: {
        good: 1,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 1,
      },
      expensesToIncome: {
        good: 1,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 1,
      },
      label: 'Lo Errazuriz',
    },
    {
      debtToIncome: {
        good: 4,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 4,
      },
      totalToIncome: {
        good: 4,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 4,
      },
      expensesToIncome: {
        good: 4,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 4,
      },
      label: 'Los Leones',
    },
    {
      debtToIncome: {
        good: 4,
        overCMF: 1,
        toImprove: 0,
        toTakeAction: 0,
        total: 5,
      },
      totalToIncome: {
        good: 4,
        overCMF: 1,
        toImprove: 0,
        toTakeAction: 0,
        total: 5,
      },
      expensesToIncome: {
        good: 5,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 5,
      },
      label: 'Macul',
    },
    {
      debtToIncome: {
        good: 4,
        overCMF: 1,
        toImprove: 0,
        toTakeAction: 0,
        total: 5,
      },
      totalToIncome: {
        good: 4,
        overCMF: 1,
        toImprove: 0,
        toTakeAction: 0,
        total: 5,
      },
      expensesToIncome: {
        good: 5,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 5,
      },
      label: 'Maipu Rinconada',
    },
    {
      debtToIncome: {
        good: 4,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 4,
      },
      totalToIncome: {
        good: 4,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 4,
      },
      expensesToIncome: {
        good: 4,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 4,
      },
      label: 'Marketing',
    },
    {
      debtToIncome: {
        good: 4,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 4,
      },
      totalToIncome: {
        good: 4,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 4,
      },
      expensesToIncome: {
        good: 4,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 4,
      },
      label: 'Obispo Orrego',
    },
    {
      debtToIncome: {
        good: 4,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 4,
      },
      totalToIncome: {
        good: 3,
        overCMF: 1,
        toImprove: 0,
        toTakeAction: 0,
        total: 4,
      },
      expensesToIncome: {
        good: 4,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 4,
      },
      label: 'Quilpue',
    },
    {
      debtToIncome: {
        good: 4,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 4,
      },
      totalToIncome: {
        good: 3,
        overCMF: 1,
        toImprove: 0,
        toTakeAction: 0,
        total: 4,
      },
      expensesToIncome: {
        good: 3,
        overCMF: 1,
        toImprove: 0,
        toTakeAction: 0,
        total: 4,
      },
      label: 'RRHH',
    },
    {
      debtToIncome: {
        good: 4,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 4,
      },
      totalToIncome: {
        good: 4,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 4,
      },
      expensesToIncome: {
        good: 4,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 4,
      },
      label: 'Rengo 769',
    },
    {
      debtToIncome: {
        good: 6,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 6,
      },
      totalToIncome: {
        good: 6,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 6,
      },
      expensesToIncome: {
        good: 6,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 6,
      },
      label: 'Vitacura',
    },
    {
      debtToIncome: {
        good: 4,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 4,
      },
      totalToIncome: {
        good: 4,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 4,
      },
      expensesToIncome: {
        good: 4,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 4,
      },
      label: 'Viña 15 Norte',
    },
  ],
  gender: [
    {
      debtToIncome: {
        good: 161,
        overCMF: 12,
        toImprove: 6,
        toTakeAction: 12,
        total: 191,
      },
      totalToIncome: {
        good: 131,
        overCMF: 20,
        toImprove: 10,
        toTakeAction: 30,
        total: 191,
      },
      expensesToIncome: {
        good: 159,
        overCMF: 10,
        toImprove: 5,
        toTakeAction: 17,
        total: 191,
      },
      label: 'M',
    },
    {
      debtToIncome: {
        good: 89,
        overCMF: 8,
        toImprove: 4,
        toTakeAction: 7,
        total: 108,
      },
      totalToIncome: {
        good: 77,
        overCMF: 10,
        toImprove: 5,
        toTakeAction: 16,
        total: 108,
      },
      expensesToIncome: {
        good: 89,
        overCMF: 6,
        toImprove: 1,
        toTakeAction: 12,
        total: 108,
      },
      label: 'F',
    },
  ],
  role: [
    {
      debtToIncome: {
        good: 121,
        overCMF: 9,
        toImprove: 7,
        toTakeAction: 8,
        total: 145,
      },
      totalToIncome: {
        good: 101,
        overCMF: 13,
        toImprove: 7,
        toTakeAction: 24,
        total: 145,
      },
      expensesToIncome: {
        good: 120,
        overCMF: 9,
        toImprove: 3,
        toTakeAction: 13,
        total: 145,
      },
      label: 'Bodeguero/Cajero',
    },
    {
      debtToIncome: {
        good: 10,
        overCMF: 1,
        toImprove: 2,
        toTakeAction: 7,
        total: 20,
      },
      totalToIncome: {
        good: 6,
        overCMF: 2,
        toImprove: 1,
        toTakeAction: 11,
        total: 20,
      },
      expensesToIncome: {
        good: 10,
        overCMF: 1,
        toImprove: 2,
        toTakeAction: 7,
        total: 20,
      },
      label: 'Vendedor Integral',
    },
    {
      debtToIncome: {
        good: 30,
        overCMF: 2,
        toImprove: 0,
        toTakeAction: 2,
        total: 34,
      },
      totalToIncome: {
        good: 25,
        overCMF: 4,
        toImprove: 3,
        toTakeAction: 2,
        total: 34,
      },
      expensesToIncome: {
        good: 31,
        overCMF: 1,
        toImprove: 1,
        toTakeAction: 1,
        total: 34,
      },
      label: 'AYUDANTE JEFE DE LOCAL',
    },
    {
      debtToIncome: {
        good: 10,
        overCMF: 2,
        toImprove: 0,
        toTakeAction: 0,
        total: 12,
      },
      totalToIncome: {
        good: 9,
        overCMF: 1,
        toImprove: 0,
        toTakeAction: 2,
        total: 12,
      },
      expensesToIncome: {
        good: 10,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 2,
        total: 12,
      },
      label: 'Encargado de local',
    },
    {
      debtToIncome: {
        good: 28,
        overCMF: 2,
        toImprove: 0,
        toTakeAction: 0,
        total: 30,
      },
      totalToIncome: {
        good: 25,
        overCMF: 3,
        toImprove: 1,
        toTakeAction: 1,
        total: 30,
      },
      expensesToIncome: {
        good: 28,
        overCMF: 1,
        toImprove: 0,
        toTakeAction: 1,
        total: 30,
      },
      label: 'ENCARGADO DE SUCURSAL',
    },
    {
      debtToIncome: {
        good: 9,
        overCMF: 1,
        toImprove: 0,
        toTakeAction: 0,
        total: 10,
      },
      totalToIncome: {
        good: 6,
        overCMF: 3,
        toImprove: 0,
        toTakeAction: 1,
        total: 10,
      },
      expensesToIncome: {
        good: 8,
        overCMF: 1,
        toImprove: 0,
        toTakeAction: 1,
        total: 10,
      },
      label: 'BODEGUERO/AUX. DE VENTAS',
    },
    {
      debtToIncome: {
        good: 3,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 1,
        total: 4,
      },
      totalToIncome: {
        good: 3,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 1,
        total: 4,
      },
      expensesToIncome: {
        good: 4,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 4,
      },
      label: 'CAJERO/AUX. DE VENTAS',
    },
    {
      debtToIncome: {
        good: 0,
        overCMF: 1,
        toImprove: 0,
        toTakeAction: 0,
        total: 1,
      },
      totalToIncome: {
        good: 0,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 1,
        total: 1,
      },
      expensesToIncome: {
        good: 0,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 1,
        total: 1,
      },
      label: 'Encargado de Inventario y Control de Gestion',
    },
    {
      debtToIncome: {
        good: 0,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 1,
        total: 1,
      },
      totalToIncome: {
        good: 0,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 1,
        total: 1,
      },
      expensesToIncome: {
        good: 0,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 1,
        total: 1,
      },
      label: 'Especialista de Expansón Retail',
    },
    {
      debtToIncome: {
        good: 1,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 1,
      },
      totalToIncome: {
        good: 0,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 1,
        total: 1,
      },
      expensesToIncome: {
        good: 0,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 1,
        total: 1,
      },
      label: 'JEFE DE BODEGA CENTRAL',
    },
    {
      debtToIncome: {
        good: 3,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 3,
      },
      totalToIncome: {
        good: 1,
        overCMF: 1,
        toImprove: 0,
        toTakeAction: 1,
        total: 3,
      },
      expensesToIncome: {
        good: 1,
        overCMF: 1,
        toImprove: 0,
        toTakeAction: 1,
        total: 3,
      },
      label: 'Supervisor de Locales',
    },
    {
      debtToIncome: {
        good: 2,
        overCMF: 1,
        toImprove: 0,
        toTakeAction: 0,
        total: 3,
      },
      totalToIncome: {
        good: 2,
        overCMF: 0,
        toImprove: 1,
        toTakeAction: 0,
        total: 3,
      },
      expensesToIncome: {
        good: 3,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 3,
      },
      label: 'Analista Contable',
    },
    {
      debtToIncome: {
        good: 0,
        overCMF: 0,
        toImprove: 1,
        toTakeAction: 0,
        total: 1,
      },
      totalToIncome: {
        good: 0,
        overCMF: 0,
        toImprove: 1,
        toTakeAction: 0,
        total: 1,
      },
      expensesToIncome: {
        good: 1,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 1,
      },
      label: 'Encargada de Inventario y Control de Gestion',
    },
    {
      debtToIncome: {
        good: 1,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 1,
      },
      totalToIncome: {
        good: 0,
        overCMF: 0,
        toImprove: 1,
        toTakeAction: 0,
        total: 1,
      },
      expensesToIncome: {
        good: 0,
        overCMF: 1,
        toImprove: 0,
        toTakeAction: 0,
        total: 1,
      },
      label: 'JUNIOR ADMINISTRATIVO',
    },
    {
      debtToIncome: {
        good: 3,
        overCMF: 1,
        toImprove: 0,
        toTakeAction: 0,
        total: 4,
      },
      totalToIncome: {
        good: 3,
        overCMF: 1,
        toImprove: 0,
        toTakeAction: 0,
        total: 4,
      },
      expensesToIncome: {
        good: 4,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 4,
      },
      label: 'ADMINISTRADOR DE LOCAL',
    },
    {
      debtToIncome: {
        good: 1,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 1,
      },
      totalToIncome: {
        good: 1,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 1,
      },
      expensesToIncome: {
        good: 1,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 1,
      },
      label: 'ASISTENTE BUSINESS INTELLIGE',
    },
    {
      debtToIncome: {
        good: 1,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 1,
      },
      totalToIncome: {
        good: 0,
        overCMF: 1,
        toImprove: 0,
        toTakeAction: 0,
        total: 1,
      },
      expensesToIncome: {
        good: 0,
        overCMF: 1,
        toImprove: 0,
        toTakeAction: 0,
        total: 1,
      },
      label: 'Alumno en Práctica',
    },
    {
      debtToIncome: {
        good: 1,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 1,
      },
      totalToIncome: {
        good: 1,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 1,
      },
      expensesToIncome: {
        good: 1,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 1,
      },
      label: 'Analista Senior de Abastecimiento',
    },
    {
      debtToIncome: {
        good: 1,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 1,
      },
      totalToIncome: {
        good: 1,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 1,
      },
      expensesToIncome: {
        good: 1,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 1,
      },
      label: 'Analista de Marketing',
    },
    {
      debtToIncome: {
        good: 1,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 1,
      },
      totalToIncome: {
        good: 1,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 1,
      },
      expensesToIncome: {
        good: 1,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 1,
      },
      label: 'Analista de Talento y Cultura',
    },
    {
      debtToIncome: {
        good: 1,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 1,
      },
      totalToIncome: {
        good: 1,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 1,
      },
      expensesToIncome: {
        good: 1,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 1,
      },
      label: 'Category Manager',
    },
    {
      debtToIncome: {
        good: 1,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 1,
      },
      totalToIncome: {
        good: 1,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 1,
      },
      expensesToIncome: {
        good: 1,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 1,
      },
      label: 'Digitador',
    },
    {
      debtToIncome: {
        good: 2,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 2,
      },
      totalToIncome: {
        good: 2,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 2,
      },
      expensesToIncome: {
        good: 2,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 2,
      },
      label: 'Diseñadora de Marketing',
    },
    {
      debtToIncome: {
        good: 1,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 1,
      },
      totalToIncome: {
        good: 1,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 1,
      },
      expensesToIncome: {
        good: 1,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 1,
      },
      label: 'ENCARGADO DE PERSONAL',
    },
    {
      debtToIncome: {
        good: 1,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 1,
      },
      totalToIncome: {
        good: 1,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 1,
      },
      expensesToIncome: {
        good: 1,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 1,
      },
      label: 'Encargado de ventas institucionales',
    },
    {
      debtToIncome: {
        good: 1,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 1,
      },
      totalToIncome: {
        good: 1,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 1,
      },
      expensesToIncome: {
        good: 1,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 1,
      },
      label: 'GERENTE LIQUIDOS STORE',
    },
    {
      debtToIncome: {
        good: 1,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 1,
      },
      totalToIncome: {
        good: 1,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 1,
      },
      expensesToIncome: {
        good: 1,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 1,
      },
      label: 'Gerente Comercial & E-commerce',
    },
    {
      debtToIncome: {
        good: 1,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 1,
      },
      totalToIncome: {
        good: 1,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 1,
      },
      expensesToIncome: {
        good: 1,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 1,
      },
      label: 'JEFA DE ADMINISTRACION Y CONTABILIDAD',
    },
    {
      debtToIncome: {
        good: 1,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 1,
      },
      totalToIncome: {
        good: 1,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 1,
      },
      expensesToIncome: {
        good: 1,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 1,
      },
      label: 'Jefe de Control  y Gestion de Inventarios',
    },
    {
      debtToIncome: {
        good: 1,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 1,
      },
      totalToIncome: {
        good: 1,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 1,
      },
      expensesToIncome: {
        good: 1,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 1,
      },
      label: 'Jefe de Marketing',
    },
    {
      debtToIncome: {
        good: 1,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 1,
      },
      totalToIncome: {
        good: 1,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 1,
      },
      expensesToIncome: {
        good: 1,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 1,
      },
      label: 'Lider de Data Sciense & Business Intelligense',
    },
    {
      debtToIncome: {
        good: 1,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 1,
      },
      totalToIncome: {
        good: 1,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 1,
      },
      expensesToIncome: {
        good: 1,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 1,
      },
      label: 'Lider de Talento y Cultura',
    },
    {
      debtToIncome: {
        good: 1,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 1,
      },
      totalToIncome: {
        good: 1,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 1,
      },
      expensesToIncome: {
        good: 1,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 1,
      },
      label: 'MAESTRO DE MANTENCION',
    },
    {
      debtToIncome: {
        good: 1,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 1,
      },
      totalToIncome: {
        good: 1,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 1,
      },
      expensesToIncome: {
        good: 1,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 1,
      },
      label: 'Product Manager',
    },
    {
      debtToIncome: {
        good: 1,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 1,
      },
      totalToIncome: {
        good: 1,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 1,
      },
      expensesToIncome: {
        good: 1,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 1,
      },
      label: 'Representante de Servicio al Cliente',
    },
    {
      debtToIncome: {
        good: 2,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 2,
      },
      totalToIncome: {
        good: 2,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 2,
      },
      expensesToIncome: {
        good: 2,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 2,
      },
      label: 'SECRETARIA ADMINISTRATIVA',
    },
    {
      debtToIncome: {
        good: 2,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 2,
      },
      totalToIncome: {
        good: 2,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 2,
      },
      expensesToIncome: {
        good: 2,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 2,
      },
      label: 'SUPERVISORA DE LOCALES',
    },
    {
      debtToIncome: {
        good: 1,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 1,
      },
      totalToIncome: {
        good: 0,
        overCMF: 1,
        toImprove: 0,
        toTakeAction: 0,
        total: 1,
      },
      expensesToIncome: {
        good: 1,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 1,
      },
      label: 'Supervisor de Bodega',
    },
    {
      debtToIncome: {
        good: 1,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 1,
      },
      totalToIncome: {
        good: 1,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 1,
      },
      expensesToIncome: {
        good: 1,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 1,
      },
      label: 'Supervisora de Administracion y Finanzas',
    },
    {
      debtToIncome: {
        good: 1,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 1,
      },
      totalToIncome: {
        good: 1,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 1,
      },
      expensesToIncome: {
        good: 1,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 1,
      },
      label: 'TECNICO EN SOPORTE COMPUTACIONAL',
    },
    {
      debtToIncome: {
        good: 2,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 2,
      },
      totalToIncome: {
        good: 2,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 2,
      },
      expensesToIncome: {
        good: 2,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 2,
      },
      label: 'VENDEDOR(A)/PROMOTOR(A)',
    },
  ],
  ageGroups: [
    {
      debtToIncome: {
        good: 73,
        overCMF: 0,
        toImprove: 1,
        toTakeAction: 0,
        total: 74,
      },
      totalToIncome: {
        good: 69,
        overCMF: 2,
        toImprove: 1,
        toTakeAction: 2,
        total: 74,
      },
      expensesToIncome: {
        good: 71,
        overCMF: 1,
        toImprove: 0,
        toTakeAction: 2,
        total: 74,
      },
      label: '(0.0, 25.0]',
    },
    {
      debtToIncome: {
        good: 109,
        overCMF: 9,
        toImprove: 2,
        toTakeAction: 12,
        total: 132,
      },
      totalToIncome: {
        good: 83,
        overCMF: 14,
        toImprove: 8,
        toTakeAction: 27,
        total: 132,
      },
      expensesToIncome: {
        good: 101,
        overCMF: 9,
        toImprove: 4,
        toTakeAction: 18,
        total: 132,
      },
      label: '(25.0, 35.0]',
    },
    {
      debtToIncome: {
        good: 45,
        overCMF: 7,
        toImprove: 5,
        toTakeAction: 6,
        total: 63,
      },
      totalToIncome: {
        good: 35,
        overCMF: 11,
        toImprove: 3,
        toTakeAction: 14,
        total: 63,
      },
      expensesToIncome: {
        good: 49,
        overCMF: 5,
        toImprove: 1,
        toTakeAction: 8,
        total: 63,
      },
      label: '(35.0, 45.0]',
    },
    {
      debtToIncome: {
        good: 16,
        overCMF: 2,
        toImprove: 2,
        toTakeAction: 1,
        total: 21,
      },
      totalToIncome: {
        good: 14,
        overCMF: 1,
        toImprove: 3,
        toTakeAction: 3,
        total: 21,
      },
      expensesToIncome: {
        good: 18,
        overCMF: 1,
        toImprove: 1,
        toTakeAction: 1,
        total: 21,
      },
      label: '(45.0, 55.0]',
    },
    {
      debtToIncome: {
        good: 5,
        overCMF: 2,
        toImprove: 0,
        toTakeAction: 0,
        total: 7,
      },
      totalToIncome: {
        good: 5,
        overCMF: 2,
        toImprove: 0,
        toTakeAction: 0,
        total: 7,
      },
      expensesToIncome: {
        good: 7,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 7,
      },
      label: '(55.0, 65.0]',
    },
    {
      debtToIncome: {
        good: 3,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 3,
      },
      totalToIncome: {
        good: 3,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 3,
      },
      expensesToIncome: {
        good: 3,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 3,
      },
      label: '(65.0, inf]',
    },
  ],
  incomeGroups: [
    {
      debtToIncome: {
        good: 63,
        overCMF: 6,
        toImprove: 4,
        toTakeAction: 8,
        total: 81,
      },
      totalToIncome: {
        good: 47,
        overCMF: 5,
        toImprove: 5,
        toTakeAction: 24,
        total: 81,
      },
      expensesToIncome: {
        good: 53,
        overCMF: 7,
        toImprove: 6,
        toTakeAction: 15,
        total: 81,
      },
      label: '(0.0, 531645.0]',
    },
    {
      debtToIncome: {
        good: 163,
        overCMF: 13,
        toImprove: 6,
        toTakeAction: 7,
        total: 189,
      },
      totalToIncome: {
        good: 142,
        overCMF: 24,
        toImprove: 9,
        toTakeAction: 14,
        total: 189,
      },
      expensesToIncome: {
        good: 174,
        overCMF: 8,
        toImprove: 0,
        toTakeAction: 7,
        total: 189,
      },
      label: '(531645.0, 1063291.0]',
    },
    {
      debtToIncome: {
        good: 7,
        overCMF: 1,
        toImprove: 0,
        toTakeAction: 0,
        total: 8,
      },
      totalToIncome: {
        good: 6,
        overCMF: 0,
        toImprove: 1,
        toTakeAction: 1,
        total: 8,
      },
      expensesToIncome: {
        good: 7,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 1,
        total: 8,
      },
      label: '(1063291.0, 1594936.0]',
    },
    {
      debtToIncome: {
        good: 6,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 6,
      },
      totalToIncome: {
        good: 4,
        overCMF: 1,
        toImprove: 0,
        toTakeAction: 1,
        total: 6,
      },
      expensesToIncome: {
        good: 4,
        overCMF: 1,
        toImprove: 0,
        toTakeAction: 1,
        total: 6,
      },
      label: '(1594936.0, 2126582.0]',
    },
    {
      debtToIncome: {
        good: 7,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 7,
      },
      totalToIncome: {
        good: 7,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 7,
      },
      expensesToIncome: {
        good: 7,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 7,
      },
      label: '(2126582.0, inf]',
    },
  ],
};

export const demoData = {
  company: {
    totalToIncome: {
      good: 100,
      overCMF: 15,
      toImprove: 7,
      toTakeAction: 28,
      total: 150,
    },
    expensesToIncome: {
      good: 124,
      overCMF: 8,
      toImprove: 3,
      toTakeAction: 15,
      total: 150,
    },
    debtToIncome: {
      good: 125,
      overCMF: 10,
      toImprove: 5,
      toTakeAction: 10,
      total: 150,
    },
    canInvest: 40,
    'over15%': 2,
    bancameIndex: 80,
  },
  costCenter: [
    {
      debtToIncome: {
        good: 5,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 2,
        total: 7,
      },
      totalToIncome: {
        good: 4,
        overCMF: 1,
        toImprove: 0,
        toTakeAction: 2,
        total: 7,
      },
      expensesToIncome: {
        good: 6,
        overCMF: 1,
        toImprove: 0,
        toTakeAction: 0,
        total: 7,
      },
      label: 'Centro de costos 1',
    },
    {
      debtToIncome: {
        good: 3,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 3,
      },
      totalToIncome: {
        good: 1,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 2,
        total: 3,
      },
      expensesToIncome: {
        good: 1,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 2,
        total: 3,
      },
      label: 'Centro de costos 2',
    },
    {
      debtToIncome: {
        good: 7,
        overCMF: 1,
        toImprove: 0,
        toTakeAction: 0,
        total: 8,
      },
      totalToIncome: {
        good: 6,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 2,
        total: 8,
      },
      expensesToIncome: {
        good: 6,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 2,
        total: 8,
      },
      label: 'Centro de costos 3',
    },
    {
      debtToIncome: {
        good: 4,
        overCMF: 1,
        toImprove: 2,
        toTakeAction: 0,
        total: 7,
      },
      totalToIncome: {
        good: 4,
        overCMF: 0,
        toImprove: 2,
        toTakeAction: 1,
        total: 7,
      },
      expensesToIncome: {
        good: 6,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 1,
        total: 7,
      },
      label: 'Centro de costos 4',
    },
    {
      debtToIncome: {
        good: 13,
        overCMF: 1,
        toImprove: 0,
        toTakeAction: 0,
        total: 14,
      },
      totalToIncome: {
        good: 9,
        overCMF: 3,
        toImprove: 0,
        toTakeAction: 2,
        total: 14,
      },
      expensesToIncome: {
        good: 12,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 2,
        total: 14,
      },
      label: 'Centro de costos 5',
    },
    {
      debtToIncome: {
        good: 4,
        overCMF: 0,
        toImprove: 1,
        toTakeAction: 0,
        total: 5,
      },
      totalToIncome: {
        good: 3,
        overCMF: 0,
        toImprove: 1,
        toTakeAction: 1,
        total: 5,
      },
      expensesToIncome: {
        good: 4,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 1,
        total: 5,
      },
      label: 'Centro de costos 6',
    },
    {
      debtToIncome: {
        good: 4,
        overCMF: 1,
        toImprove: 0,
        toTakeAction: 1,
        total: 6,
      },
      totalToIncome: {
        good: 3,
        overCMF: 1,
        toImprove: 1,
        toTakeAction: 1,
        total: 6,
      },
      expensesToIncome: {
        good: 6,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 6,
      },
      label: 'Centro de costos 7',
    },
    {
      debtToIncome: {
        good: 4,
        overCMF: 1,
        toImprove: 1,
        toTakeAction: 0,
        total: 6,
      },
      totalToIncome: {
        good: 3,
        overCMF: 1,
        toImprove: 1,
        toTakeAction: 1,
        total: 6,
      },
      expensesToIncome: {
        good: 5,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 1,
        total: 6,
      },
      label: 'Centro de costos 8',
    },
    {
      debtToIncome: {
        good: 4,
        overCMF: 0,
        toImprove: 1,
        toTakeAction: 1,
        total: 6,
      },
      totalToIncome: {
        good: 4,
        overCMF: 0,
        toImprove: 1,
        toTakeAction: 1,
        total: 6,
      },
      expensesToIncome: {
        good: 6,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 6,
      },
      label: 'Centro de costos 9',
    },
    {
      debtToIncome: {
        good: 6,
        overCMF: 2,
        toImprove: 0,
        toTakeAction: 0,
        total: 8,
      },
      totalToIncome: {
        good: 4,
        overCMF: 2,
        toImprove: 1,
        toTakeAction: 1,
        total: 8,
      },
      expensesToIncome: {
        good: 6,
        overCMF: 1,
        toImprove: 1,
        toTakeAction: 0,
        total: 8,
      },
      label: 'Centro de costos 10',
    },
    {
      debtToIncome: {
        good: 5,
        overCMF: 1,
        toImprove: 0,
        toTakeAction: 0,
        total: 6,
      },
      totalToIncome: {
        good: 5,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 1,
        total: 6,
      },
      expensesToIncome: {
        good: 5,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 1,
        total: 6,
      },
      label: 'Centro de costos 11',
    },
  ],
  gender: [
    {
      debtToIncome: {
        good: 80,
        overCMF: 6,
        toImprove: 3,
        toTakeAction: 11,
        total: 100,
      },
      totalToIncome: {
        good: 65,
        overCMF: 10,
        toImprove: 5,
        toTakeAction: 15,
        total: 100,
      },
      expensesToIncome: {
        good: 82,
        overCMF: 6,
        toImprove: 3,
        toTakeAction: 9,
        total: 100,
      },
      label: 'M',
    },
    {
      debtToIncome: {
        good: 41,
        overCMF: 4,
        toImprove: 2,
        toTakeAction: 3,
        total: 50,
      },
      totalToIncome: {
        good: 32,
        overCMF: 5,
        toImprove: 3,
        toTakeAction: 8,
        total: 50,
      },
      expensesToIncome: {
        good: 40,
        overCMF: 3,
        toImprove: 1,
        toTakeAction: 6,
        total: 50,
      },
      label: 'F',
    },
  ],
  role: [
    {
      debtToIncome: {
        good: 30,
        overCMF: 2,
        toImprove: 0,
        toTakeAction: 2,
        total: 34,
      },
      totalToIncome: {
        good: 25,
        overCMF: 4,
        toImprove: 3,
        toTakeAction: 2,
        total: 34,
      },
      expensesToIncome: {
        good: 31,
        overCMF: 1,
        toImprove: 1,
        toTakeAction: 1,
        total: 34,
      },
      label: 'Cargo 1',
    },
    {
      debtToIncome: {
        good: 28,
        overCMF: 2,
        toImprove: 0,
        toTakeAction: 0,
        total: 30,
      },
      totalToIncome: {
        good: 25,
        overCMF: 3,
        toImprove: 1,
        toTakeAction: 1,
        total: 30,
      },
      expensesToIncome: {
        good: 28,
        overCMF: 1,
        toImprove: 0,
        toTakeAction: 1,
        total: 30,
      },
      label: 'Cargo 2',
    },
    {
      debtToIncome: {
        good: 3,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 1,
        total: 4,
      },
      totalToIncome: {
        good: 3,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 1,
        total: 4,
      },
      expensesToIncome: {
        good: 4,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 4,
      },
      label: 'Cargo 3',
    },
    {
      debtToIncome: {
        good: 0,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 1,
        total: 1,
      },
      totalToIncome: {
        good: 0,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 1,
        total: 1,
      },
      expensesToIncome: {
        good: 0,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 1,
        total: 1,
      },
      label: 'Cargo 4',
    },
    {
      debtToIncome: {
        good: 1,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 1,
      },
      totalToIncome: {
        good: 0,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 1,
        total: 1,
      },
      expensesToIncome: {
        good: 0,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 1,
        total: 1,
      },
      label: 'Cargo 5',
    },
    {
      debtToIncome: {
        good: 3,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 3,
      },
      totalToIncome: {
        good: 1,
        overCMF: 1,
        toImprove: 0,
        toTakeAction: 1,
        total: 3,
      },
      expensesToIncome: {
        good: 1,
        overCMF: 1,
        toImprove: 0,
        toTakeAction: 1,
        total: 3,
      },
      label: 'Cargo 6',
    },
    {
      debtToIncome: {
        good: 2,
        overCMF: 1,
        toImprove: 0,
        toTakeAction: 0,
        total: 3,
      },
      totalToIncome: {
        good: 2,
        overCMF: 0,
        toImprove: 1,
        toTakeAction: 0,
        total: 3,
      },
      expensesToIncome: {
        good: 3,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 3,
      },
      label: 'Cargo 7',
    },
    {
      debtToIncome: {
        good: 0,
        overCMF: 0,
        toImprove: 1,
        toTakeAction: 0,
        total: 1,
      },
      totalToIncome: {
        good: 0,
        overCMF: 0,
        toImprove: 1,
        toTakeAction: 0,
        total: 1,
      },
      expensesToIncome: {
        good: 1,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 1,
      },
      label: 'Cargo 8',
    },
    {
      debtToIncome: {
        good: 1,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 1,
      },
      totalToIncome: {
        good: 0,
        overCMF: 0,
        toImprove: 1,
        toTakeAction: 0,
        total: 1,
      },
      expensesToIncome: {
        good: 0,
        overCMF: 1,
        toImprove: 0,
        toTakeAction: 0,
        total: 1,
      },
      label: 'Cargo 9',
    },
    {
      debtToIncome: {
        good: 3,
        overCMF: 1,
        toImprove: 0,
        toTakeAction: 0,
        total: 4,
      },
      totalToIncome: {
        good: 3,
        overCMF: 1,
        toImprove: 0,
        toTakeAction: 0,
        total: 4,
      },
      expensesToIncome: {
        good: 4,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 4,
      },
      label: 'Cargo 10',
    },
    {
      debtToIncome: {
        good: 1,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 1,
      },
      totalToIncome: {
        good: 1,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 1,
      },
      expensesToIncome: {
        good: 1,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 1,
      },
      label: 'Cargo 11',
    },
    {
      debtToIncome: {
        good: 1,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 1,
      },
      totalToIncome: {
        good: 0,
        overCMF: 1,
        toImprove: 0,
        toTakeAction: 0,
        total: 1,
      },
      expensesToIncome: {
        good: 0,
        overCMF: 1,
        toImprove: 0,
        toTakeAction: 0,
        total: 1,
      },
      label: 'Cargo 12',
    },
    {
      debtToIncome: {
        good: 1,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 1,
      },
      totalToIncome: {
        good: 1,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 1,
      },
      expensesToIncome: {
        good: 1,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 1,
      },
      label: 'Cargo 13',
    },
    {
      debtToIncome: {
        good: 1,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 1,
      },
      totalToIncome: {
        good: 1,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 1,
      },
      expensesToIncome: {
        good: 1,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 1,
      },
      label: 'Cargo 14',
    },
    {
      debtToIncome: {
        good: 1,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 1,
      },
      totalToIncome: {
        good: 1,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 1,
      },
      expensesToIncome: {
        good: 1,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 1,
      },
      label: 'Cargo 15',
    },
  ],
  ageGroups: [
    {
      debtToIncome: {
        good: 36,
        overCMF: 0,
        toImprove: 1,
        toTakeAction: 0,
        total: 37,
      },
      totalToIncome: {
        good: 32,
        overCMF: 2,
        toImprove: 1,
        toTakeAction: 2,
        total: 37,
      },
      expensesToIncome: {
        good: 34,
        overCMF: 1,
        toImprove: 0,
        toTakeAction: 2,
        total: 37,
      },
      label: '(0.0, 25.0]',
    },
    {
      debtToIncome: {
        good: 54,
        overCMF: 5,
        toImprove: 1,
        toTakeAction: 6,
        total: 66,
      },
      totalToIncome: {
        good: 42,
        overCMF: 7,
        toImprove: 4,
        toTakeAction: 13,
        total: 66,
      },
      expensesToIncome: {
        good: 51,
        overCMF: 5,
        toImprove: 2,
        toTakeAction: 8,
        total: 66,
      },
      label: '(25.0, 35.0]',
    },
    {
      debtToIncome: {
        good: 21,
        overCMF: 4,
        toImprove: 2,
        toTakeAction: 3,
        total: 30,
      },
      totalToIncome: {
        good: 16,
        overCMF: 6,
        toImprove: 1,
        toTakeAction: 7,
        total: 30,
      },
      expensesToIncome: {
        good: 23,
        overCMF: 2,
        toImprove: 1,
        toTakeAction: 4,
        total: 30,
      },
      label: '(35.0, 45.0]',
    },
    {
      debtToIncome: {
        good: 5,
        overCMF: 2,
        toImprove: 2,
        toTakeAction: 1,
        total: 10,
      },
      totalToIncome: {
        good: 3,
        overCMF: 1,
        toImprove: 3,
        toTakeAction: 3,
        total: 10,
      },
      expensesToIncome: {
        good: 6,
        overCMF: 1,
        toImprove: 1,
        toTakeAction: 2,
        total: 10,
      },
      label: '(45.0, 55.0]',
    },
    {
      debtToIncome: {
        good: 3,
        overCMF: 2,
        toImprove: 0,
        toTakeAction: 0,
        total: 5,
      },
      totalToIncome: {
        good: 3,
        overCMF: 2,
        toImprove: 0,
        toTakeAction: 0,
        total: 5,
      },
      expensesToIncome: {
        good: 5,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 5,
      },
      label: '(55.0, 65.0]',
    },
    {
      debtToIncome: {
        good: 2,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 2,
      },
      totalToIncome: {
        good: 2,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 2,
      },
      expensesToIncome: {
        good: 2,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 2,
      },
      label: '(65.0, inf]',
    },
  ],
  incomeGroups: [
    {
      debtToIncome: {
        good: 31,
        overCMF: 3,
        toImprove: 2,
        toTakeAction: 4,
        total: 40,
      },
      totalToIncome: {
        good: 23,
        overCMF: 2,
        toImprove: 3,
        toTakeAction: 12,
        total: 40,
      },
      expensesToIncome: {
        good: 26,
        overCMF: 4,
        toImprove: 3,
        toTakeAction: 7,
        total: 40,
      },
      label: '(0.0, 531645.0]',
    },
    {
      debtToIncome: {
        good: 77,
        overCMF: 6,
        toImprove: 3,
        toTakeAction: 4,
        total: 90,
      },
      totalToIncome: {
        good: 66,
        overCMF: 12,
        toImprove: 5,
        toTakeAction: 7,
        total: 90,
      },
      expensesToIncome: {
        good: 83,
        overCMF: 4,
        toImprove: 0,
        toTakeAction: 3,
        total: 90,
      },
      label: '(531645.0, 1063291.0]',
    },
    {
      debtToIncome: {
        good: 7,
        overCMF: 1,
        toImprove: 0,
        toTakeAction: 0,
        total: 8,
      },
      totalToIncome: {
        good: 6,
        overCMF: 0,
        toImprove: 1,
        toTakeAction: 1,
        total: 8,
      },
      expensesToIncome: {
        good: 7,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 1,
        total: 8,
      },
      label: '(1063291.0, 1594936.0]',
    },
    {
      debtToIncome: {
        good: 6,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 6,
      },
      totalToIncome: {
        good: 4,
        overCMF: 1,
        toImprove: 0,
        toTakeAction: 1,
        total: 6,
      },
      expensesToIncome: {
        good: 4,
        overCMF: 1,
        toImprove: 0,
        toTakeAction: 1,
        total: 6,
      },
      label: '(1594936.0, 2126582.0]',
    },
    {
      debtToIncome: {
        good: 6,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 6,
      },
      totalToIncome: {
        good: 6,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 6,
      },
      expensesToIncome: {
        good: 6,
        overCMF: 0,
        toImprove: 0,
        toTakeAction: 0,
        total: 6,
      },
      label: '(2126582.0, inf]',
    },
  ],
};

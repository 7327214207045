import React from 'react';
import StatsPieChart from '../../../components/StatsPieChart';
import logo from '../../../assets/bancame_logo_sans.svg';
import { GroupInterface } from '../../../utils/interfaces/company-report';

interface ChartDataInterface {
  key: number,
  title: string,
  data: {name: string, value: number }[],
}
interface CRSegmentationDetailStatsInterface{
  data: GroupInterface
}

function CompanyReportSegmentationDetailStats(
  props: CRSegmentationDetailStatsInterface,
) {
  const { data } = props;
  let chartsData0:ChartDataInterface[] = [];
  let chartsData1:ChartDataInterface[] = [];
  // let chartsData_2 = [];
  let totalWorkers = 0;
  if (data) {
    totalWorkers = data.totalToIncome.total;
    const egresosVsCMF = [
      { name: 'totalToIncome', value: +data.totalToIncome.overCMF },
      { name: 'rest', value: +totalWorkers - data.totalToIncome.overCMF },
    ];
    const egresosAtencion = [
      { name: 'totalToIncome', value: +data.totalToIncome.toImprove },
      { name: 'rest', value: +totalWorkers - data.expensesToIncome.toImprove },
    ];
    const egresosCriticos = [
      { name: 'totalToIncome', value: +data.totalToIncome.toTakeAction },
      { name: 'rest', value: +totalWorkers - data.totalToIncome.toTakeAction },
    ];

    const deudasVsCMF = [
      { name: 'debtToIncome', value: +data.debtToIncome.overCMF },
      { name: 'rest', value: +totalWorkers - data.debtToIncome.overCMF },
    ];
    const deudasAtencion = [
      { name: 'debtToIncome', value: +data.debtToIncome.toImprove },
      { name: 'rest', value: +totalWorkers - data.debtToIncome.toImprove },
    ];
    const deudasCriticas = [
      { name: 'debtToIncome', value: +data.debtToIncome.toTakeAction },
      { name: 'rest', value: +totalWorkers - data.debtToIncome.toTakeAction },
    ];
    chartsData0 = [
      {
        key: 0,
        title: 'Dentro del promedio Chileno',
        data: egresosVsCMF,
      },
      {
        key: 1,
        title: 'Requieren atención',
        data: egresosAtencion,
      },
      {
        key: 2,
        title: 'En estado crítico',
        data: egresosCriticos,
      },
    ];
    chartsData1 = [
      {
        key: 3,
        title: 'Dentro del promedio Chileno',
        data: deudasVsCMF,
      },
      {
        key: 4,
        title: 'Requieren atención',
        data: deudasAtencion,
      },
      {
        key: 5,
        title: 'En Estado crítico',
        data: deudasCriticas,
      },
    ];
  }
  return (
    <>
      <div style={{ textAlign: 'left', fontSize: '18px', marginTop: 15 }}>
        <span style={{ fontWeight: 'bold' }}>Total muestra:</span>
        {' '}
        {totalWorkers > 0 ? `${totalWorkers} colaboradores` : 'Sin datos'}

      </div>
      <div className="detail_content">
        <div style={{ textAlign: 'left', fontWeight: 'bold', margin: 5 }}>
          Nivel de deudas
          <div className="tooltip" style={{ cursor: 'default' }}>
            <span style={{ marginLeft: '10px' }}>
              <span className="tooltiptext-right">Banca.Me incide directamente en la reducción de este KPI</span>
              <object data={logo} width="22px" height="18px"> </object>
            </span>

          </div>
        </div>

        <hr style={{ maxWidth: 180, margin: 7 }} />
        <div className="detail_content_charts deudas">
          {chartsData1.map((chartData) => (
            <div key={chartData.key}>
              <div style={{ fontSize: 'medium' }}>{chartData.title}</div>
              <StatsPieChart chartData={chartData.data} size="small" />
              <p>
                {chartData.data[0].value}
                {' '}
                persona
                {chartData.data[0].value === 1 ? '' : 's'}
              </p>
            </div>
          ))}

        </div>
        <div style={{ textAlign: 'left', fontWeight: 'bold', margin: 5 }}>Nivel de gastos totales</div>
        <hr style={{ maxWidth: 180, margin: 7 }} />
        <div className="detail_content_charts egresos">
          {chartsData0.map((chartData) => (
            <div key={chartData.key}>
              <div style={{ fontSize: 'medium' }}>{chartData.title}</div>
              <StatsPieChart chartData={chartData.data} size="small" />
              <p>
                {chartData.data[0].value}
                {' '}
                persona
                {chartData.data[0].value === 1 ? '' : 's'}
              </p>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default CompanyReportSegmentationDetailStats;

import React from 'react';
import { useHistory } from 'react-router-dom';
import logo from '../../../../assets/bancame_logo.svg';
import carCredit from './credit.png';
import Header from '../../header';
import {
  handleVisibleTab,
} from '../../helpers';
import HubSpot from '../../../../components/HubspotForm';

function CarCredit() {
  const history = useHistory();
  React.useEffect(() => {
    document.body.style.backgroundColor = '#E5E5E5';
  }, []);
  const [isInterested, setIsInterested] = React.useState(false);
  const handleIsInterested = () => setIsInterested((o) => !o);
  const handleSubmit = () => setTimeout(() => setIsInterested(false), 2000);

  return (
    <Header type="workers">
      <div className="cards_container">

        <div className="card card_detail">
          <div
            className="card_image"
            style={{
              backgroundImage: `url(${carCredit})`,

            }}
          >
            {' '}
            <div style={{ margin: 'auto' }}>
              <span style={{ fontSize: '80px' }}>&#128663;</span>
              <span style={{ fontSize: '65px' }}>🏍️</span>
            </div>

          </div>
          <div className="container">

            <p>¡Te financiamos tu auto o moto a mejores tasas!</p>
            <object data={logo} width="30px" height="30px"> </object>
          </div>
        </div>
        <div>
          <div
            aria-hidden
            onClick={() => handleVisibleTab('workers', history)}
            style={{
              fontSize: '15px',
              marginBottom: '10px',
              cursor: 'pointer',
              color: '#35aac5',
              textAlign: 'left',
            }}
          >
            <span style={{ fontSize: '20px', marginRight: '5px' }}>&#x2039;</span>
            Atrás

          </div>
          <div style={{ textAlign: 'left', height: '400px', overflowY: 'auto' }}>
            {isInterested

              && HubSpot(
                process.env.REACT_APP_PORTAL_ID,
                process.env.REACT_APP_CAR_CREDIT_FORM_ID,
                handleSubmit,
              )}
            {!isInterested && (
            <div>
              <h2>Compra tu vehículo con Banca.Me</h2>
              <p>
                Porque sabemos lo que necesitas,
                te ayudamos a financiar tu auto o moto con nuestros créditos preferenciales

              </p>
              <h5>Requisitos</h5>
              <ol style={{ fontSize: '18px' }}>
                <li>Pertenecer a empresa en convenio con Banca.Me</li>
                <li>Aplicar a un crédito según nuestra política de riesgo</li>
                <li>Pie mínimo de 15%</li>
              </ol>

            </div>
            )}
          </div>
          {!isInterested
            && (
            <h5
              aria-hidden
              onClick={handleIsInterested}
              className="interestedFooter"
            >
              Estoy interesado
            </h5>
            )}
        </div>

      </div>

    </Header>
  );
}

export default CarCredit;

const dateIndexes = [
  'createdAt',
  'updatedAt',
  'paidAt',
  'transferDate',
  'expirationDate',
  'dateIssued',
  'lastContactDate',
  'validUntil',
];
const moneyIndexes = [
  'amount',
  'loanAmount',
  'bruteAmount',
  'installmentAmount',
  'lastInstallmentAmount',
  'principalBalance',
  'principal',
  'interest',
  'totalCost',
  'originCost',
  'insurance',
  'notary',
  'taxes',
  'totalAmount',
  'principalBalance',
  'principal',
  'interest',
  'maxAmount',
  'prepayPrincipal',
  'interestAmount',
  'cashOut',
  'prepayCommission',
  'installment',
  'subscription',
  'delinquency',
  'totalPrizeAmount',
];
const percentageIndexes = [
  'interestRate',
  'monthlyCAE',
  'CAE',
  'rate',
];
const periodsIndexes = [
  'periods',
  'maxPeriods',
];
const preLoanStepsLabels = {
  BASIC_INFO: 'Información básica',
  LOAN_SIMULATION: 'Esperando simulación',
  UPLOAD_DOCUMENTS: 'Esperando subida de archivos',
  COMPANY_CONTACT_INFO: 'Esperando información empleador',
  WAITING_COMPANY_AFFILIATION: 'Esperando afiliación empleador',
  AGENT_CONTACT: 'Contacto con agente',
  READY_FOR_LOAN: 'Listo para evaluación',
  ARCHIVED_SUCCESS: 'Lead exitoso',
  ARCHIVED_FAIL: 'Lead rechazado',
};
const adminRolesLabel = {
  admin: 'Admin',
  superAdmin: 'Admin supremo',
  agent: 'Agente',
  accountant: 'Externo',
  notAdmin: '-',
};
const memberRolesLabel = {
  lead: 'Lead',
  memberClient: 'Inversionista & Empresa afiliada',
  client: 'Empresa Afiliada',
  member: 'Inversionista',
};
const genderLabels = {
  M: 'Masculino',
  F: 'Femenino',
};
const creditStateLabels = {
  ACTIVE: 'ACTIVA',
  HARD_DEBT: 'MOROSA DURA',
  INTERMEDIATE_DEBT: 'MOROSA INTERMEDIA',
  SOFT_DEBT: 'MOROSA BLANDA',
  PAID: 'PAGADA',
  REFINANCED: 'REFINANCIADA',
  PREPAID: 'PREPAGADA',
  PARTIAL_PREPAID: 'PREPAGADA PARCIALMENTE',
  CANCELED: 'CANCELADA',
  OUTSOURCED: 'EXTERNALIZADA',
  PUNISHED: 'CASTIGADA',
  RECOVERED: 'RECUPERADA',
};
const economicActivityLabels = {
  FINANCIAL_INSTITUTION: 'Institución Financiera',
  CONSTRUCTION: 'Construcción',
  FINANCIAL_CONSULTANT: 'Consultoría Financiera',
  TRANSPORTATION: 'Transporte',
  MANAGEMENT_SERVICE: 'Servicios de Gestión',
  AGRICULTURE: 'Agricultura',
  COMMERCE: 'Comercio',
  PRIVATE_AND_DOMESTIC_SERVICES: 'Servicios domésticos y privados',
  INDUSTRY: 'Industria',
  EDUCATION_SERVICES: 'Servicios Educacionales',
  INVESTMENTS: 'Inversiones',
  MINING: 'Minería',
  ELECTRICITY: 'Electricidad',
};
const prepayLabels = {
  fullPrepay: 'Prepago total',
  cashOut: 'Refinanciamiento c/ aumento',
  refinanceRP: 'Refinanciamiento período-tasa',
  simulated: 'Solicitud de Admin',
  userRequest: 'Solicitud de Usuario',
};
const debtTypeLabels = {
  installment: 'Cuota de crédito',
  subscription: 'Cuota de participación',
  delinquency: 'Morosidad',
};
const invoiceTypeLabels = {
  company: 'Empresa',
  user: 'Usuario',
};

function stringFormat(obj, key) {
  if (key === 'deniedClaveUnica') {
    if (obj[key]) {
      return 'PLR 1.0';
    } return 'PLR 2.0';
  }
  if (dateIndexes.includes(key)) {
    return obj[key] ? new Date(Date.parse(obj[key])).toLocaleDateString('es-ES', { timeZone: 'America/Santiago' }) : '(Sin fecha)';
  }
  if (moneyIndexes.includes(key)) {
    return `$${(obj[key] ? obj[key] : 0).toLocaleString()}`;
  }
  if (percentageIndexes.includes(key)) {
    return (obj[key] ? obj[key] : 0).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 3 });
  }
  if (periodsIndexes.includes(key)) {
    return `${(obj[key] ? obj[key] : 0).toLocaleString()} meses`;
  }
  if (key === 'step') {
    return preLoanStepsLabels[obj[key]];
  }
  if (obj[key] === true || obj[key] === false) {
    return obj[key] ? 'Sí' : 'No';
  }
  if (key === 'gender') {
    return genderLabels[obj[key]] || null;
  }
  if (key === 'adminRole') {
    return adminRolesLabel[obj[key]];
  }
  if (key === 'memberRole') {
    return memberRolesLabel[obj[key]];
  }
  if (key === 'state') {
    if (Object.keys(creditStateLabels).includes(obj[key])) {
      return creditStateLabels[obj[key]];
    }
  }
  if (key === 'economicActivity') {
    return economicActivityLabels[obj[key]] || null;
  }
  if (Array.isArray(obj[key])) {
    return obj[key].length;
  }
  if (key === 'status') {
    if (Object.keys(prepayLabels).includes(obj[key])) {
      return prepayLabels[obj[key]];
    }
  }
  if (key === 'type') {
    if (Object.keys(prepayLabels).includes(obj[key])) {
      return prepayLabels[obj[key]];
    }
    if (Object.keys(invoiceTypeLabels).includes(obj[key])) {
      return invoiceTypeLabels[obj[key]];
    }
  }
  if (key === 'debtType') {
    if (Object.keys(debtTypeLabels).includes(obj[key])) {
      return debtTypeLabels[obj[key]];
    }
  }
  return obj[key];
}

export default stringFormat;

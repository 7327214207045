import React from 'react';
import clsx from 'clsx';
import {
  Autocomplete,
  Chip,
  IconButton,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

import useStyles from './styles';

type EnhancedTableToolbarProps = {
    numSelected: number;
    title: string;
    defaultFilters: { label: string, value: string }[];
    handleFilters: (filtersArray: any[]) => void;
    isSelect: boolean;
    onEditClick: () => void;
}

function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
  const {
    numSelected,
    title,
    defaultFilters,
    handleFilters,
    isSelect,
    onEditClick,
  } = props;

  const classes = useStyles();
  const [showInput, setShowInput] = React.useState(false);

  const handleSetFilters = (filtersArr) => {
    const filterValues = filtersArr.map((filter) => filter.value || filter);
    handleFilters(filterValues);
  };

  const resetFilter = () => {
    setShowInput(false);
  };

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: (numSelected > 0 && isSelect),
      })}
    >
      {(numSelected > 0 && isSelect) ? (
        <Typography
          className={classes.title}
          color="white"
          variant="subtitle1"
          component="div"
          fontWeight="bold"
        >
          {numSelected}
          {' '}
          seleccionados
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant="h5"
          id="tableTitle"
          component="div"
          fontWeight="bold"
        >
          {title}
        </Typography>
      )}

      {(numSelected > 0 && isSelect) ? (
        <Tooltip title="Editar estado">
          <IconButton
            aria-label="Editar estado"
            onClick={onEditClick}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <div>
          {showInput ? (
            <div style={{ display: 'flex' }}>
              <Autocomplete
                multiple
                fullWidth
                id="tags-filled"
                options={defaultFilters}
                getOptionLabel={(option) => option.label}
                limitTags={4}
                freeSolo
                renderTags={(value, getTagProps) => value.map((option, index) => (
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  <Chip variant="outlined" label={option.label || option} {...getTagProps({ index })} />
                ))}
                onChange={(e, value) => handleSetFilters(value)}
                renderInput={(params) => (
                  <TextField
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...params}
                    variant="outlined"
                    placeholder="Escriba uno o más filtros"
                    style={{ width: 300 }}
                  />
                )}
              />
              <Tooltip title="Cerrar busqueda">
                <IconButton
                  aria-label="filter list"
                  onClick={resetFilter}
                >
                  <ClearIcon />
                </IconButton>
              </Tooltip>
            </div>
          ) : (
            <Tooltip title="Filtrar lista">
              <IconButton
                aria-label="filter list"
                onClick={() => { setShowInput(true); }}
              >
                <SearchIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
      )}
    </Toolbar>
  );
}

export default EnhancedTableToolbar;

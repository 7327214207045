import React from 'react';
import body1 from '../../../assets/company-report/02_concepts/body_1.svg';
import logo from '../../../assets/bancame_logo.svg';
import logo1 from '../../../assets/bancame_logo_sans.svg';
import './styles.css';

function CompanyReportConcepts() {
  React.useEffect(() => {
    document.body.style.backgroundColor = '#DFE3EC';
    document.body.style.transition = '2s';
  }, []);
  const informeCMF = 'https://www.cmfchile.cl/portal/estadisticas/617/articles-46785_recurso_2.pdf';
  const informeWellsFargo = 'https://www.wellsfargo.com/es/goals-credit/smarter-credit/credit-101/debt-to-income-ratio/understanding-dti/';

  return (
    <div>
      <img src={body1} alt="firstBackground" className="first_background" />

      <div className="first_body">
        <div className="first_header">
          <object data={logo} width="70px" height="70px"> </object>
          <div>
            <h2 className="intro_title_2">CONCEPTOS</h2>
            <h4 className="intro_subtitle_2">
              Sobre los coeficientes y símbolos
              utilizados en este reporte de Bienestar Financiero
              {' '}

            </h4>
          </div>
        </div>
        <div className="intro_one">
          <div className="intro_one_text">
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 8fr' }}>
              <div style={{ alignSelf: 'center' }}><i className="material-icons">insert_chart</i></div>
              <h3>Índices</h3>
            </div>
            <hr style={{ margin: 0 }} />
            <div>

              <h4 style={{ margin: '15px 0px' }}>
                Deudas
                <div className="tooltip" style={{ cursor: 'default' }}>
                  <span style={{ marginLeft: '10px' }}>
                    <span className="tooltiptext-right">Banca.Me incide directamente en la reducción de este KPI</span>
                    <object data={logo1} width="18px" height="15px"> </object>
                  </span>
                </div>

              </h4>
              <p className="intro_two_description">
                <span style={{ fontWeight: 600 }}>
                  Compromisos de pago obligatorios de
                  tus colaboradores

                </span>
                {' '}

                con entidades financieras, casas comerciales y entre otros,
                Banca.Me puede tener una incidencia directa en su reducción.
                {' '}

              </p>
            </div>
            <h4 style={{ margin: '15px 0px' }}>Gastos totales</h4>
            <p className="intro_two_description">
              Es la
              {' '}
              <span style={{ fontWeight: 600 }}>suma total de Gastos y Deudas</span>
              {' '}
              encontradas
              por los algoritmos de Banca.Me.

            </p>

          </div>
          <div className="intro_one_text">
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 8fr' }}>
              <div style={{ alignSelf: 'center' }}><i className="material-icons">pie_chart_outlined</i></div>
              <h3> Coeficientes</h3>
            </div>
            <hr style={{ margin: 0 }} />
            <div style={{ display: 'grid', gridTemplateColumns: '2fr 1fr' }}>
              <h4 style={{ margin: '10px 0px' }}>Sobre el promedio chileno</h4>
              <div
                aria-hidden
                onClick={() => window.open(informeCMF)}
                style={{ alignSelf: 'center', cursor: 'pointer' }}
              >
                <i style={{ fontSize: '20px' }} className="material-icons">launch</i>

              </div>
            </div>
            <p className="intro_two_description">
              La relación Índice/Ingreso bruto es
              {' '}
              <span style={{ fontWeight: 600 }}>
                mayor
                al porcentaje de endeudamiento promedio
              </span>
              {' '}
              con cooperativas indicado
              por la CMF indicado el año 2020, que es de un
              {' '}
              <span style={{ fontWeight: 600 }}>16%.</span>
              {' '}
              Es decir,
              {' '}
              <span style={{ fontWeight: 600 }}>
                la persona está más del 16% de su sueldo
                {' '}
              </span>
              para cubrir este item.
            </p>
            <div>
              <div style={{ display: 'grid', gridTemplateColumns: '2fr 1fr' }}>
                <h4 style={{ margin: '5px 0px' }}>Requiere atención</h4>
                <div
                  aria-hidden
                  onClick={() => window.open(informeWellsFargo)}
                  style={{ alignSelf: 'center', cursor: 'pointer' }}
                >
                  <i style={{ fontSize: '20px' }} className="material-icons">launch</i>
                </div>
              </div>
              <p className="intro_two_description">
                La relación Índice/Ingreso bruto es
                {' '}
                <span style={{ fontWeight: 600 }}>
                  mayor
                  al 33%
                </span>
                , adaptado del estudio de bienestar financiero de Wells Fargo.
                {' '}
                Es decir,
                {' '}
                <span style={{ fontWeight: 600 }}>
                  la persona utiliza más del 33% de su sueldo
                </span>
                {' '}
                para cubrir este item.
              </p>
            </div>
            <div>
              <div style={{ display: 'grid', gridTemplateColumns: '2fr 1fr' }}>
                <h4 style={{ margin: '5px 0px' }}>Estado crítico</h4>
                <div
                  aria-hidden
                  onClick={() => window.open(informeWellsFargo)}
                  style={{ alignSelf: 'center', cursor: 'pointer' }}
                >
                  <i style={{ fontSize: '20px' }} className="material-icons">launch</i>
                </div>
              </div>
              <p className="intro_two_description" style={{ marginBottom: 100 }}>
                La relación Índice/Ingreso bruto es
                {' '}
                <span style={{ fontWeight: 600 }}>
                  mayor
                  al 47%
                </span>
                , adaptado del estudio de bienestar financiero de Wells Fargo.
                {' '}
                Es decir,
                {' '}
                <span style={{ fontWeight: 600 }}>
                  la persona utiliza más del 47% de su sueldo

                </span>
                {' '}
                para cubrir este item.
              </p>
            </div>
          </div>

        </div>
      </div>
    </div>

  );
}

export default CompanyReportConcepts;

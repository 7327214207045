import CompanyReportBancameIndex from '../../../pages/company-report/01_bancame-index/index';
import CompanyReportConcepts from '../../../pages/company-report/02_concepts';
import CompanyReportActionPlan from '../../../pages/company-report/03_action-plan';
import CompanyReportOverAverage from '../../../pages/company-report/04_over-average';
import CompanyReportNeedAttention from '../../../pages/company-report/05_need-attention';
import CompanyReportCritical from '../../../pages/company-report/06_critical';
import CompanyReportSegmentation from '../../../pages/company-report/07_segmentation';
import CompanyReportGoodToKnow from '../../../pages/company-report/08_good-to-know';
import CompanyReportContactUs from '../../../pages/company-report/09_contact-us';

const CrRoutes = [
  {
    path: 'bancame-index',
    component: CompanyReportBancameIndex,
    exact: true,
  },
  {
    path: 'concepts',
    component: CompanyReportConcepts,
    exact: true,
  },
  {
    path: 'action-plan',
    component: CompanyReportActionPlan,
    exact: true,
  },
  {
    path: 'over-average',
    component: CompanyReportOverAverage,
    exact: true,
  },
  {
    path: 'need-attention',
    component: CompanyReportNeedAttention,
    exact: true,
  },
  {
    path: 'critical',
    component: CompanyReportCritical,
    exact: true,
  },
  {
    path: 'segmentation',
    component: CompanyReportSegmentation,
    exact: true,
  },
  {
    path: 'good-to-know',
    component: CompanyReportGoodToKnow,
    exact: true,
  },
  {
    path: 'contact-us',
    component: CompanyReportContactUs,
    exact: true,
  },
];

export default CrRoutes;

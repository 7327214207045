import React from 'react';
import { useHistory } from 'react-router-dom';
import logo from '../../../../assets/bancame_logo.svg';
import downPayment from './downpayment.png';
import Header from '../../header';
import { handleVisibleTab } from '../../helpers';
import HubSpot from '../../../../components/HubspotForm';

function DownPayment() {
  const history = useHistory();
  React.useEffect(() => {
    document.body.style.backgroundColor = '#E5E5E5';
  }, []);
  const [isInterested, setIsInterested] = React.useState(false);
  const handleIsInterested = () => setIsInterested((o) => !o);
  const handleSubmit = () => setTimeout(() => setIsInterested(false), 2000);
  return (
    <Header type="workers">

      <div className="cards_container">

        <div className="card card_detail">
          <div
            className="card_image"
            style={{
              backgroundImage: `url(${downPayment})`,
              cursor: 'default',
            }}
          >
            {' '}
            <div style={{ margin: 'auto' }}>
              <span style={{ fontSize: '90px' }}>&#127969;</span>
            </div>

          </div>
          <div className="container">

            <p>¡Ahorra y asesórate para pagar el pie de tu vivienda con Banca.Me!</p>
            <object data={logo} width="30px" height="30px"> </object>
          </div>
        </div>
        <div>
          <div
            aria-hidden
            onClick={() => handleVisibleTab('workers', history)}
            style={{
              fontSize: '15px',
              marginBottom: '10px',
              cursor: 'pointer',
              color: '#35aac5',
              textAlign: 'left',
            }}
          >
            <span style={{ fontSize: '20px', marginRight: '5px' }}>&#x2039;</span>
            Atrás

          </div>
          <div style={{ textAlign: 'left', height: '400px', overflowY: 'auto' }}>

            {isInterested

                          && HubSpot(
                            process.env.REACT_APP_PORTAL_ID,
                            process.env.REACT_APP_HOME_DOWNPAYMENT_FORM_ID,
                            handleSubmit,
                          )}
            {!isInterested && (
            <div>

              <h2>Tu primera vivienda con Banca.Me </h2>
              <p>
                Te asesoramos para encontrar la vivienda de tus sueños,
                te financiamos el pie y te guiamos en el proceso.
              </p>
              <h5>¿Cómo funciona?</h5>
              <ol style={{ fontSize: '18px' }}>
                <li>Indícanos la información sobre la vivienda que quieres</li>
                <li>Indícanos infromación sobre tu capacidad de pago</li>
                <li>Revisa nuestra propuesta de vivienda y de financiamiento</li>
                <li>Es válido con o sin subsidio</li>
              </ol>

            </div>
            )}
          </div>
          {!isInterested
                      && (
                      <h5
                        aria-hidden
                        onClick={handleIsInterested}
                        className="interestedFooter"
                      >
                        Estoy interesado
                      </h5>
                      )}
        </div>
      </div>

    </Header>
  );
}

export default DownPayment;

import React from 'react';
import { Formik, Form } from 'formik';
import {
  Button,
  Card,
  Hidden,
  Typography,
  useTheme,
} from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { formSchemaInterface } from '../../../../utils/interfaces/simulation';
import simulationStyles from '../../styles';
import { requesterDataValidationSchema } from '../validationSchemas';
import RequesterDataForm from './contactForm';
import FAQ from '../../../../components/FAQ';
import avatarPink from '../../../../assets/simulation/form/03_ResultsData/avatar_pink.svg';
import avatarBlue from '../../../../assets/simulation/form/03_ResultsData/avatar_blue.svg';
import { useIsWidthDown } from '../formLogic';
import baseBackground from '../../../../assets/simulation/form/base_background.svg';
import AlertDialog from '../../../../components/AlertDialog';
import useFBPixeleventTracker from '../../../../utils/useFBPixelEventTracker';

interface ResultsInterface {
  formRef: React.MutableRefObject<any>,
  handleNext: () => void,
  formSchema: formSchemaInterface,
  handleEnableLoading: (state: boolean) => void,
  handleReset: ()=> void,
}

function useOnScreen(ref) {
  const [isIntersecting, setIntersecting] = React.useState(false);

  const observer = new IntersectionObserver(
    ([entry]) => setIntersecting(entry.isIntersecting),
  );

  React.useEffect(() => {
    observer.observe(ref.current);
    // Remove the observer as soon as the component is unmounted
    return () => { observer.disconnect(); };
  }, []);

  return isIntersecting;
}

function ContactForm(props: ResultsInterface) {
  const {
    formRef,
    handleNext,
    formSchema,
    handleEnableLoading,
    handleReset,
  } = props;
  const classes = simulationStyles();
  const theme = useTheme();
  React.useEffect(() => {
    window.dataLayer.push({
      event: 'Proceso simulación',
      paso: 'Resultados de simulación',
    });
    handleEnableLoading(false);
  }, []);
  const resultsRef = React.useRef(null);
  const contactFormRef = React.useRef(null);
  const FAQRef = React.useRef(null);
  const resultsIsVisible = useOnScreen(resultsRef);

  const scrollIntoForm = () => {
    if (contactFormRef) {
      contactFormRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const scrollIntoFAQ = () => {
    if (FAQRef) {
      FAQRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const scrollToTop = () => {
    const navBar = document.querySelector('#navBar');
    navBar.scrollIntoView({
      behavior: 'smooth',
    });
  };
  useFBPixeleventTracker('Simulador empresas', { event: 'Entrada a Resultados simulación' });
  return (
    <Formik
      innerRef={formRef}
      validationSchema={requesterDataValidationSchema}
      initialValues={formSchema}
      onSubmit={() => { window.location.href = 'https://www.banca.me'; }}
    >
      {({
        values,
      }) => (
        <>
          <div
            className={classes.floatingButton}
            style={{
              backgroundColor: theme.palette.primary.main,
              bottom: 70,
            }}
            onClick={scrollIntoFAQ}
            aria-hidden
          >
            <HelpOutlineIcon style={{
              color: 'white', margin: 'auto', width: '100%', height: '100%',
            }}
            />
          </div>

          <div
            className={classes.floatingButton}
            style={{
              backgroundColor: theme.customPalette.white.main,
              bottom: useIsWidthDown('md') ? 10 : 130,
              left: useIsWidthDown('md') ? 50 : null,
              border: `2px solid ${theme.palette.primary.main}`,
              display: resultsIsVisible ? 'none' : null,
            }}
            onClick={scrollToTop}
            aria-hidden
          >
            <KeyboardArrowUpIcon style={{
              color: theme.palette.primary.main, margin: 'auto', width: '100%', height: '100%',
            }}
            />
          </div>
          <Hidden mdUp>
            <AlertDialog
              buttonLabel="Volver"
              dialogTitle="¿Volver al inicio?"
              dialogContent="Si continúa, volverá al incio y perderá toda la información de la simulación"
              action={handleReset}
              button={(
                <div
                  className={classes.floatingButton}
                  style={{
                    backgroundColor: theme.customPalette.white.main,
                    left: 0,
                    border: `2px solid ${theme.palette.primary.main}`,
                    zIndex: 2,
                  }}
                  aria-hidden
                >
                  <ArrowBackIcon style={{
                    color: theme.palette.primary.main, margin: 'auto', width: '100%', height: '100%',
                  }}
                  />
                </div>
          )}
            />
          </Hidden>
          <Form
            className={classes.form}
            style={{
              width: '100%',
              backgroundColor: theme.palette.background.default,
              padding: useIsWidthDown('md') ? theme.spacing(10, 0) : theme.spacing(10),
              borderRadius: '15px',
            }}
          >
            <Typography
              align="center"
              variant="h5"
              style={{ fontWeight: 'bold' }}
            >
              ¡Gracias por tu tiempo!
            </Typography>
            <Typography
              align="center"
              variant="body1"
              gutterBottom
            >
              A continuación te presentamos
              {' '}
              <span style={{ color: theme.palette.primary.main }}>los beneficios</span>
              {' '}
              de tu alianza Banca.Me

            </Typography>
            <div ref={resultsRef} className={classes.resultsContainer}>
              <div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <img src={avatarPink} alt="AvatarPink" className={classes.resultsTitleAvatar} />
                  <div
                    className={classes.resultsTitle}
                    style={{ backgroundColor: theme.customPalette.tertiary.main }}
                  >
                    <Typography
                      align="left"
                      display="inline"
                      variant="body1"
                      style={{ color: 'white', fontWeight: 'bold' }}
                    >
                      Para los trabajadores
                    </Typography>
                    <span className={classes.dot} />
                  </div>
                </div>
                <Typography
                  variant="body1"
                  color="textSecondary"
                  style={{ margin: 'auto 13%', marginTop: '-20px', marginBottom: '30px' }}
                >
                  Con Banca.Me tus trabajadores estarían ahorrando hasta:
                </Typography>
                <div className={classes.detailGrid}>
                  {+values.simulation.employees.personalSave > 0
                  && (
                  <Card
                    elevation={5}
                    className={classes.resultsCard}
                  >
                    <Typography
                      color="secondary"
                      variant="h1"
                      gutterBottom
                      style={{ fontWeight: 'bold', marginLeft: '-40px', alignSelf: Math.abs(values.simulation.employees.personalSave) > 99999 ? 'center' : null }}
                    >
                      $
                      {' '}
                      {values.simulation.employees.personalSave.toLocaleString()}
                    </Typography>
                    <div style={{ alignSelf: 'flex-end' }}>
                      <Typography variant="h5" style={{ fontWeight: 'bold' }} gutterBottom>

                        {' '}
                        Pesos
                      </Typography>
                      <Typography variant="body1" color="textSecondary">

                        {' '}
                        Anualmente por cada persona que tiene un crédito vigente
                      </Typography>
                    </div>
                  </Card>
                  )}
                  <Card
                    elevation={5}
                    className={classes.resultsCard}
                  >
                    <Typography
                      color="secondary"
                      variant="h1"
                      gutterBottom
                      style={{ fontWeight: 'bold', marginLeft: '-40px' }}
                    >
                      +
                      {' '}
                      {values.simulation.employees.days.toLocaleString()}
                    </Typography>
                    <div>
                      <Typography variant="h6" style={{ fontWeight: 'bold' }} gutterBottom>

                        {' '}
                        Días
                      </Typography>
                      <Typography variant="body1" color="textSecondary">

                        {' '}
                        Anualmente dedicados a solicitar un crédito
                      </Typography>
                    </div>
                  </Card>
                </div>
              </div>
              <div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <img src={avatarBlue} alt="AvatarBlue" className={classes.resultsTitleAvatar} />
                  <div
                    className={classes.resultsTitle}
                    style={{ backgroundColor: theme.palette.primary.main }}
                  >
                    <Typography
                      align="left"
                      variant="body1"
                      style={{ color: 'white', fontWeight: 'bold' }}
                    >
                      Para la empresa
                    </Typography>
                    <span className={classes.dot} />
                  </div>
                </div>

                <Typography
                  variant="body1"
                  color="textSecondary"
                  style={{ margin: 'auto 13%', marginTop: '-20px', marginBottom: '30px' }}
                >
                  Con Banca.Me tu empresa estaría ahorrando hasta:
                </Typography>
                <div className={classes.detailGrid}>
                  <Card
                    elevation={5}
                    className={classes.resultsCard}
                  >
                    <Typography
                      color="secondary"
                      variant="h1"
                      gutterBottom
                      style={{ fontWeight: 'bold', marginLeft: '-40px' }}
                    >
                      +
                      {' '}
                      {values.simulation.employees.totalDaySave.toLocaleString()}
                    </Typography>
                    <div>
                      <Typography variant="h6" style={{ fontWeight: 'bold' }} gutterBottom>

                        {' '}
                        Días
                      </Typography>
                      <Typography variant="body1" color="textSecondary">

                        {' '}
                        Anualmente en trámites para gestionar créditos
                      </Typography>
                    </div>
                  </Card>
                  <Card
                    elevation={5}
                    className={classes.resultsCard}
                  >
                    <Typography
                      color="secondary"
                      variant={values.simulation.employees.totalPersonalSave < 9999999 ? 'h1' : 'h2'}
                      gutterBottom
                      style={{ fontWeight: 'bold', marginLeft: '-30px' }}
                    >
                      $
                      {' '}
                      {values.simulation.employees.totalPersonalSave.toLocaleString()}
                    </Typography>
                    <div style={{ alignSelf: 'flex-end' }}>
                      <Typography variant="h6" style={{ fontWeight: 'bold' }} gutterBottom>

                        {' '}
                        Pesos
                      </Typography>
                      <Typography variant="body1" color="textSecondary">

                        {' '}
                        Anualmente por un incremento de la productividad laboral
                      </Typography>
                    </div>
                  </Card>
                </div>
              </div>
            </div>
            <div style={{ margin: '25px auto', textAlign: 'center' }}>
              <Button
                variant="contained"
                onClick={scrollIntoForm}
              >
                Quiero que me contacten
              </Button>
            </div>
          </Form>
          <div
            ref={contactFormRef}
            className={classes.contactFormContainer}
            style={{
              backgroundImage: `url(${baseBackground})`,
              backgroundColor: theme.palette.background.default,
            }}
          >
            <RequesterDataForm handleNext={handleNext} formSchema={formSchema} />
          </div>
          <div
            ref={FAQRef}
            className={classes.contactFormContainer}
          >
            <FAQ />
          </div>
        </>

      )}
    </Formik>
  );
}

export default ContactForm;

import React from 'react';
import useSWR, { mutate } from 'swr';
import fetcher from '../../utils/fetcher';
import type {
  StatsData, LoansData, InvoicesData, SimulationWithPLRResponse,
} from '../../types/account';

export function getCompanyStats(token: string) {
  const [statsData, setStatsData] = React.useState<StatsData['data']['companyStats']|null>(null);
  const { data, error, isLoading }: {data: StatsData, error: boolean, isLoading: boolean} = useSWR([`${process.env.REACT_APP_API_URL}/company/stats`, 'GET', null, token], ([url, method, body]) => fetcher(url, method, body, token));
  React.useEffect(() => {
    if (data) {
      const orderedInvoiceData = data.data.companyStats;
      setStatsData(orderedInvoiceData);
    }
  }, [data]);
  return ({
    statsData,
    statsDataLoading: isLoading,
    statsDataError: error,
  });
}

export function getCompanyLoans(token: string) {
  const [loansData, setLoansData] = React.useState<LoansData['data']['loans']|null>(null);
  const { data, error, isLoading }: {data: LoansData, error: boolean, isLoading: boolean} = useSWR([`${process.env.REACT_APP_API_URL}/company/loans`, 'GET', null, token], ([url, method, body]) => fetcher(url, method, body, token));
  React.useEffect(() => {
    if (data) {
      const orderedLoanData = data.data.loans.map(({
        rut, firstName, lastName, loanAmount, interestRate, installmentAmount, paidPeriods, ...keep
      }) => ({
        rut, firstName, lastName, loanAmount, interestRate, installmentAmount, paidPeriods, ...keep,
      }));
      setLoansData(orderedLoanData);
    }
  }, [data]);
  return ({
    loansData,
    loansDataLoading: isLoading,
    loansDataError: error,
  });
}

export function getCompanyInvoices(token: string) {
  const [invoicesData, setInvoicesData] = React.useState<InvoicesData['data']['invoices']|null>(null);
  const { data, error, isLoading }: {data: InvoicesData, error: boolean, isLoading: boolean} = useSWR([`${process.env.REACT_APP_API_URL}/company/invoices`, 'GET', null, token], ([url, method, body]) => fetcher(url, method, body, token));
  React.useEffect(() => {
    if (data) {
      const orderedInvoiceData = data.data.invoices;
      setInvoicesData(orderedInvoiceData);
    }
  }, [data]);
  return ({
    invoicesData,
    invoicesDataLoading: isLoading,
    invoicesDataError: error,
  });
}

export function getPLRSimulation(token: string, loanSimulationId: string) {
  const [plrSimulation, setPlrSimulation] = React.useState<SimulationWithPLRResponse['data']|null>(null);
  const { data, error, isLoading }: {data: SimulationWithPLRResponse,
    error: boolean,
    isLoading: boolean} = useSWR([`${process.env.REACT_APP_API_URL}/pre-loan-request/simulation/${loanSimulationId}`,
      'GET',
      null,
      token], ([url, method, body]) => fetcher(url, method, body, token));
  React.useEffect(() => {
    if (data) {
      const orderedPLRSimulationData = data.data;
      setPlrSimulation(orderedPLRSimulationData);
    }
  }, [data]);
  return ({
    plrSimulation,
    plrSimulationLoading: isLoading,
    plrSimulationError: error,
  });
}

export async function postCompanyAnswer(
  token: string,
  plrId: string,
  loanSimulationId: string,
  answer: boolean,
) : Promise<{data:{preLoanRequest: any, simulation: any}}> {
  const res = await fetcher(
    `${process.env.REACT_APP_API_URL}/pre-loan-request/${plrId}/company-answer`,
    'POST',
    { loanSimulationId, answer },

    token,
  );
  return res;
}

export function mutatePLRSimulation(token: string, loanSimulationId: string) {
  return mutate([`${process.env.REACT_APP_API_URL}/pre-loan-request/simulation/${loanSimulationId}`,
    'GET',
    null,
    token]);
}

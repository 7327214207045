import React from 'react';
import { CircularProgress, Grid, Typography } from '@mui/material';
import AppNavbar from '../../../components/AppNavbar';
import type { CompanyAccountComponent } from '../../../types/account';
import { getCompanyInvoices } from '../../../queries/account';
import InvoiceDataTable from '../../../features/account/InvoiceDataTable';
import ViewTitle from '../../../components/ViewTitle';
import avatarExcited from '../../../assets/avatar_excited.svg';

export default function CompanyAccountInvoices(props: CompanyAccountComponent) {
  const { token, companyName } = props;
  const { invoicesData, invoicesDataLoading, invoicesDataError } = getCompanyInvoices(token);
  return (
    <AppNavbar title={companyName}>
      <>
        <Grid
          container
        >
          <Grid
            item
            xs={4}
            sm={2}
            lg={1}
          >
            <object
              data={avatarExcited}
              width="80px"
              style={{ marginInline: 'auto' }}
              aria-labelledby="Bancamin"
            />
          </Grid>
          <ViewTitle
            title="Historial de pagos y pagos pendientes"
            color="alternativePrimary"
            xs={8}
            md={8}
            lg={4}
          />

        </Grid>
        {invoicesDataError && <Typography>¡Ha ocurrido un error! Por favor contáctanos</Typography>}
        {!invoicesDataError && invoicesDataLoading && <CircularProgress size={50} />}
        {!invoicesDataError && !invoicesDataLoading && invoicesData
          && (
          <InvoiceDataTable
            companyInvoices={invoicesData}
          />
          )}
        <div style={{ marginBottom: '100px' }} />
      </>
    </AppNavbar>
  );
}

import stringFormat from './Body/labels';
import headerLabels from './Head/labels';
import saveAsExcel from '../../utils/saveAsExcel';

export function generateHeadCells(data) {
  const headCells = Object.keys(data).map((currentelement, index) => ({
    id: currentelement, numeric: (index !== 0), disablePadding: false, label: currentelement,
  }));
  return headCells.filter((attr) => !['id', 'loanId', 'userId', 'quoteRequestId', 'companyId', 'identificationId', 'prepaidLoanId', 'debtId', 'ownerId', 'preLoanRequestId', 'paymentMethodId', 'isPaid'].includes(attr.id));
}

export const handleSelect = (selectFilter, row) => {
  let isSelect = true;
  if (selectFilter) {
    Object.keys(selectFilter).forEach((key) => {
      if (key in row) {
        isSelect = selectFilter[key].function(row[key]);
      }
    });
  }
  return isSelect;
};
export const handleSaveAsExcel = (rows, title) => saveAsExcel(
  rows.map((row) => Object.keys(row).map((key) => stringFormat(row, key))),
  Object.keys(rows[0]).map((headCell) => (
    Object.keys(headerLabels).includes(headCell) ? headerLabels[headCell] : headCell)),
  title,
);

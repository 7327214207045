import React from 'react';
import {
  Route, Switch, useHistory, Redirect, RouteComponentProps,
} from 'react-router-dom';
// @ts-ignore
import { PageTransition } from '@steveeeie/react-page-transition';
import CrRoutes from './routes'; // Route list
import CompanyReportLogIn from '../../../pages/company-report/00_login';
import { demoData } from '../../../utils/CompanyReportSampleData';
import CompanyReportButtons from './buttons';
import { FetchedCompanyReport, RenderedCompanyReport } from '../../../utils/interfaces/company-report';

interface LocationState {
  location: RouteComponentProps
}

function CompanyReportRoutes(props: LocationState) {
  const { location } = props;
  const [companyReportLogged, setCompanyReportLogged] = React.useState(false);

  const [counter, setCounter] = React.useState<number>(0);
  const [preset, setPreset] = React.useState<string>('moveToTopFromBottom');
  const handleCounter = (num: number) => setCounter(num);
  const handlePreset = (pres: string) => setPreset(pres);

  const history = useHistory();
  const [reportData, setReportData] = React.useState(
    {
      bancameIndex: 0,
      companyData: {},
      genderOptions: [{}],
      costCenterOptions: [{}],
      roleOptions: [{}],
      ageGroupOptions: [{}],
      incomeGroupOptions: [{}],
    },
  );

  const handleCompanyReportLogin = (value:string|FetchedCompanyReport) => {
    if (value === 'Demo') {
      setReportData({
        bancameIndex: demoData.company.bancameIndex,
        companyData: demoData.company,
        genderOptions: demoData.gender,
        costCenterOptions: demoData.costCenter,
        roleOptions: demoData.role,
        ageGroupOptions: demoData.ageGroups,
        incomeGroupOptions: demoData.incomeGroups,
      });
    } else {
      const CR = value as FetchedCompanyReport;
      setReportData({
        bancameIndex: CR.report.score,
        companyData: CR.report.company,
        genderOptions: CR.report.gender,
        costCenterOptions: CR.report.costCenter,
        roleOptions: CR.report.role,
        ageGroupOptions: CR.report.ageGroups,
        incomeGroupOptions: CR.report.incomeGroups,
      });
    }
    setCounter(1);
    setCompanyReportLogged(true);
  };
  React.useEffect(
    () => {
      if (companyReportLogged) {
        history.push('/company-report/bancame-index');
      }
    },
    [companyReportLogged],
  );

  const companyReportLogIn = () => (
    <CompanyReportLogIn
      handleLogin={handleCompanyReportLogin}
      handleCounter={handleCounter}
    />
  );

  return (
    <>
      {CrRoutes.some((route) => (window.location.pathname.includes(route.path)))
        && (
        <CompanyReportButtons
          counter={counter}
          handlePreset={handlePreset}
          handleCounter={handleCounter}
        />
        )}
      {/* eslint-disable */ }
      {/* @ts-ignore */}
      <PageTransition transitionKey={location.pathname}
        preset={preset}
      >
              {/* @ts-ignore */}
        <Switch location={location}>

          {CrRoutes.map(({ component: Component, path, exact }) => (
            <Route
              path={`/company-report/${path}`}
              key={path}
              exact={exact}
            >
              {companyReportLogged ? <Component reportData={reportData as RenderedCompanyReport} />
                : <Redirect to="/company-report" />}
            </Route>
          ))}
          <Route path="/company-report/" component={companyReportLogIn} />

        </Switch>
      </PageTransition>
    </>

  );
}

export default CompanyReportRoutes;

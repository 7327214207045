import React from 'react';
import {
  Table,
  TableContainer,
  Paper,
} from '@mui/material';

import EnhancedTableHead from './Head';
import EnhancedTableToolbar from './Toolbar';
import EnhancedTableBody from './Body';
import EnhancedTableFooter from './Footer';
import useStyles from './styles';
import {
  handleSelect,
  generateHeadCells,

} from './helpers';
import type { AdminTableComponentProps } from './types';

export default function AdminTableComponent(props: AdminTableComponentProps) {
  const classes = useStyles();
  const {
    rows,
    isSelect,
    title,
    defaultFilters,
    onItemClicked,
    onActionClicked,
    deselectAll,
    getSelected,
    cellHighlight,
    selectFilter,
    optionalIcon,
  } = props;
  const [order, setOrder] = React.useState<'asc'|'desc'>('desc');
  const [orderBy, setOrderBy] = React.useState('createdAt');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(30);
  const [filters, setFilters] = React.useState([]);
  const [filteredRows, setFilteredRows] = React.useState(rows);
  const headCells = generateHeadCells(rows[0]);

  const handleFilters = (filterArray) => setFilters(filterArray);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked && selected.length === 0) {
      const filteredSelectedRows = filteredRows.filter((row) => handleSelect(selectFilter, row));
      return setSelected(filteredSelectedRows);
    }
    return setSelected([]);
  };

  const handleChangePage = React.useCallback(
    (event: unknown, newPage: number) => {
      setPage(newPage);
    },
    [order, orderBy, rowsPerPage],
  );
  const handleChangeRowsPerPage = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const updatedRowsPerPage = parseInt(event.target.value, 10);
      setRowsPerPage(updatedRowsPerPage);
      setPage(0);
    },
    [order, orderBy],
  );

  const onEditClicked = () => {
    onActionClicked(selected);
  };
  React.useEffect(() => {
    if (deselectAll) {
      setSelected([]);
    }
  }, [deselectAll]);

  React.useEffect(() => {
    if (getSelected) {
      getSelected(selected);
    }
  }, [selected]);

  React.useEffect(
    () => {
      if (filters.length === 0) {
        return setFilteredRows(rows);
      }
      setFilteredRows(rows.filter((item) => {
        // eslint-disable-next-line no-restricted-syntax
        for (const key in filters) {
          if (!JSON.stringify(item).toLowerCase()
            .includes(filters[key].toLowerCase())) { return false; }
        }
        return true;
      }));
      return undefined;
    },
    [rows, filters],
  );
  const concatSelection = (name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleItemClick = (_, name) => {
    if (isSelect) {
      concatSelection(name);
      if (onItemClicked && _.detail === 2) {
        return onItemClicked(name);
      }
      if (_.target.tagName === 'SPAN') {
        onItemClicked(name);
      }
      return undefined;
    }
    onItemClicked(name);
    return setSelected([name]);
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper} style={{ borderRadius: 20 }}>
        <EnhancedTableToolbar
          title={title}
          numSelected={selected.length}
          handleFilters={handleFilters}
          isSelect={isSelect}
          onEditClick={onEditClicked}
          defaultFilters={defaultFilters}
        />
        <TableContainer className={classes.container}>
          <Table
            stickyHeader
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={filteredRows.length}
              headCells={headCells}
              isSelect={isSelect}
              isSelectButton={filteredRows.some((row) => handleSelect(selectFilter, row))}
            />
            <EnhancedTableBody
              rows={rows}
              order={order}
              orderBy={orderBy}
              page={page}
              rowsPerPage={rowsPerPage}
              isSelect={isSelect}
              getSelected={getSelected}
              cellHighlight={cellHighlight}
              selectFilter={selectFilter}
              optionalIcon={optionalIcon}
              selected={selected}
              filteredRows={filteredRows}
              handleItemClick={handleItemClick}
            />
          </Table>
        </TableContainer>
        <EnhancedTableFooter
          title={title}
          rows={rows}
          page={page}
          rowsPerPage={rowsPerPage}
          cellHighlight={cellHighlight}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}

AdminTableComponent.defaultProps = {
  isSelect: false,
  onItemClicked: undefined,
  onActionClicked: undefined,
  getSelected: undefined,
  deselectAll: false,
  defaultFilters: [],
  cellHighlight: {},
  selectFilter: undefined,
  optionalIcon: <div />,
};

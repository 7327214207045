import React from 'react';
import { useHistory } from 'react-router';
import TokenDecode from '../../../features/account/TokenDecode';

export default function CompanyAccountLogin() {
  const history = useHistory();
  return (
    <TokenDecode
      successCallback={() => history.push('/account')}
      dataLayer={{
        event: 'Inicio sesión',
      }}
    />
  );
}

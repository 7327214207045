import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  loanStatsContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    padding: 30,
    columnGap: 40,
    [theme.breakpoints.down('lg')]: {
      gridTemplateColumns: '1fr',
      rowGap: 20,
    },
  },
  rejectedStats: {
    padding: 20,
    maxWidth: '50%',
    margin: 'auto',
    [theme.breakpoints.down('lg')]: {
      maxWidth: '100%',
    },
  },
  statsCard: {
    padding: 20,
    [theme.breakpoints.down('lg')]: {
      padding: 5,
    },
  },
  plrStatsContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    columnGap: 20,
    padding: 10,
    [theme.breakpoints.down('lg')]: {
      gridTemplateColumns: '1fr',
      rowGap: 20,
    },
  },
}));

export default useStyles;

import React from 'react';
import { Paper, Typography } from '@mui/material';
import AdminTableComponent from './AdminTable';
import type { AdminTableIndexProps } from './types';

export default function AdminTable(props: AdminTableIndexProps) {
  const {
    rows,
    isSelect,
    title,
    defaultFilters,
    onItemClicked,
    onActionClicked,
    deselectAll,
    getSelected,
    cellHighlight,
    selectFilter,
    optionalIcon,
    noDataText = '(Actualmente no hay datos)',
  } = props;
  return (
    rows.length > 0
      ? (
        <AdminTableComponent
          rows={rows}
          isSelect={isSelect}
          title={title}
          defaultFilters={defaultFilters}
          onItemClicked={onItemClicked}
          onActionClicked={onActionClicked}
          deselectAll={deselectAll}
          getSelected={getSelected}
          cellHighlight={cellHighlight}
          selectFilter={selectFilter}
          optionalIcon={optionalIcon}
        />
      )
      : (
        <Paper style={{ padding: 50, borderRadius: 20 }}>
          <Typography variant="h5" fontStyle="italic" color="text.secondary">
            {noDataText}
          </Typography>
        </Paper>
      )
  );
}

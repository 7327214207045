import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => ({
  customToolTip: {
    backgroundColor: 'white',
    padding: theme.spacing(2),
    borderRadius: '8px',
    width: '300px',
  },
  customToolTipLabel: {
    color: 'black',
    paddingBottom: theme.spacing(1),
  },
}));

export default useStyles;

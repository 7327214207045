import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import BackgroundPic from '../../assets/simulation/background.jpg';

const simulationStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100vh',
    position: 'absolute',
    textAlign: 'left',
    overflowY: 'auto',
    backgroundColor: theme.customPalette.white.main,
    padding: theme.spacing(0, 5),
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0, 2),
    },
  },
  navBar: {
    display: 'flex',
    width: '100%',
    placeContent:
      'space-between',
    alignItems: 'center',
    height: '12%',
  },
  stepperContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 5fr',
    width: '50%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  logo_img: {
    width: 60,
    [theme.breakpoints.down('md')]: {
      width: 50,
    },
  },
  sub_logo_img: {
    cursor: 'pointer',
    width: 45,
    margin: 10,
    [theme.breakpoints.down('md')]: {
      width: 30,
      position: 'relative',
    },
  },
  stepper: {
    backgroundColor: theme.customPalette.white.main,
  },
  backButton: {
    margin: 'auto',
  },
  leftGrid: {
    height: '88%',
    backgroundColor: theme.palette.background.default,
    borderRadius: '15px 0px 0px 15px',
  },
  infographicsContainer: {
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '800px 100%',
    [theme.breakpoints.down('md')]: {
      backgroundSize: '0',
    },
  },
  rightGrid: {
    backgroundColor: theme.palette.background.default,
    height: '88%',
    padding: theme.spacing(10),
    borderRadius: '0px 15px 15px 0px',
    [theme.breakpoints.down('lg')]: {
      borderRadius: '15px',
      padding: theme.spacing(5),
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
      },
    },

  },
  selectOptions: {
    color: 'red',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(5, 0),
    width: '60%',
    rowGap: 20,
    [theme.breakpoints.down('lg')]: {
      width: '80%',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      padding: theme.spacing('2.5%', 0),
    },
  },
  formHelperText: {
    [theme.breakpoints.up('sm')]: {
      height: 0,
      margin: 0,
    },
  },
  loadingAvatar: {
    width: 300,
    height: 150,
    marginTop: 80,
    [theme.breakpoints.down('sm')]: {
      width: 250,
      height: 110,
      marginTop: 75,
    },
  },
  loadingCircle: {
    width: 300,
    animation: 'spin 6s infinite ease-in-out',
    [theme.breakpoints.down('sm')]: {
      width: 250,
    },
  },
  detailGrid: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    columnGap: 10,
    justifyItems: 'center',
    [theme.breakpoints.down('lg')]: {
      gridTemplateColumns: '1fr',
      columnGap: 0,
      rowGap: 10,
      width: '100%',
    },
  },
  contactFormContainer: {
    minHeight: '100vh',
    borderRadius: '15px',
    marginTop: '5%',
    justifyContent: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '500px 100%',
    backgroundPosition: 'left',
    [theme.breakpoints.down('lg')]: {
      backgroundSize: '400px 97%',
    },
    [theme.breakpoints.down('md')]: {
      backgroundSize: 0,
    },
  },
  submitButton: {
    maxWidth: theme.spacing(40),
    marginBottom: 0,
  },

  image: {
    backgroundImage: `url(${BackgroundPic})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor: theme.palette.grey[50],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },

  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  textInput: {
    margin: '10px auto',
    width: '50%',
  },
  helperText: {
    fontSize: 'small',
    margin: 0,
    marginBottom: theme.spacing(1),
    padding: '0 2%',
    height: 0,
  },
  paper: {
    margin: theme.spacing(2, '5%'),
    padding: theme.spacing('5%', '5%'),
    borderRadius: '10px',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(2, '2%'),
    },
  },

  resultsContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr',
    },
  },
  resultsCard: {
    display: 'grid',
    borderRadius: '15px',
    textAlign: 'center',
    width: '95%',
    padding: '8%',
    height: '200px',
    margin: 'auto 10%',
    [theme.breakpoints.down('md')]: {
      width: '80%',
      marginBottom: '10%',
    },
  },
  resultsTitleAvatar: {
    height: 125,
    zIndex: 1,
    [theme.breakpoints.down('md')]: {
      height: 100,
    },
  },
  resultsTitle: {
    alignItems: 'center',
    display: 'flex',
    borderRadius: '15px',
    width: '40%',
    padding: theme.spacing(1, 10),
    paddingRight: theme.spacing(1),
    marginLeft: '-30px',
    placeContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      width: '90%',
    },

  },
  dot: {
    height: 18,
    width: 18,
    backgroundColor: 'white',
    borderRadius: '50%',
  },
  resultsDetails: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    rowGap: 20,
    [theme.breakpoints.down('lg')]: {
      gridTemplateColumns: '1fr',
    },
  },
  dialog: {
    '& .MuiDialog-container .MuiDialog-paper': {
      margin: 0,
    },
    [theme.breakpoints.down('sm')]: {
      '& .MuiDialog-container .MuiDialog-paper': {
        marginLeft: '2.5%',
        marginRight: '2.5%',
      },
    },
  },
  floatingButton: {
    position: 'fixed',
    float: 'right',
    zIndex: 1,
    bottom: 10,
    right: 10,
    borderRadius: '50%',
    height: 50,
    width: 50,
    padding: 10,
    margin: 10,
    cursor: 'pointer',
    transition: '0.5s',
    '&:hover': {
      boxShadow: `0 0 5px 1px ${theme.palette.primary.main}`,
    },
    [theme.breakpoints.down('sm')]: {
      height: 38,
      width: 38,
      padding: 5,
      margin: 0,
      marginBottom: 30,
    },
  },
  buttonsContainer: {
    margin: '2% auto',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    columnGap: 30,
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
      rowGap: 30,
    },
  },
}));

export default simulationStyles;

import React from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  Typography,
  useTheme,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FAQStyles from './styles';
import FAQContent from './content';
import blueLogo from '../../assets/bancame_logo.svg';

export default function FAQ() {
  const classes = FAQStyles();
  const theme = useTheme();
  const goToLanding = () => {
    window.open('https://banca.me/');
  };
  return (
    <>
      <Typography
        variant="h3"
        align="center"
        style={{
          paddingTop: '2.5%',
          marginBottom: '2%',
          fontWeight: 'bold',
        }}
      >
        ¿Tienes dudas sobre Banca
        <span style={{ color: theme.customPalette.tertiary.main }}>.</span>
        Me?

      </Typography>
      {FAQContent.map((content) => (
        <div className={classes.accordion} key={content.id}>
          <Accordion square>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id={`panel1a-header${content.id}`}
            >
              <Typography style={{ width: '100%' }} fontWeight="bold">{content.title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Divider />
              <div className={classes.accordionContent}>{content.detail}</div>
            </AccordionDetails>
          </Accordion>
        </div>
      ))}
      <div
        style={{ width: '100%', textAlign: 'center' }}
        aria-hidden
      >
        <img
          onClick={goToLanding}
          src={blueLogo}
          alt="BancaMe logo"
          className={classes.sub_logo_img}
          aria-hidden="true"
        />
      </div>
    </>
  );
}

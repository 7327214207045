import React from 'react';
import {
  Fade,
  Typography,
} from '@mui/material';
import simulationStyles from '../../styles';
import avatar from '../../../../assets/simulation/form/02_LoadingData/avatar.svg';
import loading from '../../../../assets/simulation/form/02_LoadingData/loading.svg';

interface LoadingDataInterface{
  handleNext: ()=> void,
  handleEnableLoading: (state: boolean) => void,
}

function LoadingDataForm(props: LoadingDataInterface) {
  const {
    handleNext,
    handleEnableLoading,
  } = props;
  const classes = simulationStyles();
  const [loadingMsg, setLoadingMsg] = React.useState('Creando un diagnóstico...');
  React.useEffect(() => {
    setTimeout(() => setLoadingMsg('Calculando el ahorro de los trabajadores...'), 2000);
    setTimeout(() => setLoadingMsg('Calculando el ahorro en la empresa...'), 4000);
    setTimeout(() => setLoadingMsg('Estamos preparando los resultados...'), 6000);
    setTimeout(() => {
      handleNext();
      handleEnableLoading(false);
    }, 7000);
  }, []);
  return (
    <Fade
      in
    // eslint-disable-next-line react/jsx-props-no-spreading
      {...{ timeout: 2000 }}
    >
      <div style={{ textAlign: 'center', margin: '100px auto' }}>
        <div>
          <img
            src={avatar}
            className={classes.loadingAvatar}
            alt="LoadingAvatar"
            style={{ position: 'absolute' }}
          />
          <img
            className={classes.loadingCircle}
            src={loading}
            alt="Loading"
          />
        </div>
        <br />
        <Typography
          align="center"
          variant="h6"
          fontWeight="bold"
        >
          {loadingMsg}
        </Typography>
      </div>
    </Fade>
  );
}

export default LoadingDataForm;

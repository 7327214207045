import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const FAQStyles = makeStyles((theme: Theme) => ({
  accordion: {
    margin: '2% 25%',
    [theme.breakpoints.down('sm')]: {
      margin: '2.5% auto',
    },
  },
  sub_logo_img: {
    cursor: 'pointer',
    width: 55,
    margin: 10,
    [theme.breakpoints.down('md')]: {
      width: 30,
      position: 'relative',
    },
  },
  accordionSummary: {
    [theme.breakpoints.down('md')]: {
      placeContent: 'center',
    },
  },
  accordionContent: {
    margin: '2.5%',
    textAlign: 'justify',
  },
}));

export default FAQStyles;

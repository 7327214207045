import React from 'react';
import {
  Button,
  Divider, Grid, Paper, Typography,
} from '@mui/material';
import { prettifyRut } from 'react-rut-formatter';
import LoanSimulation from './LoanSimulation';
import AlertDialog from '../../../components/AlertDialog';
import { postCompanyAnswer, mutatePLRSimulation } from '../../../queries/account';
import type { SimulationWithPLRResponse } from '../../../types/account';

type LoanSimulationDetailProps ={
    plr: SimulationWithPLRResponse['data']['preLoanRequest'],
    simulation: SimulationWithPLRResponse['data']['simulation']
    loanSimulationId: string,
    token: string
}

export default function LoanSimulationDetail(props:LoanSimulationDetailProps) {
  const {
    plr,
    simulation,
    loanSimulationId,
    token,
  } = props;
  const [loading, setLoading] = React.useState(false);
  const [answerSuccess, setAnswerSuccess] = React.useState<boolean|null>(null);
  const [error, setError] = React.useState(false);
  const handleCompanyAnswer = async (answer: boolean) => {
    try {
      setLoading(true);
      setError(false);
      window.dataLayer.push({
        event: 'Aprobación de crédito',
        boton: answer ? 'Aprobar solicitud' : 'Rechazar solicitud',
      });
      await postCompanyAnswer(
        token,
        simulation.preLoanRequestId,
        loanSimulationId,
        answer,
      );
      mutatePLRSimulation(token, loanSimulationId);
      setAnswerSuccess(answer);
    } catch (e) {
      setLoading(false);
      setError(true);
    }
  };
  return (
    <Grid container justifyContent="center" component={Paper} borderRadius={5} paddingY={10}>
      <Typography
        gutterBottom
        variant="h5"
        fontWeight="bold"
        component="div"
        width="100%"
        align="center"
      >
        {`${plr.firstName.toUpperCase()} ${plr.lastName.toUpperCase()}`}

      </Typography>
      <Typography
        gutterBottom
        variant="h6"
        fontWeight="bold"
        component="div"
        width="100%"
        align="center"
      >
        {prettifyRut(plr.idNumber)}

      </Typography>
      <Divider style={{ width: '100%' }} />
      {plr.step === 'COMPANY_APROVE'
        ? (
          <>
            <Typography
              gutterBottom
              variant="h6"
              component="div"
              width="100%"
              align="center"
              marginY={5}
            >
              Ha solicitado la aprobación de un crédito con Banca.Me

            </Typography>

            <LoanSimulation
              amount={simulation.amount}
              installment={simulation.installment}
              interest={simulation.interest}
              periods={simulation.periods}
              originCost={simulation.originCost}
              createdAt={simulation.createdAt}
            />
            {error
      && (
      <Typography width="100%" align="center" variant="caption" color="error">
        Ha ocurrido un error.
        Por favor contáctanos para resolver este problema lo antes posible.
      </Typography>
      )}
          </>
        )
        : (
          <div>
            {answerSuccess !== null
            && (
            <Typography
              variant="h6"
              color="green"
              align="center"
              fontWeight="bold"
              marginTop={10}
              paddingX={5}
            >
              ¡Solicitud
              {' '}
              {answerSuccess ? 'APROBADA' : 'RECHAZADA'}
              {' '}
              con éxito!
            </Typography>
            )}
            <Typography
              width="100%"
              align="center"
              variant="h6"
              maxWidth={500}
              component="div"
              marginY={10}
              paddingX={2}
            >
              Esta persona parece no requerir más la aprobación por parte de su empleador.
              {' '}
              <b>
                Esto se debe a que su solicitud pudo haber
                sido aceptada o rechazada en el pasado.

              </b>
            </Typography>
            <Typography
              width="100%"
              align="center"
              variant="h6"
              maxWidth={500}
              component="div"
              marginBottom={10}
              paddingX={2}
            >
              Si tienes alguna duda, no dudes en ponerte en contacto con nostros
            </Typography>
          </div>
        )}
      <Grid>
        {plr.step === 'COMPANY_APROVE'
        && (
        <>
          <br />
          <div>
            <AlertDialog
              dialogTitle={<b>¿Aceptar solicitud?</b>}
              action={() => handleCompanyAnswer(true)}
              submitButtonDisabled={loading}
              closeButtonDisabled={loading}
              dialogContent={(
                <Typography>
                  Al presionar
                  {' '}
                  <b style={{ color: 'green' }}>ACEPTAR, estará admitiendo</b>
                  {' '}
                  la solicitud de crédito de su colaborador.
                  {' '}
                  <b>Esta acción es irreversible.</b>
                </Typography>
)}
              button={(
                <Button
                  variant="contained"
                  color="secondary"
                >
                  <b>ACEPTAR SOLICITUD</b>
                </Button>
)}
              submitButtonLabel="ACEPTAR"
            />

          </div>

          <AlertDialog
            dialogTitle={<b>¿Rechazar solicitud?</b>}
            action={() => handleCompanyAnswer(false)}
            submitButtonDisabled={loading}
            closeButtonDisabled={loading}
            dialogContent={(
              <Typography>
                Al presionar
                {' '}
                <b style={{ color: 'red' }}>RECHAZAR, estará denegando</b>
                {' '}
                la solicitud de crédito de su colaborador.
                {' '}
                <b>Esta acción es irreversible.</b>
              </Typography>
)}
            button={<Button><b>RECHAZAR SOLICITUD</b></Button>}
            submitButtonLabel="RECHAZAR"
          />
        </>
        )}
      </Grid>
    </Grid>
  );
}

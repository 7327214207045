const headerLabels = {
  idNumber: 'RUT',
  rut: 'RUT',
  firstName: 'Nombre',
  lastName: 'Apellido',
  origin: 'Origen',
  phone: 'Teléfono',
  email: 'Email',
  step: 'Etapa funnel',
  companyName: 'Nombre Empresa',
  companyContactName: 'Nombre contacto Empresa',
  companyEmail: 'Email Empresa',
  companyPhone: 'Teléfono Empresa',
  createdAt: 'Fecha creación',
  updatedAt: 'Fecha actualización',
  lastContactDate: 'Fecha último contacto',
  timesContacted: 'Veces contactado',
  state: 'Estado',
  number: 'Número',
  loanPurpose: 'Propósito Crédito',
  bruteAmount: 'Monto bruto',
  loanAmount: 'Monto crédito',
  totalAmount: 'Monto total',
  interestRate: 'Tasa de interés',
  periods: 'Plazo',
  installmentAmount: 'Monto cuota',
  lastInstallmentAmount: 'Último monto cuota',
  totalCost: 'Costo total',
  originCost: 'Costo originación',
  insurance: 'Seguro',
  notary: 'Notaría',
  taxes: 'Impuestos',
  monthlyCAE: 'CAE mensual',
  CAE: 'CAE',
  transferDate: 'Fecha transferencia',
  type: 'Tipo',
  hasDelinquency: 'Es moroso',
  name: 'Nombre',
  amount: 'Monto',
  paidAt: 'Fecha pago',
  agent: 'Agente',
  companyIdNumber: 'RUT Empresa',
  verified: 'Verificado',
  emailVerified: 'Email Verificado',
  adminRole: 'Rol plataforma Admin',
  memberRole: 'Rol plataforma Créditos',
  gender: 'Género',
  totalPeriods: 'Períodos totales',
  period: 'Período',
  interest: 'Interés',
  principalBalance: 'Capital adeudado',
  principal: 'Principal',
  expirationDate: 'Fecha expiración',
  affiliated: 'Afiliada',
  paymentDay: 'Día de pago',
  approved: 'Aprobado',
  canceled: 'Cancelado',
  signed: 'Firmado',
  children: 'Colaboradores',
  maxAmount: 'Monto máximo',
  maxPeriods: 'Máximo períodos',
  rate: 'Tasa',
  accepted: 'Aceptado',
  nWorkers: 'N° Trabajadores',
  economicActivity: 'Actividad Económica',
  turnoverIndex: 'Índice de rotación',
  provisionIndex: 'Indice de provisión',
  validUntil: 'Válido hasta',
  prepayPrincipal: 'Principal prepago',
  interestAmount: 'Interés',
  cashOut: 'Libre disposición',
  gracePeriods: 'Períodos de gracia',
  status: 'Estado',
  motive: 'Motivo',
  prepayCommission: 'Comisión de prepago',
  paymentMethod: 'Método de pago',
  companyRut: 'RUT Empresa',
  deniedClaveUnica: 'Versión',
  label: 'Etiqueta',
  isActive: 'Activo',
  clicks: 'Clicks temporales',
  totalClicks: 'Clicks totales',
  loans: 'Créditos',
  debtType: 'Tipo de deuda',
  installmentPeriod: 'N° de Cuota',
  installmentTotalPeriods: 'Total cuotas',
  installments: 'Cuotas de créditos',
  delinquencies: 'Morosidades',
  subscriptions: 'Cuotas de subscripción',
  dateIssued: 'Fecha emisión',
  installment: 'Cuota de crédito',
  subscription: 'Cuota de participación',
  delinquency: 'Morosidad',
  invoiceContacts: 'Contactos cobranzas',
  paymentMethodProvider: 'Proveedor método de pago',
  ownerId: 'Id del dueño',
  companyAffiliated: 'Empresa en convenio',
  score: 'Score CREDEX',
  hardFiltersRejected: 'Rechazo filtros duros',
  archivedComment: 'Comentario archivado',
  documentNumber: 'N° de documento',
  companyId: 'Id compañia',
  bankAccountEmail: 'Mail cuenta bco.',
  bankAccountClientName: 'Nombre cuenta bco.',
  bankAccountIdNumber: 'RUT cuenta bco.',
  bankAccountType: 'Tipo cuenta bco.',
  totalPrizeAmount: 'Total premio',
  referralPaymentIds: 'Cantidad referidos',
  referralCode: 'Código referidos',
  negotiableDebts: 'Deudas negociables',
  bankName: 'Nombre bco.',
  bankAccountNumber: 'Número cuenta bco.',
  clientName: 'Nombre cliente',
  clientIdNumber: 'RUT cliente',
  amountOwed: 'Monto adeudado',
  prepayInstitution: 'Institución de prepago',
  loanApproval: 'Firma Automática Deshabilitada',
  paidPeriods: 'Cuotas pagadas',
};

export default headerLabels;

import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
        {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.main,
        },
  title: {
    flex: '1 1 100%',
  },
  searchBar: {
    margin: '0px 10px',
  },
}));

export default useStyles;

import React from 'react';
import { Tooltip } from '@mui/material';
import stringFormat from './labels';
import headerLabels from '../Head/labels';
import saveAsExcel from '../../../utils/saveAsExcel';

export function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator(order: 'asc'|'desc', orderBy: string) {
  return order === 'desc'
    ? (a: string|number, b: string|number) => descendingComparator(a, b, orderBy)
    : (a:string|number, b:string|number) => -descendingComparator(a, b, orderBy);
}

export function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export function generateHeadCells(data) {
  const headCells = Object.keys(data).map((currentelement, index) => ({
    id: currentelement, numeric: (index !== 0), disablePadding: false, label: currentelement,
  }));
  return headCells.filter((attr) => !['id', 'loanId', 'userId', 'quoteRequestId', 'companyId', 'identificationId', 'prepaidLoanId', 'debtId', 'ownerId', 'preLoanRequestId', 'paymentMethodId', 'isPaid'].includes(attr.id));
}

export const handleSelect = (selectFilter: {}|undefined, row) => {
  let isSelect = true;
  if (selectFilter) {
    Object.keys(selectFilter).forEach((key) => {
      if (key in row) {
        isSelect = selectFilter[key].function(row[key]);
      }
    });
  }
  return isSelect;
};

export const renderRowLabels = (obj, classes) => {
  const header = Object.keys(obj);
  return header.map((key) => {
    if (key === 'archivedComment') {
      return (
        <Tooltip title={obj[key]}>
          <div className={classes.stringOverflow}>{obj[key]}</div>
        </Tooltip>
      );
    }
    if (!['id', 'loanId', 'userId', 'quoteRequestId', 'companyId', 'identificationId', 'prepaidLoanId', 'debtId', 'ownerId', 'preLoanRequestId', 'paymentMethodId', 'isPaid'].includes(key)) {
      return stringFormat(obj, key);
    }
    return undefined;
  });
};

export const handleHightLight = (highlightsObject, row) => {
  let color = null;
  Object.keys(highlightsObject).forEach((key) => {
    if (key in row) {
      const newColor = highlightsObject[key].function(row[key]);
      if (newColor) {
        color = newColor;
      }
    }
  });
  return color;
};

export const handleSaveAsExcel = (rows, title) => saveAsExcel(
  rows.map((row) => Object.keys(row).map((key) => stringFormat(row, key))),
  Object.keys(rows[0]).map((headCell) => (
    Object.keys(headerLabels).includes(headCell) ? headerLabels[headCell] : headCell)),
  title,
);

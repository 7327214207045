import React from 'react';
import {
  Grid, Typography, useTheme,
} from '@mui/material';
import useStyles from './styles';

type colors = 'primary' | 'alternativePrimary' | 'secondary' | 'alternativeSecondary'
type variants = 'h1' | 'h2' | 'h3' | 'h4' | 'h5'|'h6'

interface ViewTitleProps{
    title: string,
    subtitle?: string,
    color: colors,
    variant?: variants,
    xs?: number,
    md?: number,
    lg?: number
}
const defaultProps = {
  subtitle: '',
  variant: 'h4',
  lg: 12,
  md: 12,
  xs: 12,

};
export default function ViewTitle(props: ViewTitleProps) {
  const {
    title, subtitle, color, variant, lg, md, xs,
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [selectedColor, setSelectedColor] = React.useState(theme.palette.secondary.main);
  React.useEffect(() => {
    if (color === 'primary') {
      setSelectedColor(theme.palette.primary.main);
    }
    if (color === 'alternativePrimary') {
      setSelectedColor(theme.customPalette.tertiary.main);
    }
  }, []);

  return (
    <Grid
      className={classes.viewTitle}
      xs={xs}
      md={md}
      lg={lg}
      item
    >
      <div>
        <Typography
          variant={variant}
          fontWeight="bold"
          component="div"
        >
          {title}
        </Typography>
        <div style={{
          borderBottom: `4px solid ${selectedColor}`, padding: '0 0 0px', width: '50px', margin: '10px 5px',
        }}
        />

        <Typography
          variant="body2"
          color="textSecondary"
          component="div"
          align="left"
        >
          {subtitle}
        </Typography>
      </div>
    </Grid>
  );
}

ViewTitle.defaultProps = defaultProps;

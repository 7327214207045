import React from 'react';
import {
  Button, Fade, Hidden, Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import CompanyDataForm from './01_CompanyData';
import LoadingDataForm from './02_LoadingData';
import ResultsDataForm from './03_ResultsData';
import { formSchemaInterface } from '../../../utils/interfaces/simulation';
import infoBackground1 from '../../../assets/simulation/form/01_CompanyData/info_background.svg';
import infoBackground4 from '../../../assets/simulation/form/info_background.svg';
import lastAvatar from '../../../assets/simulation/form/last_avatar.svg';
import useFBPixeleventTracker from '../../../utils/useFBPixelEventTracker';

export function useIsWidthUp(breakpoint) {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.up(breakpoint));
}
export function useIsWidthDown(breakpoint) {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down(breakpoint));
}

export const formSchema = {
  nWorkers: '',
  economicActivity: '',
  financialEntity: '',
  contactName: '',
  contactSurname: '',
  companyName: '',
  contactEmail: '',
  contactPhone: '+56',
  roleInCompany: '',
  otherRoleInCompany: '',
  simulation: {
    companySavings: 0,
    employees: {
      days: 0,
      personalSave: 0,
      shouldShow: false,
      totalDaySave: 0,
      totalPersonalSave: 0,
    },
  },
};

export const stepperSteps = ['Datos de la empresa',
  'Resultados',
  // 'Diagnóstico',
];

export const steps = ['Datos de la empresa',
  'Cálculo',
  'Diagnóstico', 'Cierre'];

export const nWorkers = {
  50: 'Menos de 50',
  100: 'Más de 50 pero menos de 100',
  300: 'Más de 100 pero menos de 500',
  750: 'Más de 500 pero menos de 1000',
  1500: 'Más de 1000 pero menos de 2000',
  3000: 'Más de 2000',
};

export const financialServices = {
  CcLosAndes: 'Caja Los Andes',
  CcLosHeroes: 'Caja Los Héroes',
  CcLaAraucana: 'Caja La Araucana',
  Cc18septiembre: 'Caja 18 de Septiembre',
  DEFAULT: 'Otra / No tiene convenio',
};

export const companyField = {
  COMMERCE: 'Comercio',
  CONSTRUCTION: 'Construcción',
  EDUCATION_SERVICES: 'Servicios educacionales',
  ELECTRICITY: 'Electricidad',
  FINANCIAL_CONSULTANT: 'Consultoría financiera',
  INVESTMENTS: 'Inversiones',
  MANAGEMENT_SERVICE: 'Consultoría en gestión',
  PRIVATE_AND_DOMESTIC_SERVICES: 'Servicios públicos y privados',
  TRANSPORTATION: 'Transporte',
  DEFAULT: 'Otro',
};

export const roleInCompany = {
  Gerente_RRHH: 'Gerente RRHH',
  Jefe_RRHH: 'Jefe RRHH',
  Analista_Remuneraciones: 'Analista Remuneraciones',
  Asistente_RRHH: 'Asistente de RRHH',
  Otro: 'Otro',
};

export const labelColorSwitch = (step) => {
  const theme = useTheme();
  switch (step) {
    case 1:
      return theme.palette.secondary.main;
    case 3:
      return theme.customPalette.tertiary.main;
    default:
      return '#ccc';
  }
};

export const getStepContent = (
  step: number,
  currentData: formSchemaInterface,
  refsObject: {
    CompanyDataFormRef: React.MutableRefObject<any>,
    ResultsDataFormRef:React.MutableRefObject<any>,
  },
  handleCurrentData: (data: any)=> void,
  handleNext: () => void,
  handleStep: (st: number)=> void,
  handleEnableLoading: (state: boolean) => void,
  handleReset: ()=>void,
) => {
  switch (step) {
    case 1:
      return (
        <CompanyDataForm
          formRef={refsObject.CompanyDataFormRef}
          formSchema={currentData}
          handleCurrentData={handleCurrentData}
          handleNext={handleNext}
          handleEnableLoading={handleEnableLoading}
        />
      );
    case 2:
      return (
        <LoadingDataForm
          handleNext={handleNext}
          handleEnableLoading={handleEnableLoading}
        />
      );
    case 3:
      return (
        <ResultsDataForm
          formRef={refsObject.ResultsDataFormRef}
          formSchema={currentData}
          handleNext={handleNext}
          handleEnableLoading={handleEnableLoading}
          handleReset={handleReset}
        />
      );

    default:
      return handleStep(0);
  }
};

export const getInfographics = (activeStep: number, className: string) => {
  const theme = useTheme();
  const goToLanding = () => {
    useFBPixeleventTracker('Simulador empresas', { event: 'Volver al sitio Banca.Me' });
    window.location.href = 'https://banca.me/';
  };
  const info1 = (
    <div
      className={className}
      style={{ backgroundImage: `url(${infoBackground1})` }}
    >
      <div style={{ maxWidth: '350px', textAlign: 'left', marginLeft: '10%' }}>
        <div style={{ marginTop: '70%' }}>
          <Typography variant="h6">
            ¿Sabías que un trabajador necesita
            {' '}
            <span style={{ fontWeight: 'bold' }}>
              hasta 3 días
              laborales
            </span>
            {' '}
            para pedir un crédito, generando ausentismo laboral?
          </Typography>
        </div>
        <div
          style={{
            backgroundColor: theme.customPalette.tertiary.main,
            margin: '20px 0px',
            height: 15,
            width: 15,
            borderRadius: '50%',
          }}
        />
        <div style={{ margin: 'auto' }}>
          <Typography display="inline" variant="h6" fontWeight="bold">
            Con Banca.Me
            {' '}
          </Typography>
          <Typography display="inline" variant="h6">
            este trámite es
            {' '}
          </Typography>
          <Typography variant="h6" color="secondary" display="inline" fontWeight="bold">  100% online, </Typography>
          <Typography display="inline" variant="h6">
            favoreciendo el bienestar financiero del trabajador y
            la productividad de la empresa
          </Typography>
        </div>
      </div>
    </div>
  );

  const info4 = (
    <Fade in {...({ timeout: 3500 })}>
      <div className={className} style={{ backgroundImage: `url(${infoBackground4})` }}>
        <div style={{ maxWidth: 470, margin: 'auto', textAlign: 'center' }}>
          <Hidden mdUp>
            <div>
              <img src={lastAvatar} alt="LastAvatar" width={150} />
            </div>
          </Hidden>
          <div>
            <Typography
              variant="h4"
              style={{ fontWeight: 'bold', marginBottom: '5%' }}
              gutterBottom
            >
              ¡Hablamos pronto!

            </Typography>
            <Typography display="inline" variant="h5">
              Ayudar a las personas a
              {' '}
            </Typography>
            <Typography
              variant="h5"
              display="inline"
              style={{ fontWeight: 'bold', color: theme.customPalette.tertiary.main }}
            >
              {' '}
              mejorar su bienestar financiero
              {' '}
            </Typography>
            <Typography display="inline" variant="h5">
              es también contribuir al bienestar y productividad de tu empresa
            </Typography>

          </div>
          <Button
            onClick={goToLanding}
            style={{ margin: '5% auto', maxWidth: 200 }}
            variant="contained"
          >
            Volver al sitio

          </Button>
        </div>
      </div>
    </Fade>
  );
  switch (activeStep) {
    case 1:
      return info1;
    case 2:
      return null;
    case 3:
      return null;
    case 4:
      return info4;
    default:
      return null;
  }
};

export const getButtonLabel = (step) => {
  if (step === -1) {
    return 'Contacten a mi empleador';
  } if (step === steps.length) {
    return 'Ir a Banca.Me';
  }
  return 'Continuar';
};

import React from 'react';
import {
  Button,
  Fade,
  Hidden,
} from '@mui/material';
import {
  getStepContent,
  steps, getButtonLabel,
} from './formLogic';
import simulationStyles from '../styles';
import { formSchemaInterface } from '../../../utils/interfaces/simulation';

interface formParentInterface {
  activeStep: number,
  currentData: formSchemaInterface
  handleCurrentData: (data: formSchemaInterface) => void,
  handleStep: (step: number) => void,
  handleNext: () => void,
  handleBack: () => void
  handleReset:() => void,
}

function FormParent(props:formParentInterface) {
  const {
    activeStep,
    currentData,
    handleCurrentData,
    handleStep,
    handleNext,
    handleBack,
    handleReset,
  } = props;
  const classes = simulationStyles();

  const [enableLoading, setEnableLoading] = React.useState(false);

  const handleEnableLoading = (state) => setEnableLoading(state);

  const companyDataFormRef = React.useRef(null);
  const resultsDataFormRef = React.useRef(null);

  const refsObject = {
    CompanyDataFormRef: companyDataFormRef,
    ResultsDataFormRef: resultsDataFormRef,
  };

  const onSubmit = () => {
    if (companyDataFormRef.current) {
      companyDataFormRef.current.handleSubmit();
    }
    if (resultsDataFormRef.current) {
      resultsDataFormRef.current.handleSubmit();
    }
  };

  return (
    <Fade
      in
      {...({ timeout: 1000 })}
    >
      <div>

        {activeStep === steps.length ? (
          <div />
        ) : (
          getStepContent(
            activeStep,
            currentData,
            refsObject,
            handleCurrentData,
            handleNext,
            handleStep,
            handleEnableLoading,
            handleReset,
          )
        )}
        {activeStep < 2
          && (
          <>
            <Button
              className={classes.submitButton}
              variant={activeStep === 3 ? 'text' : 'contained'}
              color="primary"
              onClick={onSubmit}
              disabled={enableLoading}
            >
              {enableLoading ? 'Cargando...' : getButtonLabel(activeStep)}
            </Button>
            <div>
              <Hidden smUp>
                {activeStep < 4
                  && (
                    <Button
                      className={classes.backButton}
                      disabled={activeStep === 1 || enableLoading}
                      color="primary"
                      onClick={handleBack}
                    >
                      {activeStep === 0 ? 'Volver a Banca.Me' : 'Volver'}
                    </Button>

                  )}
              </Hidden>
            </div>
          </>
          )}
      </div>
    </Fade>
  );
}

export default FormParent;

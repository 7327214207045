import React from 'react';
import { useHistory } from 'react-router-dom';

const Routes = (counter: number) => {
  switch (counter) {
    case 0:
      return '/company-report';
    case 1:
      return '/company-report/bancame-index';
    case 2:
      return '/company-report/concepts';
    case 3:
      return '/company-report/action-plan';
    case 4:
      return '/company-report/over-average';
    case 5:
      return '/company-report/need-attention';
    case 6:
      return '/company-report/critical';
    case 7:
      return '/company-report/segmentation';
    case 8:
      return '/company-report/good-to-know';
    case 9:
      return '/company-report/contact-us';
    default:
      return '/company-report';
  }
};

const ButtonClassNames = (counter: number) => {
  switch (counter) {
    case 1:
      return 'pageNumber01';
    case 2:
      return 'pageNumber02';
    case 3:
      return 'pageNumber03';
    default:
      return 'pageNumber02';
  }
};

interface CompanyReportButtonsInterface {
  counter: number,
  handleCounter: (num: number) => void,
  handlePreset: (preset: string) => void,
}

function CompanyReportButtons(props: CompanyReportButtonsInterface) {
  const { counter, handleCounter, handlePreset } = props;
  const history = useHistory();

  const handlePrevious = () => {
    handlePreset('moveToRightFromLeft');
    setTimeout(
      () => {
        history.push(Routes(counter - 1));
        handleCounter(counter - 1);
      },
      200,
    );
  };
  const handleNext = () => {
    handlePreset('moveToLeftFromRight');
    setTimeout(
      () => {
        history.push(Routes(counter + 1));
        handleCounter(counter + 1);
      },
      200,
    );
  };

  React.useEffect(() => {
    if (window.location.pathname === '/company-report/bancame-index') {
      return handleCounter(1);
    }
    if (window.location.pathname === '/company-report/concepts') {
      return handleCounter(2);
    }
    if (window.location.pathname === '/company-report/action-plan') {
      return handleCounter(3);
    }
    if (window.location.pathname === '/company-report/over-average') {
      return handleCounter(4);
    }
    if (window.location.pathname === '/company-report/need-attention') {
      return handleCounter(5);
    }
    if (window.location.pathname === '/company-report/critical') {
      return handleCounter(6);
    }
    if (window.location.pathname === '/company-report/segmentation') {
      return handleCounter(7);
    }
    if (window.location.pathname === '/company-report/good-to-know') {
      return handleCounter(8);
    }
    if (window.location.pathname === '/company-report/contact-us') {
      return handleCounter(9);
    }

    return handleCounter(0);
  }, [handleCounter, counter]);

  React.useEffect(() => {
    function keyBoardListener(event: KeyboardEvent) {
      if (event.isTrusted) {
        if (event.key === 'ArrowRight' && counter < 9) {
          window.removeEventListener('keydown', keyBoardListener, false);
          handleNext();
        }
        if (event.key === 'ArrowLeft' && counter > 1) {
          window.removeEventListener('keydown', keyBoardListener, false);
          handlePrevious();
        }
      }
    }
    window.addEventListener('keydown', keyBoardListener, false);
  }, [counter]);

  return (
    <div className="pageButtons">
      <div className={ButtonClassNames(counter)}>
        {counter > 1

          ? (
            <div
              className="nextButton"
              aria-hidden="true"
              onClick={() => handlePrevious()}
            >
              <i className="fa fa-arrow-left" />
            </div>
          )

          : (
            <div className="stopButton">
              {' '}
              <div style={{ width: '17.5px' }} />
            </div>
          )}
        <div className="pageText">
          <span style={{ fontWeight: 'bold' }}>{counter}</span>
          {' '}
          / 9
        </div>
        {counter < 9
          ? (
            <div
              className="nextButton"
              aria-hidden="true"
              onClick={() => handleNext()}
            >
              <i className="fa fa-arrow-right" />
            </div>
          )
          : (
            <div className="stopButton">
              {' '}
              <div style={{ width: '17.5px' }} />
            </div>
          )}
      </div>

    </div>
  );
}

export default CompanyReportButtons;

const buttonsArray = [
  {
    key: '/account',
    label: 'Inicio',
    gtm: () => window.dataLayer.push({
      event: 'Navegación',
      boton: 'Inicio',
    }),
  },
  {
    key: '/account/loans',
    label: 'Créditos',
    gtm: () => window.dataLayer.push({
      event: 'Navegación',
      boton: 'Créditos',
    }),
  },
  {
    key: '/account/invoices',
    label: 'Pagos',
    gtm: () => window.dataLayer.push({
      event: 'Navegación',
      boton: 'Pagos',
    }),
  },
  // {
  //   key: '/account/brochures',
  //   label: 'Difusión',
  // },
  // {
  //   key: '/account/bancame-index',
  //   label: 'Índice BancaMe',
  // },
  // {
  //   key: '/account/schedule',
  //   label: 'Agendar capacitación',
  // },

];

export default buttonsArray;

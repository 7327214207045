import React from 'react';
import logo from '../../../assets/bancame_logo_sans.svg';
import body2 from '../../../assets/company-report/04_over-average/body_2.svg';
import body2mobile from '../../../assets/company-report/04_over-average/body_2_mobile.svg';
import logoWhite from '../../../assets/bancame_logo_white.svg';
import img2 from '../../../assets/company-report/04_over-average/img_2.svg';
import './styles.css';
import StatsPieChart from '../../../components/StatsPieChart';
import { RenderedCompanyReport } from '../../../utils/interfaces/company-report';

interface CRDataInterface {
  reportData: RenderedCompanyReport
}

function CompanyReportOverAverage(props:CRDataInterface) {
  const { reportData } = props;
  React.useEffect(() => {
    document.body.style.backgroundColor = '#35aac5';
  }, []);
  let totalWorkers = 1;
  let totalToIncome = 0;
  let expensesToIncome = 0;
  let debtToIncome = 0;
  if (reportData.companyData) {
    totalWorkers = reportData.companyData.totalToIncome.total;
    totalToIncome = reportData.companyData.totalToIncome.overCMF;
    expensesToIncome = reportData.companyData.expensesToIncome.overCMF;
    debtToIncome = reportData.companyData.debtToIncome.overCMF;
  }

  const data0 = [
    { name: 'totalToIncome', value: +totalToIncome },
    { name: 'rest', value: +totalWorkers - totalToIncome },
  ];
  const data1 = [
    { name: 'totalToIncome', value: +expensesToIncome },
    { name: 'rest', value: +totalWorkers - expensesToIncome },
  ];

  const data2 = [
    { name: 'totalToIncome', value: +debtToIncome },
    { name: 'rest', value: +totalWorkers - debtToIncome },
  ];

  const dataObject: {[key:number]: any} = {
    0: {
      title: 'Gastos sobre el promedio chileno',
      data: data0,
      description:
  <>
    <span style={{ fontWeight: 'bold' }}>
      {' '}
      {totalToIncome}
      {' '}
      personas
      {' '}
    </span>
    {' '}
    poseen
    una
    {' '}
    <span style={{ fontWeight: 550 }}> tasa de egresos </span>
    más
    elevada que el promedio chileno

  </>,
      info: 'La relación Gastos Totales/Ingreso bruto es mayor al porcentaje de endeudamiento promedio con cooperativas indicado por la CMF indicado el año 2020, que es de un 16%',
    },
    1: {
      title: 'Gastos sobre el promedio en Chile',
      data: data1,
      description:
  <>
    <span style={{ fontWeight: 'bold' }}>
      {' '}
      {expensesToIncome}
      {' '}
      personas
      {' '}
    </span>
    {' '}
    poseen
    una
    {' '}
    <span style={{ fontWeight: 550 }}> tasa de gastos </span>
    más
    elevada que el promedio chileno

  </>,
      info: 'La relación Gastos/Ingreso bruto es mayor al porcentaje de endeudamiento promedio con cooperativas indicado por la CMF indicado el año 2020, que es de un 16%',
    },
    2: {
      title: 'Deudas sobre el promedio chileno',
      data: data2,
      description:
  <>
    <span style={{ fontWeight: 'bold' }}>
      {' '}
      {debtToIncome}
      {' '}
      personas
      {' '}
    </span>
    {' '}
    poseen
    una
    {' '}
    <span style={{ fontWeight: 550 }}> tasa de deudas </span>
    más
    elevada que el promedio chileno

  </>,
      info: 'La relación Deudas/Ingreso bruto es mayor al porcentaje de endeudamiento promedio con cooperativas indicado por la CMF indicado el año 2020, que es de un 16%',
    },
  };

  const [selected, setSelected] = React.useState(2);
  const informeCMF = 'https://www.cmfchile.cl/portal/estadisticas/617/articles-46785_recurso_2.pdf';

  return (
    <>
      <img src={body2} alt="secondBackground" className="second_background" />
      <img src={body2mobile} alt="secondBackground_mobile" className="second_background_mobile" />
      <div className="second_body">
        <div className="second_header">
          <object data={logoWhite} width="70px" height="70px"> </object>
          <div className="title_2">
            <h2 style={{ margin: 0 }}>
              El bienestar financiero
              está directamente
              relacionado con la salud de tus trabajadores y
              con la productividad de
              tu empresa.

            </h2>

            <h3 className="title_21">
              Cuidar de tus trabajadores
              financieramente asegura que ellos estén
              mejor tanto personalmente como en la
              empresa.

            </h3>
          </div>
        </div>
        <div className="img_2_container">
          <object data={img2} className="img_2"> </object>
        </div>

        <div className="second_content">

          <div className="info_container">
            <div className="chart_container">
              <StatsPieChart
                chartData={dataObject[selected].data}
                size="normal"
              />
            </div>
            <div className="info_subContainer">
              <div className="info_title">

                <h2 className="subtitle_2">
                  {dataObject[selected].title}
                </h2>
                <div style={{ display: 'flex', placeContent: 'center' }}>
                  <div
                    onClick={() => window.open(informeCMF)}
                    style={{ cursor: 'pointer', zIndex: 1 }}
                    aria-hidden
                  >
                    <i className="material-icons" style={{ fontSize: '25px' }}>launch</i>
                  </div>
                  <div className="tooltip" style={{ cursor: 'default' }}>
                    <i className="material-icons" style={{ fontSize: '25px' }}>info_outline</i>
                    <span className="tooltiptext-right" style={{ top: -80 }}>{dataObject[selected].info}</span>
                  </div>
                </div>

              </div>
              <h3 className="description_2">
                {dataObject[selected].description}
              </h3>
              <div className="data_buttons">
                <div
                  className="tooltip"
                  onClick={() => setSelected(0)}
                  aria-hidden
                >
                  <i style={{ color: selected === 0 ? '#F44C7F' : undefined }} className="material-icons">monetization_on</i>
                  <span className="tooltiptext">{dataObject[0].title}</span>
                </div>
                <div className="tooltip">
                  <i
                    aria-hidden
                    style={{ color: selected === 2 ? '#F44C7F' : undefined }}
                    onClick={() => setSelected(2)}
                    className="material-icons"
                  >
                    monetization_on

                  </i>
                  <span className="tooltiptext">{dataObject[2].title}</span>
                  <span className="badge badge-success pull-right">
                    <object data={logo} width="14px" height="10px" aria-label="Logo" />
                  </span>
                </div>
              </div>

            </div>
            <div />
            <div>
              <div className="upper_arrow"><i style={{ fontSize: 'medium' }} className="material-icons">keyboard_arrow_up</i></div>
              <div className="select_description">
                <div>
                  Click aquí para seleccionar un coeficiente
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </>
  );
}

export default CompanyReportOverAverage;

import React from 'react';
import { Card, Paper, Typography } from '@mui/material';
import type { StatsData } from '../../types/account';
import CustomPieChart from '../../components/CustomPieChart';
import CustomBarChart from '../../components/CustomBarChart';
import useStyles from './styles';

type PLRStatsProps = {
    plrStats: StatsData['data']['companyStats']['plrStats']
}

const plrStatsLabels = {
  successful: 'Exitosa',
  failed: 'Fallida',
  inEvaluation: 'En evaluación',
};
const getPLRstatsLabels = (key: string) => {
  if (Object.keys(plrStatsLabels).includes(key)) {
    return plrStatsLabels[key];
  }
  return key;
};

export default function PLRStats(props: PLRStatsProps) {
  const { plrStats } = props;
  const classes = useStyles();
  return (
    <>
      <Paper style={{ borderRadius: 20, backgroundColor: 'whitesmoke' }}>
        <div className={classes.plrStatsContainer}>
          <div>
            <Card className={classes.statsCard}>
              <Typography align="center" fontWeight="bold">
                Solicitudes de créditos recibidas
              </Typography>
            </Card>
            <br />
            <Card className={classes.statsCard}>
              <CustomBarChart
                data={plrStats.plrsByDate.map((date) => ({
                  name: date.date, Cantidad: date.count,
                }))}
                barColor="alternativePrimary"
              />
            </Card>
          </div>
          <div>
            <Card className={classes.statsCard}>
              <Typography align="center" fontWeight="bold">
                Resultado solicitudes
              </Typography>
            </Card>
            <br />
            <Card className={classes.statsCard}>
              <CustomPieChart
                data={Object.entries(plrStats.plrs)
                  .map((stat, idx) => ({ id: `${idx}_${stat[0]}`, name: getPLRstatsLabels(stat[0]), value: stat[1] }
                  ))}
                isMoney={false}
              />
            </Card>
          </div>
        </div>
      </Paper>
      <br />
      <Paper style={{ borderRadius: 20, backgroundColor: 'whitesmoke' }}>
        <div style={{ padding: 10 }}>
          <Card className={classes.rejectedStats}>
            <Typography align="center" fontWeight="bold">
              Motivo rechazos
            </Typography>
          </Card>
          <br />
          <Card className={classes.rejectedStats}>
            <CustomBarChart data={plrStats.rejectReasons.map((reason) => ({
              name: reason.archivedComment, Cantidad: reason.count,
            }))}
            />
          </Card>
        </div>
      </Paper>
    </>
  );
}

/* eslint-disable no-console */
import React from 'react';
import './App.css';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import TagManager from 'react-gtm-module';
import AppRoutes from './navigation';
import { theme } from './theme';
import useFBPixel from './utils/useFBPixel';

const setGTMArgs = (url: string) => {
  if (url.includes('dev') || url.includes('localhost')) {
    return 'GTM-PHLTNH6';
  }
  if (url.includes('beta')) {
    return 'GTM-N2MQN7C';
  }
  return 'GTM-PFR7PNK';
};

if (!window.location.href.includes('localhost')) {
  TagManager.initialize({ gtmId: setGTMArgs(window.location.href) });
} else {
  window.dataLayer = window.dataLayer || [];
  console.warn('GTM is deactivated for localhost!');
}

function App() {
  useFBPixel();
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppRoutes />
    </ThemeProvider>
  );
}

export default App;

import React from 'react';
import { useParams } from 'react-router';
import { CircularProgress, Grid, Typography } from '@mui/material';
import { CompanyAccountComponent } from '../../../types/account';
import AppNavbar from '../../../components/AppNavbar';
import LoanSimulationDetail from '../../../features/account/LoanSimulationDetail';
import { getPLRSimulation } from '../../../queries/account';
import ViewTitle from '../../../components/ViewTitle';
import avatarExcited from '../../../assets/avatar_excited.svg';

type LoanSimulationIdParams = {
  loanSimulationId: string,
}

export default function CompanyAnswerLoanSimulationDetail(props:CompanyAccountComponent) {
  const { token, companyName } = props;
  const { loanSimulationId } = useParams<LoanSimulationIdParams>();
  const {
    plrSimulation,
    plrSimulationLoading,
    plrSimulationError,
  } = getPLRSimulation(token, loanSimulationId);

  return (
    <AppNavbar title={companyName} hideNavigation>
      <>
        <Grid
          container
        >
          <Grid
            item
            xs={4}
            sm={2}
            lg={1}
          >
            <object
              data={avatarExcited}
              width="80px"
              style={{ marginInline: 'auto' }}
              aria-labelledby="Bancamin"
            />
          </Grid>
          <ViewTitle
            title="Aprobación de crédito pendiente"
            color="alternativePrimary"
            xs={8}
            md={8}
            lg={4}
          />

        </Grid>
        {plrSimulationError
        && <Typography>¡Ha ocurrido un error! Por favor contáctanos</Typography>}
        {!plrSimulationError
        && plrSimulationLoading && <CircularProgress size={50} />}
        {!plrSimulationError && !plrSimulationLoading && plrSimulation
          && (
          <LoanSimulationDetail
            plr={plrSimulation.preLoanRequest}
            simulation={plrSimulation.simulation}
            token={token}
            loanSimulationId={loanSimulationId}
          />
          )}
      </>
    </AppNavbar>

  );
}

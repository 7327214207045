import React from 'react';

import {
  Route, Switch, useHistory, Redirect,
} from 'react-router-dom';
import GenerateReportLogin from '../../../pages/generate-report/00_login';
import GenerateReportAuth from '../../../pages/generate-report/01_sign-up';

function GenerateReportRoutes() {
  const history = useHistory();
  const [generateReportLogged, setGenerateReportLogged] = React.useState(false);

  const handleGenerateReportLogin = (token: string) => {
    document.body.style.backgroundColor = '#D7EFF4';
    setGenerateReportLogged(true);
    history.push(`/generate-report/auth?token=${token}`);
  };

  const generateReportLogIn = () => (
    <GenerateReportLogin
      handleLogin={handleGenerateReportLogin}
    />
  );
  const generateReportAuth = () => (
    <GenerateReportAuth />

  );

  return (
    <Switch>
      <Route exact path="/generate-report/auth" component={generateReportAuth}>
        {generateReportLogged ? <GenerateReportAuth /> : <Redirect to="/generate-report" />}
      </Route>
      <Route path="/generate-report" component={generateReportLogIn} />
    </Switch>
  );
}
export default GenerateReportRoutes;

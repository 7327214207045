import * as Yup from 'yup';

const MailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))|(undefined)$/;

export const companyDataValidationSchema = Yup.object().shape({
  nWorkers: Yup.number().min(1, 'Al menos debe haber 1 trabajador')
    .required('Por favor ingrese el n° de colaboradores'),
  economicActivity: Yup.string()
    .required('Por favor indique la actividad económica de su empresa'),
  financialEntity: Yup.string()
    .required('Por favor si su empresa cuenta con algún convenio'),
});

export const requesterDataValidationSchema = Yup.object().shape({
  contactName: Yup.string()
    .required('Ingrese su nombre')
    .test('sin-guion-al-principio', 'Sin guiones al inicio', (name) => {
      if (name !== undefined) {
        if (name.trim().length > 0 && name.trim()[0] === '-') {
          return false;
        }
      }
      return true;
    })
    .test('un-solo-guion', 'Hay más de 2 guiones', (name) => {
      if (name !== undefined) {
        if (name.trim().length > 0 && name.split('-').length > 2) {
          return false;
        }
      }
      return true;
    }),
  contactSurname: Yup.string()
    .required('Ingrese su apellido')
    .test('sin-guion-al-principio', 'Sin guiones al inicio', (name) => {
      if (name !== undefined) {
        if (name.trim().length > 0 && name.trim()[0] === '-') {
          return false;
        }
      }
      return true;
    })
    .test('un-solo-guion', 'Hay más de 2 guiones', (name) => {
      if (name !== undefined) {
        if (name.trim().length > 0 && name.split('-').length > 2) {
          return false;
        }
      }
      return true;
    }),
  companyName: Yup.string()
    .required('Por favor ingrese el nombre de la empresa')
    .test('sin-guion-al-principio', 'La empresa no puede tener guión al principio', (companyName) => {
      if (companyName !== undefined) {
        if (companyName.trim().length > 0 && companyName.trim()[0] === '-') {
          return false;
        }
      }
      return true;
    })
    .test('un-solo-guion', 'No pueden haber más de 2 guiones en la empresa', (companyName) => {
      if (companyName !== undefined) {
        if (companyName.trim().length > 0 && companyName.split('-').length > 2) {
          return false;
        }
      }
      return true;
    }),
  roleInCompany: Yup.string()
    .required('Por favor ingrese su rol en la empresa'),
  otherRoleInCompany: Yup.string()
    .when('roleInCompany', {
      is: 'Otro',
      then: Yup.string().required('Por favor ingrese su rol en la empresa'),
      otherwise: Yup.string(),
    })
    .test('sin-guion-al-principio', 'El rol no puede tener guión al principio', (roleInCompany) => {
      if (roleInCompany !== undefined) {
        if (roleInCompany.trim().length > 0 && roleInCompany.trim()[0] === '-') {
          return false;
        }
      }
      return true;
    })
    .test('un-solo-guion', 'No pueden haber más de 2 guiones en su rol', (roleInCompany) => {
      if (roleInCompany !== undefined) {
        if (roleInCompany.trim().length > 0 && roleInCompany.split('-').length > 2) {
          return false;
        }
      }
      return true;
    }),
  contactEmail: Yup.string()
    .required('Por favor ingrese su E-mail')
    .matches(
      MailRegex,
      'Por favor ingrese un E-mail válido',
    ),
  contactPhone: Yup.string()
    .test(
      'num-required',
      'Por favor ingrese un número de teléfono válido',
      (num) => {
        if (num.length > 3) {
          const schema = Yup.string().min(12).max(12);
          return schema.isValidSync(num);
        }
        return true;
      },
    ),
});

import React from 'react';
import { PieChart, Pie, Cell } from 'recharts';

const COLORS = ['#262d40', 'lightgray'];

interface StatsPieChartInterface {
  chartData: { name: string, value:number }[],
  size: string
}

export default function StatsPieChart(props:StatsPieChartInterface) {
  const { chartData, size } = props;
  return (
    <PieChart width={size === 'small' ? 250 : 300} height={size === 'small' ? 230 : 300}>
      <Pie
        data={chartData}
        cx={size === 'small' ? 125 : 150}
        cy={size === 'small' ? 125 : 150}
        startAngle={450}
        endAngle={90}
        innerRadius={size === 'small' ? 70 : 80}
        outerRadius={size === 'small' ? 100 : 120}
        fill="#8884d8"
        dataKey="value"
      >
        {chartData.map((entry, index) => (
          <Cell key={`cell-${index + 1}`} stroke={(index % COLORS.length).toString()} fill={COLORS[index % COLORS.length]} />
        ))}

      </Pie>
      <g>
        <text style={{ fontSize: size === 'small' ? 35 : 40 }} x={size === 'small' ? 125 : 150} y={size === 'small' ? 125 : 150} dy={20} dx={15} textAnchor="middle" fill="#262d40">
          {(chartData[0].value / (chartData[0].value + chartData[1].value)).toLocaleString(undefined, { style: 'percent', maximumFractionDigits: 0 })}
        </text>
      </g>
    </PieChart>
  );
}

/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  Route,
  Redirect,
  RouteProps,
} from 'react-router-dom';
import { isAuthenticated, getToken, getCompanyName } from './helpers';

type PrivateRouteProps = {
  component: React.ElementType<{ token: string, companyName?: string, plrId?: string }>;
  exact?: RouteProps['exact']
  path: RouteProps['path'];
  acceptedTokenIntentions?: string[];
  notAuthenticatedRedirectUrl?: string;
}

export default function ProtectedRoute(props: PrivateRouteProps) {
  const {
    component: Component,
    acceptedTokenIntentions,
    notAuthenticatedRedirectUrl,
    ...rest
  } = props;
  const token = getToken();
  const companyName = getCompanyName(token);
  if (!Component) return null;
  return (
    <Route
      {...rest}
      render={(routeProps) => (
        isAuthenticated(token, acceptedTokenIntentions) ? (
          <Component token={token} companyName={companyName} {...routeProps} />
        ) : (
          <Redirect
            to={{
              pathname: notAuthenticatedRedirectUrl + token,
            }}
          />
        )
      )}
    />
  );
}

ProtectedRoute.defaultProps = {
  acceptedTokenIntentions: ['companyLogin', 'companyLoanApproval'],
  notAuthenticatedRedirectUrl: '/company-login?token=',
  exact: false,
};

import React from 'react';
import { useHistory } from 'react-router-dom';
import logo from '../../../../assets/bancame_logo.svg';
import bancameIndex from './financial-wellness.png';
import Header from '../../header';
import { handleVisibleTab } from '../../helpers';
import HubSpot from '../../../../components/HubspotForm';

function FinancialWellness() {
  const history = useHistory();
  React.useEffect(() => {
    document.body.style.backgroundColor = '#E5E5E5';
  }, []);
  const [isInterested, setIsInterested] = React.useState(false);
  const handleIsInterested = () => setIsInterested((o) => !o);
  const handleSubmit = () => setTimeout(() => setIsInterested(false), 2000);

  return (
    <Header type="enterprises">
      <div className="cards_container">

        <div className="card card_detail ">
          <div
            className="card_image enterprise"
            style={{
              backgroundImage: `url(${bancameIndex})`,

            }}
          >
            {' '}
            <div style={{ margin: 'auto' }}>
              <span style={{ fontSize: '90px' }}>&#128203;</span>
            </div>

          </div>
          <div className="container">

            <p>
              Diagnóstico, plan de acción y
              seguimiento del bienestar financiero de tus trabajadores

            </p>
            <object data={logo} width="30px" height="30px"> </object>
          </div>
        </div>
        <div>
          <div
            aria-hidden
            onClick={() => handleVisibleTab('enterprises', history)}
            style={{
              fontSize: '15px',
              marginBottom: '10px',
              cursor: 'pointer',
              color: '#f44c7f',
              textAlign: 'left',
            }}
          >
            <span style={{ fontSize: '20px', marginRight: '5px' }}>&#x2039;</span>
            Atrás

          </div>
          <div style={{ textAlign: 'left', height: '400px', overflowY: 'auto' }}>
            {isInterested

              && HubSpot(
                process.env.REACT_APP_PORTAL_ID,
                process.env.REACT_APP_FINANCIAL_WELLNESS_FORM_ID,
                handleSubmit,
              )}
            {!isInterested && (
            <div>

              <h2>Estudio bienestar financiero</h2>
              <p style={{ fontSize: '18px' }}>
                Agregamos información para tener un
                {' '}
                <a className="link_2" href="/enterprises/bancame-index" target="_blank"><b>Índice Banca.Me</b></a>
                {' '}
                más asertivo y desarrollamos un plan de acción en
                función de las necesidades de tu empresa

              </p>
              <ul style={{ fontSize: '16px' }}>
                <li>Asesoría financiera</li>
                <li>Capacitaciones mediante cursos gamificados</li>
                <li>
                  Créditos de consumo a la medida de la situación del trabajador y
                  a mejores tasas que Cajas de Compensación.

                </li>
                <li>Inversión automatizada.</li>
              </ul>
              <h5>¿Cómo funciona?</h5>
              <ol style={{ fontSize: '16px' }}>
                <li>
                  <i>Setup:</i>
                  {' '}
                  Integración de sistemas, recopilación de información de colaboradores,
                  entrega de diagnóstico.
                </li>
                <li>
                  <i>Lanzamiento:</i>
                  {' '}
                  Se da a conocer el convenio con los colaboradores.
                </li>
                <li>
                  <i>Plan de acción:</i>
                  {' '}
                  Se calendarizan las acciones a realizar para mejorar la
                  situación de sus colaboradores.
                </li>
                <li>
                  <i>Seguimiento:</i>
                  {' '}
                  Se revisa como ha evolucionado la situación de sus colaboradores
                </li>
              </ol>

            </div>
            )}
          </div>
          {!isInterested
            && (
            <h5
              aria-hidden
              onClick={handleIsInterested}
              className="interestedFooter"
            >
              Estoy interesado
            </h5>
            )}
        </div>
      </div>

    </Header>
  );
}

export default FinancialWellness;

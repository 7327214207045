import React from 'react';
// @ts-ignore
import HubspotForm from 'react-hubspot-form';

function HubSpotForm(
  portalId: string | undefined,
  formId: string | undefined,
  handleSubmit: () => void,
) {
  return (
    <HubspotForm
      portalId={portalId}
      formId={formId}
      onReady={() => {
        const script = document.createElement('script');
        script.src = 'https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js';
        const jqueryScript = document.getElementsByTagName('script');
        const src = Array.from(jqueryScript).filter((item) => item.src === script.src);
        if (src.length === 0) {
          document.body.appendChild(script);
        }
      }}
      onSubmit={() => handleSubmit()}
      loading={(
        <div style={{ textAlign: 'center' }}>
          <div>Cargando...</div>
        </div>
        )}
    />
  );
}

export default HubSpotForm;

import React from 'react';
import body7 from '../../../assets/company-report/09_contact-us/body_7.svg';
// import ellipse7 from './ellipse_7.svg';
import logo7 from '../../../assets/bancame_logo_white.svg';
import img7 from '../../../assets/company-report/09_contact-us/img_7.svg';
import './styles.css';

function CompanyReportContactUs() {
  React.useEffect(() => {
    document.body.style.backgroundColor = '#164550';
  }, []);

  return (
    <div>
      <img src={body7} alt="seventhBackground" className="seventh_background" />
      {/* <img src={ellipse6} alt="sixthBackground" className='ellipse_6' />        */}
      <div className="seventh_body">
        <div className="seventh_header">
          <object data={logo7} width="70px" height="70px"> </object>
        </div>
        <div className="seventh_content">
          <object data={img7} className="img_7"> </object>
          <div className="seventh_subcontent">
            <div>
              <h2>
                Si deseas un estudio más acabado,
                contáctanos a:
              </h2>
              <h1 className="mail_7">
                bienestarfinanciero@banca.me
              </h1>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
}

export default CompanyReportContactUs;

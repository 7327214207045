import React from 'react';

import {
  Redirect, Route, Switch,
} from 'react-router-dom';
import CompanyAccount from '../../../pages/account';
import CompanyAccountLoans from '../../../pages/account/loans';
import CompanyAccountInvoices from '../../../pages/account/invoices';
import ProtectedRoute from '../../../components/ProtectedRoute';
import CompanyAnswer from '../../../pages/account/company-answer';
import CompanyAnswerLoanSimulationDetail from '../../../pages/account/company-answer/[loanSimulationId]';

export default function AccountRoutes() {
  return (
    <Switch>
      <ProtectedRoute exact path="/account" component={CompanyAccount} />
      {/* <ProtectedRoute exact path="/account/login" component={companyAccount} /> */}
      <ProtectedRoute exact path="/account/loans" component={CompanyAccountLoans} />
      <ProtectedRoute exact path="/account/invoices" component={CompanyAccountInvoices} />
      {/* <ProtectedRoute exact path="/account/brochures" component={companyAccountBrochures} />
      <ProtectedRoute exact path="/account/bancame-index" component={companyAccountBancameIndex} />
      <ProtectedRoute exact path="/account/schedule" component={companyAccountSchedule} /> */}
      <Route exact path="/account/company-answer" component={CompanyAnswer} />
      <ProtectedRoute path="/account/company-answer/:loanSimulationId" component={CompanyAnswerLoanSimulationDetail} />
      <Route path="/">
        <Redirect to="/account" />
      </Route>
    </Switch>
  );
}

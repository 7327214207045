import React from 'react';
import ReactPixel from 'react-facebook-pixel';

const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false,
};

const useFBPixel = (): void => {
  const [initialized, setInitialized] = React.useState(false);
  React.useEffect(() => {
    if (!window.location.href.includes('localhost') && (!window.location.href.includes('beta')) && (!window.location.href.includes('dev'))) {
      ReactPixel.init('152538696686947', undefined, options);
    }
    setInitialized(true);
  }, []);
  React.useEffect(() => {
    if (initialized) {
      ReactPixel.pageView();
    }
  }, [initialized]);
};

export default useFBPixel;

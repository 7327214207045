import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    margin: 'auto',
  },
  container: {
    maxHeight: 400,
    '&::-webkit-scrollbar': {
      width: 7,
      height: 8,
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: '8px',
      boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '8px',
      backgroundColor: 'darkgrey',

    },

  },
  paper: {
    width: '100%',
    padding: 10,
  },
  stringOverflow: {
    width: '150px',
    textOverflow: 'clip',
    whiteSpace: 'nowrap',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      height: 0,
    },
  },
  tableRow: {
    '&$hover:hover': {
      backgroundColor: 'rgba(0,0,0,0.04)!important',
    },
  },
  hover: {},
}));

export default useStyles;

import React from 'react';
import { useHistory } from 'react-router-dom';
import logo from '../../../assets/bancame_logo.svg';
import entreprenerus from './entrepreneurship/emprendimiento.png';
import downPayment from './home-downpayment/downpayment.png';
import carCredit from './car-credit/credit.png';
import debtsPay from './debts-payment/online-pay.png';
import Header from '../header';

function Workers() {
  const history = useHistory();
  React.useEffect(() => {
    document.body.style.backgroundColor = '#E5E5E5';
  }, []);
  const handleProduct = (productSelected: string) => {
    if (productSelected === 'entrepeneurship') {
      history.push('/products/workers/entrepreneurship');
    }
    if (productSelected === 'debtsPay') {
      history.push('/products/workers/debts-payment');
    }
    if (productSelected === 'downPayment') {
      history.push('/products/workers/home-downpayment');
    }
    if (productSelected === 'carCredit') {
      history.push('/products/workers/car-credit');
    }
  };
  return (
    <Header type="workers">
      <div className="cards_container workers_cards">

        <div
          aria-hidden
          className="card"
          onClick={() => handleProduct('entrepeneurship')}
        >
          <div
            className="card_image"
            style={{
              backgroundImage: `url(${entreprenerus})`,
            }}
          >
            {' '}
            <div style={{ margin: 'auto' }}>
              <span style={{ fontSize: '70px' }}>&#128640;</span>
              <h2><b>Apoyo a emprendedores</b></h2>
            </div>

          </div>
          <div className="container">

            <p>¡Comienza tu emprendimiento con la asesoría de Banca.Me!</p>
            <object data={logo} width="30px" height="30px"> </object>
          </div>
        </div>
        <div
          aria-hidden
          className="card"
          onClick={() => handleProduct('debtsPay')}
        >
          <div
            className="card_image"
            style={{
              backgroundImage: `url(${debtsPay})`,
            }}
          >
            {' '}
            <div style={{ margin: 'auto' }}>
              <span style={{ fontSize: '70px' }}>&#128209;</span>
              <h2><b>Ordena tus finanzas</b></h2>
            </div>

          </div>
          <div className="container">

            <p>¡Ordena tus cuentas y ahorra hasta un 20% con la ayuda de  Banca.Me!</p>
            <object data={logo} width="30px" height="30px"> </object>
          </div>
        </div>
        <div
          aria-hidden
          className="card"
          onClick={() => handleProduct('downPayment')}
        >
          <div
            className="card_image"
            style={{
              backgroundImage: `url(${downPayment})`,
            }}
          >
            {' '}
            <div style={{ margin: 'auto' }}>
              <span style={{ fontSize: '70px' }}>&#127969;</span>
              <h2><b>Financia tu primera vivienda</b></h2>
            </div>

          </div>
          <div className="container">

            <p>¡Ahorra y asesórate para pagar el pie de tu vivienda con Banca.Me!</p>
            <object data={logo} width="30px" height="30px"> </object>
          </div>
        </div>
        <div
          aria-hidden
          className="card"
          onClick={() => handleProduct('carCredit')}
        >
          <div
            className="card_image"
            style={{
              backgroundImage: `url(${carCredit})`,
            }}
          >
            {' '}
            <div style={{ margin: 'auto' }}>
              <span style={{ fontSize: '60px' }}>&#128663;</span>
              <span style={{ fontSize: '45px' }}>🏍️</span>
              <h2><b>Compra tu vehículo</b></h2>
            </div>

          </div>
          <div className="container">

            <p>¡Te financiamos tu auto o moto a mejores tasas!</p>
            <object data={logo} width="30px" height="30px"> </object>
          </div>
        </div>

      </div>

    </Header>
  );
}

export default Workers;

import React from 'react';
import { CircularProgress, Grid, Typography } from '@mui/material';
import AppNavbar from '../../components/AppNavbar';
import type { CompanyAccountComponent } from '../../types/account';
import { getCompanyStats } from '../../queries/account';
import ViewTitle from '../../components/ViewTitle';
import LoanStats from '../../features/account/LoanStats';
import PLRStats from '../../features/account/PlrStats';
import avatarExcited from '../../assets/avatar_excited.svg';

export default function CompanyAccount(props:CompanyAccountComponent) {
  const { token, companyName } = props;
  const { statsData, statsDataLoading, statsDataError } = getCompanyStats(token);
  return (
    <AppNavbar title={companyName}>
      <>
        <Grid
          container
        >
          <Grid
            item
            xs={4}
            sm={2}
            lg={1}
          >
            <object
              data={avatarExcited}
              width="80px"
              style={{ marginInline: 'auto' }}
              aria-labelledby="Bancamin"
            />
          </Grid>
          <ViewTitle
            title="Te invitamos a revisar el impacto que ha generado BancaMe en tu empresa"
            color="alternativePrimary"
            xs={8}
            md={8}
            lg={6}
          />

        </Grid>
        {statsDataError && <Typography>¡Ha ocurrido un error! Por favor contáctanos</Typography>}
        {!statsDataError && statsDataLoading && <CircularProgress size={50} />}
        {!statsDataError && !statsDataLoading && statsData
          && (
          <>
            <LoanStats loanStats={statsData.loanStats} />
            <br />
            <PLRStats plrStats={statsData.plrStats} />
            <div style={{ marginBottom: '100px' }} />
          </>
          )}
      </>
    </AppNavbar>
  );
}

import React from 'react';

import { Route, Switch } from 'react-router-dom';
import Workers from '../../../pages/products/workers';
import Enterprises from '../../../pages/products/enterprises';

import Entrepreneurship from '../../../pages/products/workers/entrepreneurship';
import DebtsPay from '../../../pages/products/workers/debts-payment';
import DownPayment from '../../../pages/products/workers/home-downpayment';
import CarCredit from '../../../pages/products/workers/car-credit';

import BancameIndex from '../../../pages/products/enterprises/bancame-index/index';
import FinancialWellness from '../../../pages/products/enterprises/financial-wellness';

interface LocationState {
  location: unknown
}

function ProductsRoutes(props: LocationState) {
  const { location } = props;
  const workersLanding = () => <Workers />;
  const enterprisesLanding = () => <Enterprises />;

  const entrepreneurshipProduct = () => <Entrepreneurship />;
  const debtsPayProduct = () => <DebtsPay />;
  const downpaymentProduct = () => <DownPayment />;
  const carCreditProduct = () => <CarCredit />;

  const bancameIndexProduct = () => <BancameIndex />;
  const financialWellnessProduct = () => <FinancialWellness />;
  return (
    // @ts-ignore
    <Switch location={location}>
      <Route exact path="/products/workers" component={workersLanding} />
      <Route exact path="/products/workers/entrepreneurship" component={entrepreneurshipProduct} />
      <Route exact path="/products/workers/debts-payment" component={debtsPayProduct} />
      <Route exact path="/products/workers/home-downpayment" component={downpaymentProduct} />
      <Route exact path="/products/workers/car-credit" component={carCreditProduct} />
      <Route exact path="/products/enterprises" component={enterprisesLanding} />
      <Route exact path="/products/enterprises/bancame-index" component={bancameIndexProduct} />
      <Route exact path="/products/enterprises/financial-wellness" component={financialWellnessProduct} />
    </Switch>
  );
}
export default ProductsRoutes;

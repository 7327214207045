import React from 'react';
import './styles.css';
import notFound from './404.svg';
import body from '../../assets/products/background.svg';
import logo from '../../assets/bancame_logo.svg';

function NotFound() {
  React.useEffect(() => {
    document.body.style.backgroundColor = '#E5E5E5';
  }, []);
  const redirectToLanding = () => {
    window.location.href = 'https://banca.me';
  };

  return (
    <>
      <img src={body} alt="Background" className="products_background" />
      <div className="products_body">
        <div>

          <object data={logo} className="mainLogo"> </object>
          <h5 style={{ margin: 0 }}>Empresas</h5>
          <div className="cards_container">
            <div><img src={notFound} alt="notFound" className="notFound" /></div>
            <div>
              <h1>¡Nos perdimos!</h1>
              <h3>Esta página no existe</h3>
              <h5
                aria-hidden
                onClick={redirectToLanding}
                className="notFoundButton"
              >
                Volver a Banca.Me

              </h5>
            </div>
          </div>

        </div>
      </div>

    </>
  );
}

export default NotFound;

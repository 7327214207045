import React from 'react';
import { Formik, Form } from 'formik';
import {
  Button,
  FormControl,
  TextField,
  Typography,
  useTheme,
  Select,
  MenuItem,
  FormHelperText,
} from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import fetch from 'node-fetch';
import { verifyPhone } from '../../../../utils/inputValidators';
import { formSchemaInterface } from '../../../../utils/interfaces/simulation';
import simulationStyles from '../../styles';
import { requesterDataValidationSchema } from '../validationSchemas';
import {
  financialServices,
  companyField,
  roleInCompany,
} from '../formLogic';
import blueLogo from '../../../../assets/bancame_logo.svg';
import useFBPixeleventTracker from '../../../../utils/useFBPixelEventTracker';
import SelectPlaceholder from '../../../../components/SelectPlaceholder';

interface RequesterContactnterface {
  handleNext: ()=> void,
  formSchema: formSchemaInterface,
}

function RequesterContactForm(props: RequesterContactnterface) {
  const {
    handleNext,
    formSchema,
  } = props;
  const classes = simulationStyles();
  const theme = useTheme();
  const [isSubmitted, setIsSubmitted] = React.useState(false);
  const [error, setError] = React.useState(false);

  const goToContactMe = () => {
    window.open('https://wa.me/56920711980?text=Hola.%20Quiero%20agendar%20una%20reunión%20con%20Banca.Me.', '_blank');
  };
  const goToLanding = () => {
    window.open('https://banca.me/');
  };
  return (
    <Formik
      validationSchema={requesterDataValidationSchema}
      initialValues={formSchema}
      onSubmit={async (values) => {
        setError(false);
        const financialEntity = financialServices[values.financialEntity];
        const economicActivity = companyField[values.economicActivity];
        const data = {
          fields: [
            {
              name: 'firstname',
              value: values.contactName,
            },
            {
              name: 'lastname',
              value: values.contactSurname,
            },
            {
              name: 'company',
              value: values.companyName,
            },
            {
              name: 'email',
              value: values.contactEmail,
            },
            {
              name: 'nworkers',
              value: +values.nWorkers,
            },
            {
              name: 'financialentity',
              value: financialEntity,
            },
            {
              name: 'economicactivity',
              value: economicActivity,
            },
            {
              name: 'role_in_company',
              value: values.roleInCompany,
            },
            {
              name: 'other_role_in_company',
              value: values.otherRoleInCompany,
            },
          ],
        };
        if (values.contactPhone !== '+56') {
          data.fields.push({
            name: 'phone',
            value: values.contactPhone,
          });
        }
        try {
          const res = await fetch(
            `https://api.hsforms.com/submissions/v3/integration/submit/${process.env.REACT_APP_PORTAL_ID}/${process.env.REACT_APP_HUBSPOT_COMPANY_ONBOARDING_LEAD_FORM_ID}`,
            {
              method: 'POST',
              body: JSON.stringify(data),
              headers: { 'Content-Type': 'application/json' },
            },
          );
          if (res.ok) {
            window.dataLayer.push({
              event: 'Fin simulación',
              paso: 'Formulario de contacto',
            });
            setIsSubmitted(true);
            useFBPixeleventTracker('Simulador empresas', { event: 'Envío datos Formulario de contacto' });
            return handleNext();
          }
          return setError(true);
        } catch (e) {
          return setError(true);
        }
      }}
    >
      {({
        values,
        handleChange,
        setFieldValue,
        handleBlur,
        touched,
        errors,
        submitForm,
        isSubmitting,
      }) => (
        <>
          <Typography
            variant="h3"
            align="center"
            style={{
              paddingTop: '5%',
              marginBottom: '2%',
              fontWeight: 'bold',
            }}
          >
            Datos de contacto

          </Typography>
          <Form className={classes.form} style={{ margin: 'auto' }}>
            <Typography
              variant="body1"
              align="left"
            >
              Tu nombre completo *
            </Typography>
            <div className={classes.detailGrid} style={{ rowGap: 20 }}>
              <FormControl>
                <TextField
                  id="contactName"
                  name="contactName"
                  value={values.contactName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Nombre"
                  disabled={isSubmitted}
                  error={touched.contactName && Boolean(errors.contactName)}
                  helperText={touched.contactName ? errors.contactName : ''}
                  FormHelperTextProps={{ className: classes.helperText }}
                />
              </FormControl>
              <FormControl>
                <TextField
                  id="contactSurname"
                  name="contactSurname"
                  value={values.contactSurname}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Apellido"
                  disabled={isSubmitted}
                  error={touched.contactSurname && Boolean(errors.contactSurname)}
                  helperText={touched.contactSurname ? errors.contactSurname : ''}
                  FormHelperTextProps={{ className: classes.helperText }}
                />
              </FormControl>
            </div>
            <FormControl>
              <Typography
                variant="body1"
                gutterBottom
                align="left"
              >
                Nombre de la empresa *
              </Typography>
              <TextField
                id="companyName"
                name="companyName"
                value={values.companyName}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Nombre de la empresa"
                error={touched.companyName && Boolean(errors.companyName)}
                disabled={isSubmitted}
                helperText={touched.companyName ? errors.companyName : ''}
                FormHelperTextProps={{ className: classes.helperText }}
              />
            </FormControl>

            <div className={classes.detailGrid} style={{ rowGap: 20 }}>
              <FormControl>
                <Typography
                  variant="body1"
                  gutterBottom
                  align="left"
                >
                  E-Mail *
                </Typography>

                <TextField
                  id="contactEmail"
                  name="contactEmail"
                  value={values.contactEmail}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="tu.contacto@empresa.com"
                  disabled={isSubmitted}
                  error={touched.contactEmail && Boolean(errors.contactEmail)}
                  helperText={touched.contactEmail ? errors.contactEmail : ''}
                  FormHelperTextProps={{ className: classes.helperText }}
                />
              </FormControl>
              <FormControl>
                <Typography
                  variant="body1"
                  gutterBottom
                  align="left"
                >
                  N° de contacto (opcional)
                </Typography>

                <TextField
                  size="small"
                  variant="outlined"
                  autoComplete="off"
                  id="contactPhone"
                  name="contactPhone"
                  disabled={isSubmitted}
                  value={values.contactPhone}
                  onChange={(event) => verifyPhone(event, setFieldValue, 'contactPhone')}
                  onBlur={handleBlur}
                  error={touched.contactPhone && Boolean(errors.contactPhone)}
                  helperText={touched.contactPhone ? errors.contactPhone : 'Fijo o móvil'}
                  FormHelperTextProps={{ className: classes.helperText }}
                />
              </FormControl>
            </div>
            <Typography
              variant="body1"
              align="left"
            >
              Rol en la empresa
            </Typography>
            <div className={classes.detailGrid} style={{ rowGap: 20 }}>
              <FormControl error={touched.roleInCompany && Boolean(errors.roleInCompany)}>
                <Select
                  id="roleInCompany"
                  name="roleInCompany"
                  displayEmpty
                  value={values.roleInCompany}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  renderValue={
                  values.roleInCompany !== '' ? undefined : () => <SelectPlaceholder>Seleccionar...</SelectPlaceholder>
                }
                >
                  {Object.keys(roleInCompany).map((CF) => (
                    <MenuItem key={CF} value={CF}>
                      {roleInCompany[CF]}
                    </MenuItem>
                  ))}

                </Select>
                <FormHelperText className={classes.formHelperText}>
                  {touched.roleInCompany ? errors.roleInCompany : ''}
                </FormHelperText>
              </FormControl>
              {values.roleInCompany === 'Otro'
                ? (
                  <FormControl>
                    <TextField
                      id="otherRoleInCompany"
                      name="otherRoleInCompany"
                      value={values.otherRoleInCompany}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Ingrese su rol"
                      disabled={isSubmitted}
                      error={touched.otherRoleInCompany && Boolean(errors.otherRoleInCompany)}
                      helperText={touched.otherRoleInCompany ? errors.otherRoleInCompany : ''}
                      FormHelperTextProps={{ className: classes.helperText }}
                    />
                  </FormControl>
                )
                : undefined }
            </div>
            {isSubmitted
              && (
                <Typography
                  style={{ color: theme.palette.success.main }}
                  variant="caption"
                  align="center"
                >
                  ¡Solicitud enviada correctamente!

                </Typography>
              )}
            {error
              && (
                <Typography
                  color="error"
                  variant="body2"
                  align="center"
                >
                  Ha ocurrido un error enviando la solicitud, por favor contáctanos por WhatsApp
                  <div style={{
                    width: '100%', display: 'flex', placeContent: 'center', alignItems: 'center', margin: 0,
                  }}
                  >
                    <span style={{ fontSize: 20 }}>
                      &#128073;
                    </span>
                    <WhatsAppIcon style={{ color: 'green', height: 40, cursor: 'pointer' }} onClick={goToContactMe} />
                  </div>

                </Typography>
              )}
            <div style={{
              margin: error ? 'auto' : '40px auto',
              textAlign: 'center',
            }}
            >
              <Button
                variant="contained"
                onClick={submitForm}
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Cargando...' : 'Enviar datos de contacto'}
              </Button>
              <div>
                <img
                  onClick={goToLanding}
                  src={blueLogo}
                  alt="BancaMe logo"
                  className={classes.sub_logo_img}
                  aria-hidden="true"
                />
              </div>
            </div>

          </Form>

        </>
      )}
    </Formik>
  );
}

export default RequesterContactForm;

import { prettifyRut } from 'react-rut-formatter';

export const verifyText = (event, setFieldValue, fieldName) => {
  const regex = /^[a-zA-ZñÑáéíóúÁÉÍÓÚ -]+$/;
  if (event.target.value === '' || regex.test(event.target.value)) {
    setFieldValue(fieldName, event.target.value);
  }
};
export const verifyPhone = (event, setFieldValue, fieldName) => {
  const regex = /^[0-9]+$/;
  if (event.target.value.slice(0, 3) === '+56'
                  && regex.test(event.target.value.slice(1, event.target.value.length))) {
    setFieldValue(fieldName, `${event.target.value}`);
  }
};
export const setRut = (setFieldValue, value) => {
  const formatted = prettifyRut(value);
  setFieldValue('rut', formatted);
};

import React from 'react';
import { useHistory } from 'react-router-dom';
import logo from '../../../../assets/bancame_logo.svg';
import entreprenerus from './emprendimiento.png';
import Header from '../../header';
import { handleVisibleTab } from '../../helpers';
import HubSpot from '../../../../components/HubspotForm';

function Entrepreneurship() {
  const history = useHistory();

  React.useEffect(() => {
    document.body.style.backgroundColor = '#E5E5E5';
  }, []);

  const [isInterested, setIsInterested] = React.useState(false);
  const handleIsInterested = () => setIsInterested((o) => !o);
  const handleSubmit = () => setTimeout(() => setIsInterested(false), 2000);
  return (
    <Header type="workers">
      <div className="cards_container">

        <div className="card card_detail">
          <div
            className="card_image"
            style={{
              backgroundImage: `url(${entreprenerus})`,
              cursor: 'default',
            }}
          >
            {' '}
            <div style={{ margin: 'auto' }}>
              <span style={{ fontSize: '90px' }}>&#128640;</span>
            </div>

          </div>
          <div className="container">

            <p>¡Comienza tu emprendimiento con la asesoría de Banca.Me!</p>
            <object data={logo} width="30px" height="30px"> </object>
          </div>
        </div>
        <div>
          <div
            aria-hidden
            onClick={() => handleVisibleTab('workers', history)}
            style={{
              fontSize: '15px',
              marginBottom: '10px',
              cursor: 'pointer',
              color: '#35aac5',
              textAlign: 'left',
            }}
          >
            <span style={{ fontSize: '20px', marginRight: '5px' }}>&#x2039;</span>
            Atrás

          </div>
          <div style={{ textAlign: 'left', height: '400px', overflowY: 'auto' }}>
            {isInterested

              && HubSpot(
                process.env.REACT_APP_PORTAL_ID,
                process.env.REACT_APP_ENTREPRENEURSHIP_FORM_ID,
                handleSubmit,
              )}
            {!isInterested && (
            <div>

              <h2>Financiamos tu emprendimiento</h2>
              <p>
                Financiamos tu emprendimiento:
                Te damos acceso a
                {' '}
                <b>
                  {' '}
                  un cupo de hasta

                  $2.000.000
                  {' '}
                </b>
                {' '}
                para que
                puedas potenciar tu emprendimiento y
                generar una nueva fuente de ingresos.

              </p>
              <h5>Requisitos</h5>
              <ul style={{ fontSize: '18px' }}>
                <li>Pertenecer a empresa en convenio con Banca.Me</li>
                <li>Aplicar a un crédito según nuestra política de riesgo</li>
              </ul>

            </div>
            ) }
          </div>
          {!isInterested
            && (
            <h5
              aria-hidden
              onClick={handleIsInterested}
              className="interestedFooter"
            >
              Estoy interesado
            </h5>
            )}

        </div>

      </div>

    </Header>
  );
}

export default Entrepreneurship;

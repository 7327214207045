import { ReactSession } from 'react-client-session';
import jwtDecode from '../../utils/jwtDecode';

export const getToken = () => {
  ReactSession.setStoreType('sessionStorage');
  const token = ReactSession.get('token');
  return token;
};

export const getCompanyName = (token: string) => {
  if (token) {
    const { tokenIntention } = jwtDecode(token);
    if (['companyLogin', 'companyLoanApproval'].includes(tokenIntention)) {
      const { companyName } = jwtDecode(token);
      return companyName;
    }
  }
  return undefined;
};

export const isAuthenticated = (token: string, acceptedTokenIntentions: string[]) => {
  if (token !== null && token !== undefined) {
    try {
      const { tokenIntention, exp } = jwtDecode(token);
      if (acceptedTokenIntentions.includes(tokenIntention)
          && (new Date(exp * 1000).getTime() > new Date().getTime())) {
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  }
  return false;
};
